import styled from 'styled-components'

export const PreviewContainer = styled.section`
    width: 35%;
    height: 40rem;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;    
    margin-bottom: 20px;
   
    @media screen and (max-width: 1200px) {
        width: 55%;
    }

    @media screen and (max-width: 991px) {
        width: 95%;
    }
`;

export const PhoneHeader = styled.section`
    height: 3.4rem;
    background-color: white;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding: 0 20px;
`;

export const Left = styled.div`  
    width: 10px;
    height: 10px;
    background-color: gray;
`;

export const Center = styled.div`
    border-radius: 2rem;
    width: 55px;
    height: 10px;
    background-color: gray;
    position: relative;
    left: 20px;
`;

export const Icons = styled.div`
    width: 3rem;
    height: 20px;
    gap: 10px;
`;

export const ImgBateria = styled.img`
    height: 20px;
    width: 25px;
`;

export const ImgContect = styled.img`
    height: 15px;
    width: 15px;
`;

export const PreviewHeader = styled.section`
    height: 3.6rem;
    padding: 0.5rem 20px;
    background-color: #4D53E0;
    gap: 20px;
    border-bottom: 1px solid white;
`;

export const PreviewFooter = styled.section`
    border-top: 1px solid lightGray;
    height: 5.5rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    font-size: 0.9rem;
    position: absolute;
    bottom: 0;
`;

export const PreviewWrap = styled.section`
    width: 100%;
    min-height: 37rem;
    max-height: 37rem;
    position: relative;
`;

export const ChatbotAvatar = styled.div`
    width: 40px;
    height: 40px;
`;

export const ImgAvatar = styled.img`
    width: 30px;
    height: 30px;
`;

export const PreviewBody = styled.div`
    width: 100%;
    padding: 0 20px;

    p.origin-question,
    p.trigger,
    p.destiny-question {
        font-size: 0.8rem;
    }

`;

export const ParagraphContainerTrigger = styled.div`
    width: 75%;
    max-width: 300px;
    background-color: #4D53E060;
    border-radius: 1rem;
    position: relative;
    word-wrap: break-word;
    gap: 15px;
`;

export const Paragaph = styled.p`
    padding: 10px 15px 20px;
    color: white;
    font-size: 0.9rem;
    white-space: pre-line;
`;

export const PreviewBodyConatiner = styled.div`
    padding: 30px 0;
    max-height: 27.5rem;
    position: relative;
    word-wrap: break-word;
    overflow-y: auto;
    gap: 2rem;
`;

export const ParagraphContainer = styled.div`
    width: 75%;
    max-width: 300px;
    background-color: #4D53E0;
    border-radius: 1rem;
    position: relative;
    word-wrap: break-word;
    gap: 15px;
`;