import React, { useState, useEffect } from "react";
import {
    FormGroup,
    Button,
    Modal,
    ModalHeader,
    ModalFooter,
    Input,
    ModalBody,
    Label,
    CustomInput,
} from "reactstrap";
import Swal from "sweetalert2";

import { updateGroup } from "../../../services/Client-Schedule/index.js";

const EditGroup = ({ editModalOpen, toggleEditModal, groupId, initialName, initialSendMessage, getGroups }) => {
    const [name, setName] = useState(initialName || "");
    const [sendMessage, setSendMessage] = useState(initialSendMessage || "ALL");

    useEffect(() => {
        setName(initialName || "");
        setSendMessage(initialSendMessage || "ALL");
    }, [initialName, initialSendMessage]);

    const handleSave = async () => {
        if (!name) {
            Swal.fire({
                icon: 'error',
                title: 'Campos em branco',
                text: 'O Nome é obrigatório',
            });
            return;
        }

        Swal.fire({
            title: "Carregando, Por favor aguarde...",
            text: "Por favor aguarde.",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            const data = {
                groupId: parseInt(groupId),
                name,
                sendMessage,
            };

            const response = await updateGroup(groupId, data);

            if (response) {
                Swal.fire({
                    icon: 'success',
                    title: 'Sucesso!',
                    text: 'Grupo atualizado com sucesso!',
                    confirmButtonText: "OK",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                });

                getGroups();
                resetModal();
            }
        } catch (error) {
            console.log(error);
            const code = !error.response ? '00000' : error.response.data.code;
            const message = !error.response ? 'Ocorreu um erro inesperado' : error.response.data.message;
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `[${code}] ${message}`,
            });
        }
    };

    const resetModal = () => {
        toggleEditModal();
    };

    return (
        <Modal isOpen={editModalOpen} toggle={resetModal} className="modal-lg">
            <ModalHeader toggle={resetModal}>Editar Grupo</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="name">Nome do Grupo</Label>
                    <Input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="sendMessage">Tipo de Mensagem</Label>
                    <CustomInput
                        type="select"
                        id="sendMessage"
                        value={sendMessage}
                        onChange={(e) => setSendMessage(e.target.value)}
                    >
                        <option value="ALL">Todos</option>
                        <option value="WHATSAPP">WhatsApp</option>
                        <option value="SMS">SMS</option>
                    </CustomInput>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={resetModal}>
                    Fechar
                </Button>
                <Button color="primary" onClick={handleSave}>
                    Salvar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default EditGroup;