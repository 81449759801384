import '@fortawesome/fontawesome-free/css/all.css';
import React, {  useState } from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, Label, Row } from 'reactstrap';
import IncomeAndExpensesChart from './components/IncomeAndExpensesChart';
import ExpenseByCategoryChart from './components/ExpensesByCategoryChart';
import ReceiptByCategoryChart from './components/ReceiptByCategoryChart';

const Report = () => {
    const [filter, setFilter] = useState({
        period: '7'
    });

    const handleChangeFilter = (ev) => {
        const { id, value } = ev.target;
        setFilter(prevFilter => ({
            ...prevFilter,
            [id]: value,
        }));
    };

    return (
        <>
            <Col className="pr-grid-col mt-4" xs={12} lg={12}>
                <Card className="border-0 mb-5 mt-3 shadow">
                    <CardHeader className="bg-white border-0">
                        <h4>Filtro</h4>
                    </CardHeader>
                    <CardBody className='pt-0'>
                        <Row>
                            <Col md="6" lg="4">
                                <FormGroup>
                                    <Label for="period">Periodo de cadastro</Label>
                                    <select
                                        id="period"
                                        className="form-control form-control-md"
                                        value={filter.period || '7'}
                                        onChange={handleChangeFilter}
                                    >
                                        <option value="7">Últimos 7 dias</option>
                                        <option value="30">Últimos 30 dias</option>
                                        <option value="60">Últimos 60 dias</option>
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col className="pr-grid-col mt-4" xs={12} lg={12}>
                <Card className="border-0 mb-5 mt-3 shadow">
                    <CardHeader className="bg-white border-0">
                        <h4 >Graficos</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="12" xl="6">
                                <Card className='mb-5'>
                                    <CardHeader className='bg-white border-0'><h5>Despesas por categoria:</h5></CardHeader>
                                    <CardBody className='d-flex align-items-center justify-content-center'>
                                        <ExpenseByCategoryChart filter={filter} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="12" xl="6">
                                <Card className='mb-5'>
                                    <CardHeader className='bg-white border-0'><h5>Recebimento por categoria:</h5></CardHeader>
                                    <CardBody className='d-flex align-items-center justify-content-center'>
                                        <ReceiptByCategoryChart filter={filter} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="12">
                                <Card>
                                    <CardHeader className='bg-white border-0'><h5>Fluxo de caixa Receita x Despesas:</h5></CardHeader>
                                    <CardBody className='text-center'>
                                        <IncomeAndExpensesChart filter={filter} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>

        </>
    );
};

export default Report;
