import React, { useState, useEffect } from "react";

import {
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { newEvent, updateEvent, deleteEvent } from '../../../services/Calendar';

const EventModal = ({
    modal,
    toggleModal,
    dateStartClick,
    dateEndClick,
    selectedEvent,
    handleList
}) => {
    const [allDay, setAllDay] = useState(false);
    const [events, setEvents] = useState([]);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');

    const handleSave = async () => {
        try {
            if (!title) {
                Swal.fire({
                    icon: 'info',
                    title: 'Campos em branco',
                    text: 'O título é obrigatório!',
                    showConfirmButton: true,
                });
                return;
            }

            Swal.fire({
                title: 'Carregando, Por favor aguarde...',
                text: 'Por favor aguarde.',
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            const eventData = {
                title,
                description: message,
                dateStart,
                dateEnd,
                allDay,
            };

            let result;
            if (selectedEvent) {
                eventData.id = selectedEvent.id;
                result = await updateEvent(selectedEvent.id, eventData);
            } else {
                result = await newEvent(eventData);
            }

            if (result) {
                Swal.fire({
                    icon: 'success',
                    title: 'Sucesso!',
                    text: selectedEvent ? 'Evento atualizado com sucesso!' : 'Evento cadastrado com sucesso!',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                });

                const newEventArray = {
                    ...eventData,
                    start: new Date(eventData.dateStart),
                    end: new Date(eventData.dateEnd)
                };

                if (selectedEvent) {
                    setEvents(events.map(event => (event.id === selectedEvent.id ? newEventArray : event)));
                } else {
                    setEvents([...events, newEventArray]);
                }

                setTitle('');
                setMessage('');
                setDateStart('');
                setDateEnd('');
                toggleModal();
                handleList();
            }
        } catch (error) {
            const code = !error.response ? '00000' : error.response.data.code;
            const message = !error.response
                ? 'Ocorreu um erro inesperado'
                : error.response.data.message;
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `[${code}] ${message}`,
                showConfirmButton: true,
            });
        }
    };

    const handleAllDayChange = (e) => {
        const isChecked = e.target.checked;
        setAllDay(isChecked);
        if (isChecked) {
            if (dateStart) {
                const formattedDate = dateStart.split('T')[0];
                setDateEnd(formattedDate + 'T23:59');
            } else {
                const today = new Date();
                const year = today.getFullYear();
                const month = today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1;
                const day = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
                setDateStart(`${year}-${month}-${day}T00:00`);
                setDateEnd(`${year}-${month}-${day}T23:59`);
            }
        }
    }

    const handleDelete = async () => {
        if (selectedEvent) {
            try {
                const result = await Swal.fire({
                    title: 'Tem certeza?',
                    text: 'Você não poderá reverter isso!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sim, excluir!',
                });

                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Carregando, Por favor aguarde...",
                        text: "Por favor aguarde",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    await deleteEvent(selectedEvent.id);
                    toggleModal();
                    Swal.close();
                    Swal.fire(
                        'Excluído!',
                        'Seu evento foi excluído.',
                        'success'
                    );

                    handleList();
                }
            } catch (error) {
                const code = !error.response ? '00000' : error.response.data.code;
                const message = !error.response
                    ? 'Ocorreu um erro inesperado'
                    : error.response.data.message;
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `[${code}] ${message}`,
                    showConfirmButton: true,
                });
            }
        }
    };

    useEffect(() => {
        if (!modal) {
            setTitle('');
            setMessage('');
            setDateStart('');
            setDateEnd('');
            setAllDay(false);
        }
    }, [toggleModal]);

    useEffect(() => {
        if (dateStartClick && dateEndClick) {
            setDateStart(dateStartClick);
            setDateEnd(dateEndClick);
            setAllDay(true);
        }
    }, [dateStartClick, dateEndClick]);

    useEffect(() => {
        if (selectedEvent) {
            setTitle(selectedEvent.title);
            setMessage(selectedEvent.extendedProps.description);
            setAllDay(selectedEvent.allDay);
            setDateStart(dateStartClick);
            setDateEnd(dateEndClick);
        }
    }, [selectedEvent, dateStartClick, dateEndClick]);

    return (
        <>
            <Modal isOpen={modal} toggle={toggleModal} className='modal-lg modal-dialog-centered'>
                <ModalHeader toggle={toggleModal}>
                    {selectedEvent ? 'Editar Evento' : 'Cadastrar Evento'}
                </ModalHeader>
                <ModalBody>
                    <Form className='position-relative'>
                        <div className='d-flex flex-lg-row flex-column align-items-lg-center'>
                            <FormGroup className='col-lg-6'>
                                <Label>Título</Label>
                                <Input
                                    type="text"
                                    name="title"
                                    id="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup className='check'>
                                <Input
                                    type="checkbox"
                                    checked={allDay}
                                    onChange={handleAllDayChange}
                                />
                                <Label for="allDay" className='m-0'>Dia inteiro</Label>
                            </FormGroup>
                        </div>
                        <FormGroup className='col-lg-12'>
                            <Label for="message">Mensagem</Label>
                            <Input
                                type="textarea"
                                name="message"
                                id="message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </FormGroup>
                        <div className='d-flex flex-lg-row flex-column align-items-lg-center'>
                            <FormGroup className='col-lg-6'>
                                <Label for="dateStart">Data de Início</Label>
                                <Input
                                    type="datetime-local"
                                    name="dateStart"
                                    id="dateStart"
                                    value={dateStart}
                                    onChange={(e) => setDateStart(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup className='col-lg-6'>
                                <Label for="dateEnd">Data de Término</Label>
                                <Input
                                    type="datetime-local"
                                    name="dateEnd"
                                    id="dateEnd"
                                    value={dateEnd}
                                    onChange={(e) => setDateEnd(e.target.value)}
                                />
                            </FormGroup>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
                    {selectedEvent && <Button color="danger" onClick={handleDelete}>Excluir</Button>}
                    <Button color="primary" onClick={handleSave}>{selectedEvent ? 'Salvar' : 'Cadastrar'}</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default EventModal;