import api from "../api"

export const getDaysToExpire = async() => {
  return await api.get('/api/v1/license/days-to-expire');
}

export const showDetailService = async() => {
  return await api.get('/api/v1/license/details');
}

export const generateInvoiceService = async () => {
  return await api.post('/api/v1/payment');
}