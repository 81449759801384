import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
import { LOGIN_SUCCESS } from "../../actions/auth";
import hasToken from "../../services/authService";
import { Auth } from "../../services/Auth";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import ResetPassword from "../register/ResetPasswordService";
import Logo from "../../assets/logo.png"
import loginImage from "../../assets/login-image.png";
import { getDaysToExpire } from "../../services/License/index";

const Login = (props) => {
  const history = useHistory();
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const doLogin = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Realizando Login",
      text: "Por favor, aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await Auth(state.email, state.password)
      .then((response) => {
        localStorage.setItem("authenticated_izichat", true);
        localStorage.setItem("token_izichat_xpto", response.data.resultSet);
        localStorage.setItem(
          "data_user_izichat_app",
          JSON.stringify(response.data.user)
        );

        Swal.close();

        props.dispatch({
          type: LOGIN_SUCCESS,
        });

        history.push("/admin/dashboard");
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
          showConfirmButton: true,
        });
      });
  };

  const changeCreds = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const { from } = props.location.state || { from: { pathname: "/admin" } };
  if (hasToken(JSON.parse(localStorage.getItem("authenticated_izichat")))) {
    return <Redirect to={from} />;
  }

  return (
    <div className="auth-page">
      <Container className="col-12">
        <Row className="position-absolute logo-row w-100">
          <img src={Logo} alt="Logo" className="logo-block" />
        </Row>
        <Row className="d-flex align-items-center">
          <Col className="left-column d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between py-3">
              <h2 className="headline-1 auth-header mb-0">Faça seu login</h2>
            </div>
            <form onSubmit={(event) => doLogin(event)}>
              <FormGroup className="my-3">
                <FormText className="formText">Email</FormText>
                <Input
                  id="email"
                  className="input pl-3"
                  value={state.email}
                  onChange={(event) => changeCreds(event)}
                  type="email"
                  required
                  name="email"
                  placeholder="Digite o email"
                />
              </FormGroup>
              <FormGroup className="my-3">
                <div className="d-flex justify-content-between">
                  <FormText className="formText">Senha</FormText>
                </div>
                <Input
                  id="password"
                  className="input pl-3"
                  value={state.password}
                  onChange={(event) => changeCreds(event)}
                  type="password"
                  required
                  name="password"
                  placeholder="Digite a senha"
                />
              </FormGroup>
              <div className="bg-widget d-flex justify-content-end mt-0">
                <ResetPassword />
              </div>
              <div className="bg-widget d-flex justify-content-center">
                <Button
                  className="mt-3 mb-2 w-100 rounded-0 border-0 text-light"
                  style={{ backgroundColor: '#009582' }}
                  type="submit"
                >
                  Entrar
                </Button>
              </div>
              <a href="https://api.whatsapp.com/send/?phone=554499143623&text=Ol%C3%A1+gostaria+de+mais+informa%C3%A7%C3%B5es+sobre+o+izichat&type=phone_number&app_absent=0" target="_blank">
                <div
                  className="btn mb-3 w-100 rounded-0 border-0 text-light bg-warning"
                >
                  Falar com representante comercial
                </div>
              </a>
              <div className="text-center mt-4">
                <Link to="/register">
                  <p style={{ fontSize: '0.9rem' }}>Não possui registro? Clique aqui para Cadastrar</p>
                </Link>
              </div>
            </form>
          </Col>
          <Col className="right-column">
            <div>
              <img src={loginImage} alt="Error page" className="loginImage" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );

};

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Login));
