import styled from 'styled-components'

export const Strong = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    max-width: 150px;
 `;

