import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Alert } from 'reactstrap';
import { getBillsByReceiptAndCategory } from 'services/BillsReport';
import Swal from 'sweetalert2';

const ReceiptByCategoryChart = ({ filter }) => {
  const [listReceiptAndCategory, setListReceiptAndCategory] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleListReceiptAndCategory = () => {

    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getBillsByReceiptAndCategory(filter)
      .then((res) => {
        setListReceiptAndCategory(res.data.resultSet);
        setLoading(false);
        setTimeout(() => {
          Swal.close();
        }, 6000);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        const code = !error.response ? "00000" : error.response.data.code;
        const message = !error.response ? "Ocorreu um erro inesperado" : error.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };
  
  useEffect(() => {
    handleListReceiptAndCategory();
  }, [filter]);

  const labels = listReceiptAndCategory.map(register => register.categoryName);
  const series = listReceiptAndCategory.map(register => parseFloat(register.totalReceive));

  const options = {
    chart: {
      type: 'pie'
    },
    labels: labels,
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 300
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    tooltip: {
      y: {
        formatter: (value) => {
          return `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        }
      }
    }
  };

  if (loading) {
    return <div>Carregando...</div>;
  }
  if (series <= 0) {
    return <Alert className="bg-info">Sem Dados...</Alert>;
  }

  return (
    <div>
      <Chart
        options={options}
        series={series}
        type="pie"
        width="400"
      />
    </div>
  );
};

export default ReceiptByCategoryChart;

