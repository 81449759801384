import { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Table,
  Card,
  CardBody,
  CardHeader,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import {
  ClientData,
  Container,
  ClientInfos,
  CellPhonesList,
  ListPhones,
  Phones,
  GroupList,
  Groups,
  ListGroups
} from "../style/ClientScheduleStyled";

import {
  obsStatus,
  getObservationsById,
} from "../../../services/Client-Schedule";

import Swal from "sweetalert2";
import EditObservation from "./EditObservation";

const translatePhoneType = (type) => {
  switch (type) {
    case "WHATSAPP":
      return "WhatsApp";
      case "PHONE":
        return "Fixo";
      case "SMS":
        return "SMS";
    default:
      return type;
  }
};

const ClientDetails = ({ isOpen, toggleModal, client }) => {
  const [list, setList] = useState([]);
  const [showBtnMoreObs, setShowBtnMoreObs] = useState(false);
  const [selectedObservationId, setSelectedObservationId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [initialText, setInitialText] = useState("");
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [visibleRecords, setVisibleRecords] = useState(4);


  const closeModal = () => {
    toggleModal();

    setList([]);
    setShowBtnMoreObs(false);
    setSelectedClientId(null);
    setInitialText("")
    setVisibleRecords(4);
  };

  const toggleEditModal = (obsId, clientId, initialText) => {
    setSelectedObservationId(obsId);
    setSelectedClientId(clientId);
    setInitialText(initialText);
    setIsEditModalOpen(!isEditModalOpen);
  };

  const getObservations = async (isMore = false) => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading(),
    });

    try {
      const { data } = await getObservationsById(client.id, visibleRecords);
      Swal.close();

      if (isMore) {
        setList((prevList) => {
          const startIndex = prevList.length;
          const endIndex = startIndex + visibleRecords;
          const newObs = data.resultSet.slice(startIndex, endIndex);
          return [...prevList, ...newObs];
        });
        setVisibleRecords((prev) => prev + visibleRecords);
      } else {
        setList(data.resultSet ?? []);
        setVisibleRecords(visibleRecords);
      }

      setShowBtnMoreObs(data.resultSet.length > visibleRecords);
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Erro",
        text:
          "Ocorreu um erro ao buscar as observações. Tente novamente mais tarde.",
      });
    }
  };

  const toggleStatus = async (obsId, clientId, active) => {
    const action = active === 1 ? "Inativar" : "Ativar";

    const confirmed = await Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: `Você tem certeza que deseja ${action} a Observação?`,
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar",
    });

    if (!confirmed.isConfirmed) return;

    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      await obsStatus(obsId, clientId, active);

      setList((prevList) => {
        return prevList.map((observation) => {
          if (observation.id === obsId) {
            return { ...observation, active: active };
          }
          return observation;
        });
      });

      Swal.fire({
        icon: "success",
        title: "Operação realizada com sucesso.",
        confirmButtonText: "Fechar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: true,
      });
    } catch (err) {
      const code = !err.response ? "00000" : err.response.data.code;
      const message = !err.response
        ? "Ocorreu um erro inesperado"
        : err.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
        showConfirmButton: true,
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      getObservations(true, visibleRecords);
    }
  }, [isOpen, selectedClientId]);

  return (
    <Modal isOpen={isOpen} toggle={closeModal} className="modal-xl">
      <ModalHeader toggle={closeModal}>Detalhes do Cliente</ModalHeader>
      <ModalBody>
        {client && (
          <ClientInfos>
            <Container>
              <p>Data de Criação:</p>
              <ClientData>{client.createdAt}</ClientData>
            </Container>

            {client.birthDate && (
              <Container>
                <p>Data de Nascimento:</p>
                <ClientData>{client.birthDate}</ClientData>
              </Container>
            )}

            <Container>
              <p>
                Nome: <strong>{client.name}</strong>
              </p>
            </Container>

            {client.document && (
              <Container>
                <p>
                  Documento: <strong>{client.document}</strong>
                </p>
              </Container>
            )}

            <CellPhonesList>
              <p>Telefones:</p>
              <ListPhones>
                {client.phoneNumbers && client.phoneNumbers.length > 0 ? (
                  <>
                    {client.phoneNumbers.map((phone, index) => (
                      <Phones key={index}>{`${phone.number} (${translatePhoneType(
                        phone.type
                      )})`}</Phones>
                    ))}
                  </>
                ) : (
                  <li>Nenhum telefone disponível</li>
                )}
              </ListPhones>
            </CellPhonesList>

            {client.groups && client.groups.length > 0 && (
              <GroupList>
                <p>Grupos:</p>
                <ListGroups>
                  {client.groups.map((group, index) => (
                    <Groups key={index}>{group.name}</Groups>
                  ))}
                </ListGroups>
              </GroupList>
            )}

            <Card className="border-0">
              <CardHeader className="border-0 bg-white">
                <Row>
                  <Col md="9" xl="9">
                    <div className="">
                      <div className="headline-2">Lista de Observações</div>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="widget-table-overflow text-center">
                  {list.length > 0 ? (
                    <>
                      <Table className={`table-striped table-borderless table-hover`} responsive>
                        <thead>
                          <tr>
                            <th className="w-10">Data de Cadastro</th>
                            <th className="w-10">Nome</th>
                            <th className="w-15">Texto</th>
                            <th className="w-15">Status</th>
                            <th className="w-15">Opções</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.map((observation, key) => (
                            <tr key={key}>
                              <td>
                                <p>
                                  <strong>{observation.createdAt}</strong>
                                </p>
                              </td>
                              <td>
                                <p>
                                  <strong>{observation.clientName}</strong>
                                </p>
                              </td>
                              <td>
                                <p>
                                  <strong className="text">{observation.text}</strong>
                                </p>
                              </td>
                              <td>
                                {Number(observation.active) === 1 ? (
                                  <span className="badge badge-success">ATIVO</span>
                                ) : (
                                  <span className="badge badge-danger">INATIVO</span>
                                )}
                              </td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle caret style={{ color: 'black' }}>OPÇÕES</DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={() =>
                                        toggleEditModal(observation.id, observation.clientId, observation.text.toString())
                                      }
                                    >
                                      Editar
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        toggleStatus(
                                          observation.id,
                                          observation.clientId,
                                          Number(observation.active) === 1 ? 0 : 1
                                        )
                                      }
                                    >
                                      {Number(observation.active) === 0
                                        ? "Ativar"
                                        : "Inativar"}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        {showBtnMoreObs && (
                          <tbody>
                            <tr>
                              <td colSpan={5}>
                                <Button
                                  className="w-100"
                                  onClick={() => {
                                    getObservations(true, visibleRecords);
                                  }}
                                >
                                  Carregar mais...
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </Table>

                    </>
                  ) : (
                    <div className="alert alert-secondary">
                      <h4 className="text-dark text-center">
                        Não possuem registros no momento.
                      </h4>
                    </div>
                  )}

                </div>
              </CardBody>
              <EditObservation
                editModalOpen={isEditModalOpen}
                toggleEditModal={() => toggleEditModal(selectedObservationId, "")}
                observationId={selectedObservationId}
                initialText={initialText}
                updateList={getObservations}
                clientId={selectedClientId}
              />
            </Card>
          </ClientInfos>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-primary"
          onClick={closeModal}
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ClientDetails;