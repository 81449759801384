import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Widget from "../Widget/Widget";
import api from "../../services/api";
import Swal from "sweetalert2";
import { maskDocument, phoneMask, maskOnlyNumbers } from "../../utils/mask";
import Select from "react-select";
import { listAllInstanceService } from "../../services/Instance";
import { getAllDepartments } from "../../services/Department";

const FormUser = ({ handleSave, dataUser }) => {
  const dataMock = {
    name: "",
    email: "",
    phone: "",
    password: "",
    document: "",
    instances: [],
    departments: [],
  };
  const [formValues, setFormValues] = useState(dataMock);
  const [state, setState] = useState("CREATE");
  const [instances, setInstances] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [filter, setFilter] = useState({
    limit: 0,
    previusLimit: 0,
  });

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "document") {
      value = maskDocument(value);
    }

    if (name === "phone") {
      value = phoneMask(value);
    }

    setFormValues({ ...formValues, [name]: value });
  };

  const handleShowInstance = () => {
    listAllInstanceService()
      .then(({ data }) => {
        if (!data?.resultSet || data?.resultSet.length <= 0) return;

        setInstances(
          data.resultSet.map((register) => ({
            value: register.id,
            label: register.name,
          }))
        );
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
          showConfirmButton: true,
        });
      });
  };

  const handleShowDepartments = () => {
    getAllDepartments(filter)
      .then(({ data }) => {
        if (!data?.resultSet || data?.resultSet.length <= 0) return;

        const filteredDepartments = data.resultSet.filter(
          (dept) =>
            !formValues.departments.some(
              (selected) => selected.value === dept.id
            )
        );

        setDepartments(
          filteredDepartments.map((register) => ({
            value: register.id,
            label: register.name,
          }))
        );
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
          showConfirmButton: true,
        });
      });
  };

  const fillFields = async () => {
    if (Object.keys(dataUser).length === 0) {
      return false;
    }

    Swal.fire({
      title: "Carregando...",
      html: "Por favor, aguarde ... ",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await api
      .get(`/api/v1/accessUser/${dataUser.id}`)
      .then(({ data: { resultSet } }) => {
        setFormValues({
          ...resultSet,
          instances: resultSet.instance.map((register) => ({
            label: register.name,
            value: register.id,
          })),
          departments: resultSet.departments.map((register) => ({
            label: register.name,
            value: register.departmentId,
          })),
        });
        setState("UPDATE");
        Swal.close();
      })
      .catch((error) => {
        Swal.close();
      });
  };

  useEffect(() => {
    handleShowInstance();
    fillFields();
    handleShowDepartments();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (state !== "CREATE") {
      updateUser(formValues);
      return true;
    }

    createUser(formValues);
  };

  const createUser = async (data) => {
    Swal.fire({
      title: "Processando...  ",
      html: "Por favor, aguarde ... ",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await api
      .post(`/api/v1/accessUser`, {
        ...data,
        phone: maskOnlyNumbers(data.phone),
        document: maskOnlyNumbers(data.document),
      })
      .then(({ data: { status } }) => {
        if (status) {
          setFormValues(dataMock);
          handleSave(status);
        }
        Swal.close();
      })
      
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Não foi possível prosseguir com a solicitação! <br> Entre em contato com nossa equipe IZIDEV",
          text: `[${code}] ${message}`,
        });
      });
  };

  const updateUser = async (data) => {
    Swal.fire({
      title: "Processando...",
      html: "Por favor, aguarde ... ",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await api
      .put(`/api/v1/accessUser`, {
        phone: maskOnlyNumbers(data.phone),
        document: maskOnlyNumbers(data.document),
        ...data,
      })
      .then(({ data: { status } }) => {
        if (status) {
          setFormValues(dataMock);
          handleSave(status);
        }
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Não foi possível prosseguir com a solicitação! <br> Entre em contato com nossa equipe IZIDEV",
          text: `[${code}] ${message}`,
        });
      });
  };

  return (
    <>
      <Widget className="mt-4 mb-4">
        <Form onSubmit={handleSubmit}>
          <Row className="p-3">
            <Col md={12} xl={12} lg={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="headline-2"> Cadastro de Usuário</p>
              </div>
              <div className="mt-2">
                <FormGroup>
                  <Label>Nome Usuário</Label>
                  <Input
                    name="name"
                    placeholder="Nome Usuário"
                    value={formValues.name}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                <div className="form-row">
                  <div className="col-md-6">
                    <FormGroup>
                      <Label>Telefone</Label>
                      <Input
                        value={formValues.phone}
                        name="phone"
                        placeholder="Telefone"
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup>
                      <Label>CPF</Label>
                      <Input
                        name="document"
                        value={formValues.document}
                        placeholder="CPF"
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </div>
                  <Col md="12">
                    <FormGroup>
                      <Label>Instâncias</Label>
                      <Select
                        options={instances}
                        isMulti
                        isLoading={instances.length <= 0}
                        value={formValues.instances}
                        placeholder="Selecione..."
                        onChange={(selectedOptions) => {
                          setFormValues({
                            ...formValues,
                            instances: selectedOptions,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Departamentos</Label>
                      <Select
                        options={departments}
                        isMulti
                        isLoading={departments.length <= 0}
                        value={formValues.departments}
                        placeholder="Selecione..."
                        onChange={(res) => {
                          setFormValues({
                            ...formValues,
                            departments: res,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </div>
              </div>
              <Card className="shadow">
                <CardHeader className="border-0 bg-white">
                  <h5>Informações de acesso</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="col-md-6">
                      <FormGroup>
                        <Label>E-mail</Label>
                        <Input
                          name="email"
                          defaultValue={formValues.email}
                          placeholder="Nome Usuário"
                          type="email"
                          onChange={handleChange}
                          autoComplete="new-password"
                          required
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup>
                        <Label>Senha</Label>
                        <Input
                          name="password"
                          defaultValue={formValues.password}
                          placeholder="Password"
                          type="password"
                          autoComplete="new-password"
                          onChange={handleChange}
                          required={state === "CREATE" ? true : false}
                        />
                        {state !== "CREATE" ? (
                          <Label
                            className="text-muted"
                            style={{ fontSize: "12px" }}
                          >
                            Preencha apenas caso queira alterar a senha
                          </Label>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="d-flex justify-content-end pb-3 pr-3">
            <Col md={2} xl={2}>
              <Button type="submit" color="success" size="lg">
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Widget>
    </>
  );
};

export default FormUser;
