import api from "../api";

export const getBillsByReceiptAndCategory = async (filter) => {
    return await api.get("/api/v1/bills/reports/graphReceiptCategory", {
        params: filter
    });
}

export const getBillsByIncomeAndExpenses = async (filter) => {
    return await api.get('/api/v1/bills/reports/graphIncomeExpenses', {
        params: filter
    });
}

export const getBillsbyExpensesAndCategory = async (filter) => {
    return await api.get('/api/v1/bills/reports/graphExpensesCategory', {
        params: filter
    });
}