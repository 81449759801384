import api from "../api";

export const getTemplate = async () => {
    return await api.get(`/api/v1/campaign/templates`);
};

export const sendTemplate = async (template) => {
    return await api.post(`/api/v1/campaign/templates`, template);
};

export const deleteTemplate = async (templateId, active) => {
    return await api.put(`/api/v1/campaign/templates/${templateId}`, { active });
};