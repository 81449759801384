import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import s from "./Breadcrumbs.module.scss";
import { getDaysToExpire } from "../../services/License/index.js";
import Swal from "sweetalert2";

const Breadcrumbs = (props) => {
  const history = useHistory();
  const [daysToExpire, setDaysToExpire] = useState(0);

  const renderBreadcrumbs = () => {
    let url = props.url;
    let route = props.url.split('/')
      .slice(1)
      .map(route => route
        .split('-')
        .map(word => word.length < 3
          ? word.toUpperCase()
          : word[0].toUpperCase() + word.slice(1))
        .join(' ')
      );
    const length = route.length;

    return route.map((item, index) => {
      let middlewareUrl = "/" + url.split("/").slice(1, index + 2).join("/");

      return (
        <BreadcrumbItem key={uuidv4()}>
          {length === index + 1
            ? item
            : <Link to={middlewareUrl}>
              {item}
            </Link>
          }
        </BreadcrumbItem>
      )
    });
  };

  const handleDaysToExpirePlan = useCallback(async () => {
    try {
      const response = await getDaysToExpire();
      const fetchedDays = response.data.resultSet;
      setDaysToExpire(fetchedDays);

      setTimeout(() => {
        if (fetchedDays <= 0) {
          history.push("/admin/finance");
        }
      }, 1000);

    } catch (error) {
      const code = !error.response ? "00000" : error.response.data.code;
      const message = !error.response
        ? "Ocorreu um erro inesperado"
        : error.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
        showConfirmButton: true,
      });
    }
  }, []);

  useEffect(() => {
    handleDaysToExpirePlan();
  }, [handleDaysToExpirePlan, props.url]);

  let routeArr = props.url.split('/');
  let title = routeArr[routeArr.length - 1];
  let breadcrumbTitle = title[0].toUpperCase() + title.slice(1);

  return (
    <div className={s.breadcrumbs}>
      <div className="headline-2">
        <div className="row">
          <div className="col-md-8">{breadcrumbTitle}</div>
          <div className="col-md-4">
            <div className="text-primary text-right">
              {daysToExpire <= 0 ? (
                <span className="badge badge-warning">
                  <p className="text-white">Ativar registro</p>
                </span>
              ) : (
                <p>
                  Dias restantes:{" "}
                  <span className="badge badge-success">
                    {daysToExpire}
                  </span>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      {breadcrumbTitle !== "Dashboard" && (
        <Breadcrumb tag="nav" listTag="div">
          {renderBreadcrumbs()}
        </Breadcrumb>
      )}
    </div>
  );
};

export default Breadcrumbs;
