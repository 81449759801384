import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Table,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  CardFooter,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Tooltip
} from "reactstrap";

import Widget from "../../components/Widget/Widget.js";
import Swal from "sweetalert2";
import {
  registerDepartment,
  getAllDepartments,
  deleteDepartments,
  updateDepartments
} from "services/Department/index.js";

const Department = () => {
  const [list, setList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [departmentColor, setDepartmentColor] = useState("#999999");
  const [greetingMessage, setGreetingMessage] = useState("");
  const [showBtnMoreRegisters, setShowBtnMoreRegisters] = useState(false);
  const [dropdownStates, setDropdownStates] = useState(list.map(() => false));
  const [tooltipOpen, setTooltipOpen] = useState([]);
  const [tooltipColorOpen, setTooltipColorOpen] = useState([]);
  const [tooltipNameOpen, setTooltipNameOpen] = useState([]);
  const [filter, setFilter] = useState({
    limit: 15,
    previusLimit: 0,
  });
  const [editingDepartment, setEditingDepartment] = useState(null);

  const toggleModal = (isEditing, department) => {
    setIsOpenModal(!isOpenModal);
    if (isEditing) {
      setEditingDepartment(department);
      setName(department.name);
      setDepartmentColor(department.color);
      setGreetingMessage(department.greetingMessage);
    } else {
      setEditingDepartment(null);
      setName("");
      setDepartmentColor("#999999");
      setGreetingMessage("");
    }
  };

  const closeModal = () => {
    setIsOpenModal(false);
    setEditingDepartment(null);
  };

  const toggleDropdown = (key) => {
    const newDropdownStates = [...dropdownStates];
    newDropdownStates[key] = !newDropdownStates[key];
    setDropdownStates(newDropdownStates);
  };

  const handleAdd = async () => {
    try {
      if (!name || !departmentColor) {
        Swal.fire({
          icon: "error",
          title: "Campos em branco",
          text: "O nome é obrigatório! Se nenhuma cor for selecionada, a cor padrão será usada.",
        });
        return;
      }

      Swal.fire({
        title: "Carregando, Por favor aguarde...",
        text: "Por favor aguarde.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const data = {
        name: name,
        color: departmentColor,
        greetingMessage: greetingMessage,
      };

      await registerDepartment(data);
      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Departamento cadastrado com sucesso!",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      setName("");
      setDepartmentColor("#999999");
      setGreetingMessage("");
      getDepartments();
      closeModal();
    } catch (error) {
      console.log(error);
      const code = !error.response ? "00000" : error.response.data.code;
      const message = !error.response
        ? "Ocorreu um erro inesperado"
        : error.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
      });
    }
  };

  const handleEdit = async () => {
    try {
      if (!name || !departmentColor) {
        Swal.fire({
          icon: "error",
          title: "Campos em branco",
          text: "O nome é obrigatório! Se nenhuma cor for selecionada, a cor padrão será usada.",
        });
        return;
      }

      Swal.fire({
        title: "Carregando, Por favor aguarde...",
        text: "Por favor aguarde.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const data = {
        name: name,
        color: departmentColor,
        greetingMessage: greetingMessage,
      };

      await updateDepartments(editingDepartment.id, data);
      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Departamento atualizado com sucesso!",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      setName("");
      setDepartmentColor("#999999");
      setGreetingMessage("");
      getDepartments();
      closeModal();
    } catch (error) {
      console.log(error);
      const code = !error.response ? "00000" : error.response.data.code;
      const message = !error.response
        ? "Ocorreu um erro inesperado"
        : error.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
      });
    }
  };

  const getDepartments = async (isMore = false) => {
    try {
      Swal.fire({
        title: "Carregando, Por favor aguarde...",
        text: "Por favor aguarde",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let filterSearch = {
        ...filter,
      };

      if (!isMore) {
        filterSearch.previusLimit = 0;
      }

      const response = await getAllDepartments(filterSearch);
      const data = response.data;

      if (data.resultSet && Array.isArray(data.resultSet)) {
        if (isMore) {
          setList((prevList) => [...prevList, ...data.resultSet]);
          setShowBtnMoreRegisters(data.resultSet.length >= 15);
        } else {
          setList(data.resultSet || []);
          setShowBtnMoreRegisters(data.resultSet.length >= 15);
        }
        setFilter({
          ...filter,
          previusLimit: data.resultSet.length,
        });
      } else {
        console.error('Data resultSet is missing or not an array');
      }

      Swal.close();
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ocorreu um erro inesperado",
      });
    }
  };

  const toggleTooltip = (index) => {
    setTooltipOpen((prevTooltipOpen) => {
      const newTooltipOpen = [...prevTooltipOpen];
      newTooltipOpen[index] = !newTooltipOpen[index];
      return newTooltipOpen;
    });
  };

  const toggleTooltipColor = (index) => {
    setTooltipColorOpen((prevTooltipOpen) => {
      const newTooltipOpen = [...prevTooltipOpen];
      newTooltipOpen[index] = !newTooltipOpen[index];
      return newTooltipOpen;
    });
  };

  const toggleTooltipName = (index) => {
    setTooltipNameOpen((prevTooltipOpen) => {
      const newTooltipOpen = [...prevTooltipOpen];
      newTooltipOpen[index] = !newTooltipOpen[index];
      return newTooltipOpen;
    });
  };

  const toggleStatus = async (departmentId, active) => {
    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Você tem certeza que deseja excluir o Departamento?",
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar",
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: "Carregando, Por favor aguarde...",
        text: "Por favor aguarde.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      deleteDepartments(departmentId, active)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Operação realizada com sucesso.",
            confirmButtonText: "Fechar",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
          });
          getDepartments();
        })
        .catch((err) => {
          const code = !err.response ? "00000" : err.response.data.code;
          const message = !err.response
            ? "Ocorreu um erro inesperado"
            : err.response.data.message;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `[${code}] ${message}`,
            showConfirmButton: true,
          });
        });
    });
  };

  useEffect(() => {
    getDepartments(true);
  }, []);

  useEffect(() => {
    if (editingDepartment) {
      setName(editingDepartment.name);
      setDepartmentColor(editingDepartment.color);
      setGreetingMessage(editingDepartment.greetingMessage);
    }
  }, [editingDepartment]);

  return (
    <div>
      <Row>
        <Col className="pr-grid-col">
          <Card className="border-0 mb-2 mt-3 shadow">
            <CardHeader className="bg-white border-0">
              <h4 className="headline-2">Cadastro de Departamentos</h4>
            </CardHeader>
            <CardBody className="py-0">
            </CardBody>
            <CardFooter className="border-0 bg-white">
              <Button
                className="btn btn-lg btn-success"
                onClick={() => toggleModal(false)}
              >
                Cadastrar
              </Button>
            </CardFooter>
          </Card>

          <Row className="gutter mt-5">
            <Col>
              <Widget>
                <Card className="border-0">
                  <CardHeader className="border-0 bg-white">
                    <Row>
                      <Col md="9" xl="9">
                        <div>
                          <div className="headline-2">Departamentos</div>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="widget-table-overflow  text-center">
                      {list.length > 0 ? (
                        <>
                          <Table
                            className={`table-striped table-borderless table-hover`}
                            responsive
                          >
                            <thead>
                              <tr>
                                <th className="w-25">Nome</th>
                                <th className="w-25">Cor</th>
                                <th className="w-25">Mensagem de saudação</th>
                                <th className="w-25">Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {list.map((department, index) => (
                                <tr key={index}>
                                  <td>
                                    <Tooltip
                                      className="align-self-start text-start w-auto"
                                      placement="top"
                                      isOpen={tooltipNameOpen[index]}
                                      target={`name-tooltip-${index}`}
                                      toggle={() => toggleTooltipName(index)}
                                      trigger="hover"
                                      delay={{ show: 100, hide: 0 }}
                                      style={{
                                        backgroundColor: "#FFF",
                                        border: "1px solid #C7D0D9",
                                        color: "#000",
                                        fontWeight: "500",
                                        borderRadius: "5px",
                                        width: "15rem",
                                        padding: "10px",
                                        whiteSpace: "pre-wrap",
                                        textAlign: "start",
                                        position: "absolute",
                                        bottom: "10px"
                                      }}
                                    >
                                      {department.name}
                                    </Tooltip>
                                    <div id={`name-tooltip-${index}`}>
                                      {department.name.length > 20 ? `${department.name.slice(0, 20)}...` : department.name}
                                    </div>
                                  </td>
                                  <td>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", width: "100%" }}>
                                      <div id={`color-tooltip-${index}`} style={{ width: '90px', height: '20px', backgroundColor: department.color, border: "1px solid #00000020" }}></div>
                                      <Tooltip
                                        isOpen={tooltipColorOpen[index]}
                                        target={`color-tooltip-${index}`}
                                        toggle={() => toggleTooltipColor(index)}
                                        delay={{ show: 100, hide: 0 }}
                                        style={{
                                          backgroundColor: "#FFF",
                                          border: "1px solid #C7D0D9",
                                          color: "#000",
                                          fontWeight: "500",
                                          borderRadius: "5px",
                                          width: "6rem",
                                          padding: "10px",
                                          whiteSpace: "pre-wrap",
                                          textAlign: "center",
                                          position: "absolute",
                                          bottom: "10px"
                                        }}
                                      >
                                        {department.color}
                                      </Tooltip>
                                    </div>
                                  </td>
                                  <td>
                                    <Tooltip
                                      className="align-self-start text-start w-auto"
                                      placement="top"
                                      isOpen={tooltipOpen[index]}
                                      target={`tooltip-${index}`}
                                      toggle={() => toggleTooltip(index)}
                                      trigger="hover"
                                      delay={{ show: 100, hide: 0 }}
                                      style={{
                                        backgroundColor: "#FFF",
                                        border: "1px solid #C7D0D9",
                                        color: "#000",
                                        fontWeight: "500",
                                        borderRadius: "5px",
                                        width: "15rem",
                                        padding: "10px",
                                        whiteSpace: "pre-wrap",
                                        textAlign: "start",
                                        position: "absolute",
                                        bottom: "10px"
                                      }}
                                    >
                                      {department.greetingMessage}
                                    </Tooltip>
                                    <div id={`tooltip-${index}`}>
                                      {department.greetingMessage.length > 20 ? `${department.greetingMessage.slice(0, 20)}...` : department.greetingMessage}
                                    </div>
                                  </td>
                                  <td>
                                    <Dropdown isOpen={dropdownStates[index]} toggle={() => toggleDropdown(index)}>
                                      <DropdownToggle caret style={{ color: 'black' }}>OPÇÕES</DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem
                                          onClick={() => toggleStatus(department.id, 0)}
                                        >
                                          Excluir
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => toggleModal(true, department)}
                                        >
                                          Editar
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            {showBtnMoreRegisters && (
                              <tbody>
                                <tr>
                                  <td colSpan={5}>
                                    <Button
                                      className="w-100"
                                      onClick={() => {
                                        setFilter({ ...filter });
                                        getDepartments(true);
                                      }}
                                    >
                                      Carregar mais...
                                    </Button>
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </Table>
                        </>
                      ) : (
                        <div className="alert alert-secondary">
                          <h4 className="text-dark text-center">
                            Não possuem registros no momento.
                          </h4>
                        </div>
                      )}
                    </div>
                  </CardBody>

                  <Modal isOpen={isOpenModal} toggle={closeModal} className="modal-lg modal-dialog modal-dialog-centered">
                    <ModalHeader toggle={closeModal}>{editingDepartment ? "Editar" : "Adicionar"} Departamento</ModalHeader>
                    <ModalBody style={{ backgroundColor: "#F7F8FB" }}>
                      <div className="d-flex flex-lg-row flex-column justify-content-between">
                        <FormGroup className="col-lg-6 col-12">
                          <label>Nome</label>
                          <Input
                            type="text"
                            id="name"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                        <FormGroup className="col-lg-6 col-12">
                          <label>Cor</label>
                          <div className="position-relative">
                            <Input
                              type="color"
                              id="departmentColor"
                              name="color"
                              value={departmentColor}
                              onChange={(e) => setDepartmentColor(e.target.value)}
                              className="mr-2 pr-5"
                              style={{ cursor: "pointer" }}
                            />
                            <i className="eva eva-color-picker-outline" style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}></i>
                          </div>
                        </FormGroup>
                      </div>
                      <FormGroup className="col-12">
                        <label>Mensagem de saudação</label>
                        <Input
                          type="textarea"
                          placeholder="Digite uma mensagem de saudação..."
                          value={greetingMessage}
                          onChange={(e) => setGreetingMessage(e.target.value)}
                          style={{
                            minHeight: "150px"
                          }}
                        />
                      </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        className="btn btn-success"
                        onClick={editingDepartment ? handleEdit : handleAdd}
                      >
                        {editingDepartment ? "Editar" : "Adicionar"}
                      </Button>
                      <Button
                        color="secondary"
                        onClick={closeModal}
                      >
                        Cancelar
                      </Button>
                    </ModalFooter>
                  </Modal>
                </Card>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Department;
