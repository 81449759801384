import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import s from "./ErrorPage.module.scss";

import errorImage from "../../assets/errorImage.svg";

const ErrorPage = () => {
  return (
    <div className={s.pageContainer}>
      <div className={s.errorContainer}>
        <h1 className={s.errorCode}>404</h1>
        <p className={s.errorInfo}>Erro</p>
        <p className={s.errorHelp}>Erro</p>
        <Link to="/admin/dashboard">
          <Button
            className={`${s.errorBtn} rounded-pill`}
            type="submit"
            color="secondary-red"
          >
            Back to Home
          </Button>
        </Link>
      </div>
      <div className={s.imageContainer}>
        <img
          className={s.errorImage}
          src={errorImage}
          alt="Error page"
          width="80"
        />
      </div>
      <div className={s.footer}>
        <span className={s.footerLabel}>2021 &copy; IZICHAT</span>
      </div>
    </div>
  );
};

export default ErrorPage;
