import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Card,
    CardHeader,
    CardBody,
    Alert,
    Button,
    Label,
    InputGroup,
    FormGroup,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Table,
    DropdownToggle,
    InputGroupAddon,
    ModalFooter,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
} from "reactstrap";

import Swal from "sweetalert2";
import Widget from "../../components/Widget/Widget";
import {
    deleteBlackListPhone,
    getBlackListPhones,
    postBlackListPhones,
} from "../../services/BlackList-Numbers";
import { phoneMask } from "../../utils/mask";

const BlackList = () => {
    const [list, setList] = useState([]);
    const [showBtnMorePhones, setShowBtnMorePhones] = useState(false);
    const [dropdownStates, setDropdownStates] = useState(list.map(() => false));
    const [numbersPhone, setNumbersPhone] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPhoneId, setSelectedPhoneId] = useState(null);
    const [selectedPhoneInfo, setSelectedPhoneInfo] = useState(null);
    const [phones, setPhones] = useState({
        observations: "",
        numbers: [],
        previusLimit: 0,
        limit: 30
    });

    const openModal = (phoneId) => {
        setSelectedPhoneId(phoneId);
        setIsModalOpen(true);
        const phoneInfo = list.find((phone) => phone.id === phoneId);
        setSelectedPhoneInfo(phoneInfo);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = async () => {
        if (!(phones.numbers.length > 0) || !phones.observations.trim()) {
            Swal.fire({
                icon: "warning",
                title: "Aviso",
                text: "Por favor, todos os campos são obrigatórios!",
            });
            return;
        }

        Swal.fire({
            title: "Carregando, Por favor aguarde...",
            text: "Por favor aguarde",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            await postBlackListPhones(phones);

            Swal.close();

            setPhones({
                ...phones,
                observations: "",
                numbers: [],
                previusLimit: 0,
            });

            setPhones({
                ...phones,
                observations: "",
                numbers: [],
                previusLimit: 0,
            });

            Swal.fire({
                icon: "success",
                title: "Sucesso",
                text: "Mensagens estão sendo enviadas",
            });

            handleListPhones();
            setNumbersPhone("");
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Erro",
                text: "Não foi possível finalizar o registro",
            });
        }
    };

    const toggleDropdown = (key) => {
        const newDropdownStates = [...dropdownStates];
        newDropdownStates[key] = !newDropdownStates[key];
        setDropdownStates(newDropdownStates);
    };

    const handleAddPhone = async () => {
        const numbers = phones.numbers;
        numbersPhone
            .split("\n")
            .map((line) =>
                line.split("\t").map((value) => {
                    numbers.push(value);
                })
            );
        setPhones({
            ...phones,
            numbers: numbers,
        });

        setNumbersPhone("");
    };

    const handleChange = async (ev) => {
        if (ev.target.name === "number") {
            setNumbersPhone(ev.target.value);
        }

        if (ev.target.name === "observations") {
            setPhones({
                ...phones,
                observations: ev.target.value,
            });
        }
    };

    const handleRemovePhone = async (index) => {
        const updatedNumbers = phones.numbers.filter((_, i) => i !== index);
        setPhones((prevNumbers) => ({
            ...prevNumbers,
            numbers: updatedNumbers,
        }));
    };

    const handleRemoveAllPhones = () => {
        setPhones({ ...phones, numbers: [] });
    };

    const handleListPhones = async () => {
        Swal.fire({
            title: "Carregando, Por favor aguarde...",
            text: "Por favor aguarde",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        await getBlackListPhones({ limit: phones.limit, previusLimit: phones.previusLimit })
            .then((response) => {
                Swal.close();
                const phones = response.data.resultSet;
                setList(phones);
            })
            .catch((error) => {
                console.error("Error fetching phones:", error);
            });
    };

    const loadMorePhones = async () => {
        try {
            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            const newPreviusLimit = phones.previusLimit + phones.limit;
            const response = await getBlackListPhones({ limit: phones.limit, previusLimit: newPreviusLimit });
            const newPhones = response.data.resultSet;

            setPhones({ ...phones, previusLimit: newPreviusLimit });
            setList((prevList) => [...prevList, ...newPhones]);

        } catch (error) {
            console.error("Erro ao carregar mais telefones:", error);
        } finally {
            Swal.close();
        }
    };

    const setStatus = async (phoneId) => {
        Swal.fire({
            icon: "question",
            title: "Confirmação",
            text: "Você tem certeza que excluir o telefone?",
            showCancelButton: true,
            cancelButtonText: "Não, sair",
            confirmButtonText: "Sim, continuar"
        }).then(async (confirm) => {
            if (!confirm.isConfirmed) return;

            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde.",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            try {
                await deleteBlackListPhone(phoneId, 0);

                Swal.fire({
                    icon: "success",
                    title: "Operação realizada com sucesso.",
                    confirmButtonText: "Fechar",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                });

                setList((prevList) => prevList.filter((phone) => phone.id !== phoneId));


            } catch (err) {
                const code = !err.response ? "00000" : err.response.data.code;
                const message = !err.response
                    ? "Ocorreu um erro inesperado"
                    : err.response.data.message;
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `[${code}] ${message}`,
                    showConfirmButton: true,
                });
            }
        });
    };

    useEffect(() => {
        handleListPhones();
    }, []);

    useEffect(() => {
        const hasMorePhones =
            list.length > 0 &&
            list.length % phones.limit === 0 &&
            list.length !== phones.previusLimit;
        setShowBtnMorePhones(hasMorePhones);
    }, [list, phones.limit, phones.previusLimit]);

    return (
        <>
            <Row>
                <Col className="pr-grid-col d-flex flex-column flex-lg-row justify-content-lg-start align-items-lg-start justify-content-center align-items-center col-12">
                    <Card className="border-0 w-100">
                        <CardHeader className="border-0 bg-white">
                            <div className="headline-2">Cadastrar Telefones na BlackList</div>
                        </CardHeader>
                        <CardBody>
                            <Alert color="warning" className="text-warning">
                                <h5>Instruções</h5>
                                <p>
                                    Cadastre telefones e forneça observações sobre problemas de funcionamento.
                                </p>
                            </Alert>
                            <FormGroup>
                                <label>Observação do Cadastro</label>
                                <textarea
                                    name="observations"
                                    value={phones.observations}
                                    className="form-control"
                                    onChange={handleChange}
                                ></textarea>
                            </FormGroup>
                            <FormGroup>
                                <Label>Telefones</Label>
                                <InputGroup>
                                    <textarea
                                        name="number"
                                        value={numbersPhone}
                                        className="form-control"
                                        onChange={handleChange}
                                        onBlur={handleChange}
                                    ></textarea>
                                    <InputGroupAddon addonType="append">
                                        <Button color="secondary" onClick={handleAddPhone}>
                                            ADICIONAR NÚMEROS
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                                <div>
                                    {phones.numbers.length > 0 ? (
                                        <>
                                            <div className="mt-2 alert alert-secondary">
                                                <div className="d-flex flex-lg-row flex-column mb-4">
                                                    <h4 className="text-dark m-0 mr-2 mb-lg-0 mb-2">Números adicionados</h4>
                                                    <div className="badge badge-success d-flex align-items-center" style={{ maxWidth: "120px" }}>Quantidade: {phones.numbers.length}</div>
                                                </div>
                                                <div className="d-flex flex-wrap" style={{ maxHeight: "300px", overflow: "auto", gap: "10px", maxWidth: "70vw" }}>
                                                    {phones.numbers.map((number, index) => (
                                                        <div className="d-flex align-itens-center mb-1">
                                                            <span key={index} className="badge badge-success" style={{ borderTopRightRadius: "0", borderBottomRightRadius: "0" }}>
                                                                {number}
                                                            </span>
                                                            <button
                                                                className="btn-danger border-0"
                                                                style={{ padding: "2px 5px", fontSize: "12px", textAlign: "center", display: "flex", justifyContent: "center" }}
                                                                onClick={() => handleRemovePhone(index)}
                                                            >
                                                                X
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <button
                                                className="btn btn-danger"
                                                onClick={handleRemoveAllPhones}
                                            >
                                                Remover Todos
                                            </button>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </FormGroup>
                            <Button
                                className="btn btn-lg btn-success mt-5"
                                onClick={handleSubmit}
                            >
                                Salvar
                            </Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="gutter mt-5">
                <Col>
                    <Widget>
                        <Card className="border-0">
                            <CardHeader className="border-0 bg-white">
                                <Row>
                                    <Col md="9" xl="9">
                                        <div className="">
                                            <div className="headline-2">Telefones Cadastrados</div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="widget-table-overflow text-center">
                                    {list.length > 0 ? (
                                        <>
                                            <Table
                                                className={`table-striped table-borderless table-hover`}
                                                responsive
                                            >
                                                <thead>
                                                    <tr>
                                                        <th className="w-14">DATA CADASTRO</th>
                                                        <th className="w-14">TELEFONE</th>
                                                        <th className="w-14">OBSERVAçÕES</th>
                                                        <th className="w-14">STATUS</th>
                                                        <th className="w-14">OPÇÕES</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {list.map((phones, key) => (
                                                        <tr key={key}>
                                                            <td>
                                                                <p>
                                                                    <strong>{phones.createdAt}</strong>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <strong className="text">
                                                                        {phones.phone && phoneMask(phones.phone)}
                                                                    </strong>
                                                                </p>
                                                            </td>

                                                            <td>
                                                                <p>
                                                                    <strong className="text">
                                                                        {phones.observations && phones.observations.length ? (
                                                                            phones.observations.length > 10
                                                                                ? `${phones.observations.substring(0, 10)}...`
                                                                                : phones.observations
                                                                        ) : (
                                                                            <p className="text-danger">INVALID</p>
                                                                        )}
                                                                    </strong>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                {Number(phones.active) === 1 && (
                                                                    <span className="badge badge-success">
                                                                        ATIVO
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <Dropdown isOpen={dropdownStates[key]} toggle={() => toggleDropdown(key)}>
                                                                    <DropdownToggle caret style={{ color: 'black' }}>OPÇÕES</DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem
                                                                            onClick={() => {
                                                                                setStatus(phones.id);
                                                                            }}
                                                                        >
                                                                            Deletar
                                                                        </DropdownItem>
                                                                        <DropdownItem
                                                                            onClick={() => {
                                                                                openModal(phones.id);
                                                                            }}
                                                                        >
                                                                            Informações
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                {showBtnMorePhones && (
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={7}>
                                                                <Button
                                                                    className="w-100"
                                                                    onClick={loadMorePhones}
                                                                >
                                                                    Carregar mais...
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                            </Table>
                                        </>
                                    ) : (
                                        <div className="alert alert-secondary">
                                            <h4 className="text-dark text-center">
                                                Não possuem registros no momento.
                                            </h4>
                                        </div>
                                    )}
                                </div>

                                <Modal isOpen={isModalOpen} toggle={closeModal} className="modal-lg modal-dialog-centered">
                                    <ModalHeader toggle={closeModal}>Informações do Contato</ModalHeader>
                                    <ModalBody>
                                        {selectedPhoneInfo && (
                                            <>
                                                <FormGroup>
                                                    <Label>Data de Cadastro:</Label>
                                                    <Input type="text" value={selectedPhoneInfo?.createdAt || ''} readOnly className="bg-transparent-bg text-success" />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>Telefone</Label>
                                                    <Input type="text" value={phoneMask(selectedPhoneInfo?.phone || '')} readOnly className="bg-transparent-bg text-success" />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>Observação</Label>
                                                    <Input type="textarea" value={selectedPhoneInfo?.observations || ''} readOnly className="bg-transparent-bg text-success" />
                                                </FormGroup>
                                            </>
                                        )}
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            color="secondary"
                                            onClick={closeModal}
                                        >
                                            Fechar
                                        </Button>

                                    </ModalFooter>
                                </Modal>
                            </CardBody>
                        </Card>
                    </Widget>
                </Col>
            </Row>
        </>
    );
};

export default BlackList;
