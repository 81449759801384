import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  Table,
} from "reactstrap";
import Swal from "sweetalert2";
import {
  updatePasswordService,
} from "../../services/Register/User";

const UpdatePasswordView = (props) => {
  const history = useHistory();
  const [register, setRegister] = useState({
    password: null,
    newPassword: null,
    repeatPassword: null,
  });
  const [msgError, setMsgError] = useState("");
  const [disableBtnSave, setDisableBtnSave] = useState(true);

  const user = JSON.parse(localStorage.getItem("data_user_izichat_app"));

  const handleValidatePassword = (password) => {
    const characters = (password.match(/[A-Za-z]/g) || []).length;
    const num = (password.match(/[0-9]/g) || []).length;
    const symbol = (password.match(/\W|_/g) || []).length;

    setDisableBtnSave(true);

    if (characters < 1) {
      setMsgError("A senha deve ter ao menos (1) uma letra");
      return;
    }
    if (num < 1) {
      setMsgError("A senha deve conter ao menos 1 (um) número");
      return;
    }
    if (symbol < 1) {
      setMsgError("A senha deve conter ao menos 1 (um) caractere especial");
      return;
    }

    if (password.length < 6) {
      setMsgError("A senha deve conter ao menos 6 caracteres");
      return;
    }

    setMsgError("");
  };

  const handleDisableBtn = () => {
    setDisableBtnSave(true);

    if (!register.password) {
      setMsgError("É preciso informar sua senha atual");
      setDisableBtnSave(true);
      return;
    }

    if (!register.newPassword) {
      setMsgError("É necessário informar a nova senha");
      setDisableBtnSave(true);
      return;
    }

    if (!register.repeatPassword) {
      setMsgError("É necessário repetir a nova senha");
      setDisableBtnSave(true);
      return;
    }

    if (register.newPassword !== register.repeatPassword) {
      setMsgError(
        "As senhas informadas, não são identicas, por favor verifique"
      );
      setDisableBtnSave(true);
      return;
    }

    handleValidatePassword(register.newPassword);

    if (msgError !== "") {
      return;
    }

    setMsgError("");
    setDisableBtnSave(false);
  };

  const handleSubmit = async () => {
    handleDisableBtn();

    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      message: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await updatePasswordService({
      oldPassword: register.password,
      newPassword: register.newPassword,
      confirmPassword: register.repeatPassword,
    })
      .then(async () => {
        localStorage.removeItem("authenticated_izichat");
        localStorage.removeItem("token_izichat_xpto");
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Senha alterada com sucesso, realize login novamente com a nova senha!",
        }).then(() => {
          history.push("/login");
        });
        setRegister({
          password: null,
          newPassword: null,
          repeatPassword: null,
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleDisableBtn();
  }, [register.password, register.newPassword, register.repeatPassword]);

  return (
    <>
      <Card className="shadow">
        <CardHeader className="bg-white border-0">
          <h2>Alterar senha</h2>
        </CardHeader>
        <CardBody>
          {user?.isUpdatePassword > 0 && (
            <Alert color="warning" className="text-warning">
              <strong>Atenção:</strong> Realize a alteração de sua senha, informe a senha temporária enviada por e-mail e insira uma nova senha.
            </Alert>
          )}
          <Row>
            <Col md="4">
              <FormGroup>
                <Label>Senha atual</Label>
                <Input
                  type="password"
                  autoComplete="new-password"
                  value={register.password}
                  onChange={(ev) => {
                    setRegister({
                      ...register,
                      password: ev.target.value,
                    });
                    handleDisableBtn();
                  }}
                  onBlur={() => handleDisableBtn()}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>Nova senha</Label>
                <InputGroup>
                  <Input
                    type="password"
                    autoComplete="new-password"
                    value={register.newPassword}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        newPassword: ev.target.value,
                      });
                      handleDisableBtn();
                    }}
                    onBlur={() => handleDisableBtn()}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>Confirmar nova senha</Label>
                <Input
                  type="password"
                  autoComplete="new-password"
                  value={register.repeatPassword}
                  onChange={(ev) => {
                    setRegister({
                      ...register,
                      repeatPassword: ev.target.value,
                    });
                    handleDisableBtn();
                  }}
                  onBlur={() => handleDisableBtn()}
                />
              </FormGroup>
            </Col>
          </Row>
          {msgError && (
            <Alert color="warning" className="text-warning">
              {msgError}
            </Alert>
          )}
        </CardBody>
        <CardFooter>
          <Button
            color="success"
            disabled={disableBtnSave}
            onClick={handleSubmit}
          >
            Realizar alteração da senha
          </Button>
        </CardFooter>
      </Card>

    </>
  );
};

export default UpdatePasswordView;