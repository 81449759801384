import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  FormGroup,
} from "reactstrap";
import "@webscopeio/react-textarea-autocomplete/style.css";
import AnalyticsGraphView from './Component/AnalyticsGraphView';
import GraphInstanceandStatus from './Component/GraphInstanceAndStatus';
import GraphDayAndStatus from './Component/GraphDayAndStatus';
import { listAllInstances } from '../../services/Instance';
import { getAllGroups } from "../../services/Client-Schedule";

const Analyitcs = () => {
  const [instances, setInstances] = useState([]);
  const [groups, setGroups] = useState([]);
  const [filter, setFilter] = useState({
    dateType: "createdAt",
    instance: [],
    status: null,
  });
  const [isSubmit, setIsSubmit] = useState(false);

  const handleListInstance = () => {
    listAllInstances().then((res) => {
      setInstances(res.data.resultSet);
    });
  };

  const handleListGroup = () => {
    getAllGroups().then((res) => {
      setGroups(res.data.resultSet);
    });
  };

  const handleChangeFilter = (ev) => {
    if (ev.target.id === "dateStart") {
      setFilter({
        ...filter,
        dateStart: ev.target.value,
      });
    }
    if (ev.target.id === "dateEnd") {
      setFilter({
        ...filter,
        dateEnd: ev.target.value,
      });
    }
    if (ev.target.id === "dateType") {
      setFilter({
        ...filter,
        dateType: ev.target.value
      });
    }
    if (ev.target.id === "instance") {
      setFilter({
        ...filter,
        instance: ev.target.value
      });
    }
    if (ev.target.id === "group") {
      setFilter({
        ...filter,
        group: ev.target.value,
      });
    }
    if (ev.target.id === "status") {
      setFilter({
        ...filter,
        status: ev.target.value
      });
    }
  };

  useEffect(() => {
    handleListInstance();
    handleListGroup();
  }, []);

  return (
    <div>
      <Row className="gutter">
        <Col className="pr-grid-col" xs={12} lg={12}>
          <Card className="border-0 mb-5 mt-3 shadow">
            <CardHeader className="bg-white border-0">
              <h4>Filtro</h4>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label>Data de Cadastro</label>
                    <div className="input-group">
                      <input
                        id="dateStart"
                        type="date"
                        className="form-control form-control-md"
                        onChange={handleChangeFilter}
                      />
                      <input
                        id="dateEnd"
                        type="date"
                        className="form-control form-control-md"
                        onChange={handleChangeFilter}
                      />
                      <select
                        id="dateType"
                        className="form-control form-control-md"
                        onChange={handleChangeFilter}
                      >
                        <option value="createdAt" selected>
                          Data de cadastro
                        </option>
                      </select>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label>Instancia</label>
                    <select
                      id="instance"
                      className="form-control form-control-md"
                      onChange={handleChangeFilter}
                    >
                      <option value="" selected>
                        Selecione...
                      </option>
                      {instances.length > 0 ? (
                        instances.map((instance, key) => (
                          <option key={key} value={instance.id}>
                            {instance.name}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label>Status</label>
                    <select
                      id="status"
                      className="form-control form-control-md"
                      onChange={handleChangeFilter}
                    >
                      <option value="">TODOS</option>
                      <option value="1">ENVIADO</option>
                      <option value="0">AGUARDANDO</option>
                      <option value="-1">ERRO</option>
                      <option value="-2">CANCELADO</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label>Grupo:</label>
                    <select
                      id="group"
                      className="form-control form-control-md"
                      onChange={handleChangeFilter}
                    >
                      <option value="" selected>
                        Selecione...
                      </option>
                      {groups.length > 0 ? (
                        groups.map((group, key) => (
                          <option key={key} value={group.id}>
                            {group.name}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="border-0 bg-white">
              <button
                className="btn btn-success btn-md"
                onClick={(ev) => {
                  console.log(ev.target)
                  setIsSubmit(Math.random())
                }}
              >
                Buscar
              </button>
            </CardFooter>
          </Card>
          <Card className="border-0 shadow">
            <CardHeader className="border-0 bg-white">
              <h4>Estatísticas </h4>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="12" xl="6">
                  <Card className='mb-5'>
                    <CardHeader className='bg-white border-0'><h5>Mensagens por Status:</h5></CardHeader>
                    <CardBody>
                      <AnalyticsGraphView className="p-2" type="bar" filter={filter} isSubmit={isSubmit} />
                    </CardBody>
                  </Card>
                </Col>
                <Col md="12" xl="6">
                  <Card className='mb-5'>
                    <CardHeader className='bg-white border-0'><h5>Mensagens por Instancia e Status:</h5></CardHeader>
                    <CardBody>
                      <GraphInstanceandStatus className="p-3" type="bar" filter={filter} isSubmit={isSubmit} />
                    </CardBody>
                  </Card>
                </Col>
                <Col md="12">
                  <Card>
                    <CardHeader className='bg-white border-0'><h5>Mensagens por dia e status:</h5></CardHeader>
                    <CardBody>
                      <GraphDayAndStatus className="p-3" type="bar" filter={filter} isSubmit={isSubmit} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Analyitcs;