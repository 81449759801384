import React from 'react';
import {
    PreviewContainer,
    PhoneHeader,
    PreviewHeader,
    PreviewFooter,
    PreviewWrap,
    Left,
    Center,
    Icons,
    ImgBateria,
    ImgContect,
    ChatbotAvatar,
    ImgAvatar,
    PreviewBody,
    Paragraph
} from "./style/PreviewStyle.js";

import IconBateria from "./assets/bateria.png"
import IconConect from "./assets/conexao.png"
import Chatbot from "./assets/chatbot.png"
import Send from "./assets/send.png"

export default function Preview() {
    return (
        <PreviewContainer>
            <PhoneHeader>
                <Left />
                <Center />
                <Icons>
                    <ImgBateria src={IconBateria} alt="Bateria" />
                    <ImgContect src={IconConect} alt="Conexão" />
                </Icons>
            </PhoneHeader>
            <PreviewWrap>
                <PreviewHeader>
                    <ChatbotAvatar>
                        <ImgAvatar src={Chatbot} alt="Chatbot"></ImgAvatar>
                    </ChatbotAvatar>
                    <p>Bate-Papo</p>
                </PreviewHeader>
                <PreviewBody>
                    <Paragraph id="message-preview"></Paragraph>
                </PreviewBody>
                <PreviewFooter>
                    <p>Escreva sua mensagem</p>
                    <img src={Send} alt="Send" />
                </PreviewFooter>
            </PreviewWrap>
        </PreviewContainer>
    )
}