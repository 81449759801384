import api from "../api";

export const registerDepartment = async (data) => {
  return await api.post(`/api/v1/department`, data);
};

export const getAllDepartments = async (filter) => {
  return await api.get(`/api/v1/department`, {
    params: filter,
  });
};

export const deleteDepartments = async (departmentId, newStatus) => {
  return await api.put(`/api/v1/department/${departmentId}`, newStatus)
};

export const updateDepartments = async (departmentId, data) => {
  return await api.put(`/api/v1/department/update/${departmentId}`, data)
};