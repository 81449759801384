import api from "../api";

export const newEvent = async ($data) => {
    return await api.post(`/api/v1/calendar`, $data);
};

export const getEvents = async () => {
    return await api.get(`/api/v1/calendar`);
};

export const updateEvent = async (eventId, eventData) => {
    return await api.put(`/api/v1/calendar/${eventId}`, eventData);
};


export const deleteEvent = async (eventId) => {
    return await api.delete(`/api/v1/calendar/${eventId}`);
};
