import React, { useState, useRef, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

import {getEvents} from '../../services/Calendar/index.js';

import {
    Button,
    Card,
    CardBody,
    CardHeader,
} from 'reactstrap';
import Swal from 'sweetalert2';

import "./assets/Calendar.css"

import EventModal from './Components/EventModal.js';

const Calendar = () => {
    const calendarRef = useRef(null);
    const [events, setEvents] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [allDay, setAllDay] = useState(false);
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');

    const handleList = async () => {
        Swal.fire({
            title: "Carregando, Por favor aguarde...",
            text: "Por favor aguarde",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            const response = await getEvents();
            const fetchedEvents = response.data.resultSet;

            const formattedEvents = fetchedEvents.map(event => ({
                ...event,
                start: new Date(event.dateStart),
                end: new Date(event.dateEnd),
                title: event.title,
                description: event.description,
                allDay: event.allDay || false,
            }));

            setEvents(formattedEvents);
            Swal.close();
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro ao carregar eventos.',
                showConfirmButton: true,
            });
        }
    };

    const toggleModal = () => {
        setModal(!modal);
        if (!modal && selectedEvent) {
            setTitle(selectedEvent.title);
            setMessage(selectedEvent.description);
            setAllDay(selectedEvent.allDay);
        } else {
            setSelectedEvent(null);
            setTitle('');
            setMessage('');
            setAllDay(false);
            setDateStart('');
            setDateEnd('');
        }
    };

    const handleEventClick = ({ event }) => {
        setSelectedEvent(event);
        setTitle(event.title);
        setMessage(event.extendedProps.description);
        setAllDay(event.allDay);

        const formatDateTimeLocal = (date) => {
            if (!date) return '';
            const d = new Date(date);
            const pad = (num) => (num < 10 ? '0' + num : num);
            const year = d.getFullYear();
            const month = pad(d.getMonth() + 1);
            const day = pad(d.getDate());
            const hours = pad(d.getHours());
            const minutes = pad(d.getMinutes());
            return `${year}-${month}-${day}T${hours}:${minutes}`;
        };

        setDateStart(formatDateTimeLocal(event.start));
        setDateEnd(formatDateTimeLocal(event.end));
        setModal(true);
    };

    const handleClickDate = (arg) => {
        setSelectedEvent(null);
        setTitle('');
        setMessage('');
        setAllDay(false);
        const selectedDate = arg.dateStr;
        setDateStart(selectedDate + 'T00:00');
        setDateEnd(selectedDate + 'T23:59');
        setModal(true);
    };

    useEffect(() => {
        handleList();
    }, []);

    return (
        <section >
            <Card className="shadow border-0">
                <CardHeader>
                    <h3 className="m-0">
                        <i className="eva eva-calendar-outline" /> Agenda
                    </h3>
                </CardHeader>
                <CardBody>
                    <Button
                        color='primary'
                        className='d-flex align-items-center'
                        onClick={() => {
                            toggleModal()
                        }}
                    >
                        Agendar Evento
                    </Button>
                </CardBody>
            </Card>
            <Card className='border-0 mt-5 container-fluid shadow'>
                <CardBody className='p-0 pt-2'>
                    <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,listWeek'
                        }}
                        locales={ptLocale}
                        locale={'pt-br'}
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        initialView="dayGridMonth"
                        weekends={true}
                        dateClick={handleClickDate}
                        events={events}
                        eventClick={handleEventClick}
                        aspectRatio="2"
                    />
                </CardBody>
            </Card>
            <EventModal
                modal={modal}
                toggleModal={toggleModal}
                selectedEvent={selectedEvent}
                dateStartClick={dateStart}
                dateEndClick={dateEnd}
                handleList={handleList}
            />
        </section>
    );
};

export default Calendar;



