import api from "../api";

export const getChatbot = async () => {
  return await api.get(`/api/v1/chatbot`);
};

export const sendChatbot = async (chatbotData) => {
  return await api.post(`/api/v1/chatbot`, chatbotData);
};

export const getChatbotById = async (chatbotId) => {
  return await api.get(`/api/v1/chatbot/${chatbotId}`);
};

export const setChatbotById = async (chatbotId, updatedChatbotData) => {
  return await api.put(`/api/v1/chatbot/${chatbotId}`, updatedChatbotData);
};

export const setStatusService = async (chatbotId, newStatus) => {
  return await api.put(`/api/v1/chatbot/status/${chatbotId}`, { newStatus });
};



export const getTriggers = async (chatbotQuestionId) => {
  return await api.get(`/api/v1/chatbot/trigger`, {
    params: {
      chatbotQuestionId
    }
  });
};

export const sendTriggers = async (newTriggersData) => {
  return await api.post(`/api/v1/chatbot/trigger`, newTriggersData);
};

export const updateTrigger = async (chatbotTriggerId, editedTriggerData) => {
  return await api.put(`/api/v1/chatbot/trigger/${chatbotTriggerId}`, editedTriggerData);
}

export const setStatusTrigger = async (chatbotTriggerId, newStatus) => {
  return await api.put(`/api/v1/chatbot/statusTrigger/${chatbotTriggerId}`, { newStatus });
}


export const listChatbotQuestionService = async (chatbotId) => {
  return await api.get(`/api/v1/chatbot/question`, {
    params: {
      chatbotId: chatbotId,
    },
  });
};

export const getChatbotQuestionService = async (chatbotId) => {
  return await api.get(`/api/v1/chatbot/question/${chatbotId}`);
};

export const createChatbotQuestionService = async (
  chatbot = { chatbotId: null, type: null, question: null }
) => {
  return await api.post(`/api/v1/chatbot/question`, {
    chatbotId: chatbot.chatbotId,
    type: chatbot.type,
    question: chatbot.question,
  });
};

export const updateChatbotQuestionService = async (
  chatbot = { chatbotId: null, type: null, question: null }
) => {
  return await api.put(`/api/v1/chatbot/question/${chatbot.id}`, {
    type: chatbot.type,
    question: chatbot.question,
    chatbotId: chatbot.chatbotId,
  });
};

export const getWhiteList = async () => {
  return await api.get(`/api/v1/chatbot/whitelist`);
};

export const phoneWhiteList = async (number) => {
  return await api.post(`/api/v1/chatbot/whitelist`, number);
};


export const clientStatus = async (whiteListId, newStatus) => {
  return await api.put(`/api/v1/chatbot/whitelist/status/${whiteListId}`, { newStatus });
};