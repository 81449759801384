import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  Label,
} from "reactstrap";
import Swal from "sweetalert2";

import { updateObservation } from "../../../services/Client-Schedule/index.js";

const EditObservation = ({ editModalOpen, toggleEditModal, observationId, initialText, updateList, clientId }) => {
  const [text, setText] = useState(initialText || "");

  useEffect(() => {
    setText(initialText || "");
  }, [initialText]);

  const handleSave = async () => {
    if (!text) {
      Swal.fire({
        icon: 'error',
        title: 'Campos em branco',
        text: 'O Texto da Observação é obrigatório',
      });
      return;
    }

    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const data = {
        clientId: parseInt(clientId),
        obsId: parseInt(observationId),
        text,
      };

      const response = await updateObservation(observationId, clientId, data);

      if (response) {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Observação atualizada com sucesso!',
          confirmButtonText: "OK",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });

        updateList();
        resetModal();
      }
    } catch (error) {
      console.log(error);
      const code = !error.response ? '00000' : error.response.data.code;
      const message = !error.response ? 'Ocorreu um erro inesperado' : error.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    }
  };

  const resetModal = () => {
    setText("");
    toggleEditModal();
  };

  return (
    <Modal isOpen={editModalOpen} toggle={resetModal} className="modal-lg">
      <ModalHeader toggle={resetModal}>Editar Observação</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="text">Texto da Observação</Label>
          <Input
            type="textarea"
            id="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            required
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={resetModal}>
          Fechar
        </Button>
        <Button color="primary" onClick={handleSave}>
          Salvar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditObservation;