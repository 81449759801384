import { useEffect } from "react";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getMessageByStatus } from "../../../services/Instance";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Alert } from "reactstrap";


const AnalyticsGraphView = ({ type = "bar", filter = {}, isSubmit}) => {
  const [series, setSeries] = useState([]);
  // const[filter, setFilter]= useState({
  //   dateType: 'createdAt',
  //   instance: [],
  //   status: null,
  // });

  console.log(filter);

  useEffect(() => {
    setSeries([])
    getMessageByStatus(filter)
      .then(({ data }) => {
        setSeries([
          {
            name: 'AGUARDANDO',
            data: data.resultSet.map((register) => { return register.qtdWait })
          },
          {
            name: 'ENVIADO',
            data: data.resultSet.map((register) => { return register.qtdSend })
          },
          {
            name: 'ERRO',
            data: data.resultSet.map((register) => { return register.qtdErr })
          },
          {
            name: 'CANCELADO',
            data: data.resultSet.map((register) => { return register.qtdCanceled })
          },
        ]);
      });
  }, [isSubmit]);

  const state = {
    series: series,
    options: {
      chart: {
        type: 'bar',
        height: 430
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: ['AGUARDANDO', 'ENVIADO', 'ERRO', 'CANCELADO'],
      }
    },
  };

  if (series.length <= 0) {
      return <>
          <Alert color="secondary" className="text-secondary">Carregando</Alert>
      </>
  }

  return (
    <div id="chart">
      <ReactApexChart
        options={state.options} series={state.series}
        type={type}
      />
    </div>
  );
};

export default AnalyticsGraphView;