import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup.js";
import { changeActiveSidebarItem } from "../../actions/navigation.js";
import Logo from "../../assets/logo.png";
import cn from "classnames";
import {
  Li,
  Link,
  Icon,
  ArrowIconImage,
  ArrowIconImageMenu,
  ArrowIconImageOne,
  ServiceButton,
} from "./LinkStyle";

import ModalAddressComponent from "../User/ModalAddressComponent";
import ExpandIcon from "./assets/expandIcon.png";
import CollapseIcon from "./assets/collapseIcon.png";


const Sidebar = (props) => {
  const { activeItem = "", ...restProps } = props;
  const [burgerSidebarOpen, setBurgerSidebarOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("data_user_izichat_app"));
  const [campanhasOpen, setCampanhasOpen] = useState(false);
  const [isArrowRotated, setIsArrowRotated] = useState(false);

  const [scheduleOpen, setScheduleOpen] = useState(false);
  const [isArrowScheduleRotated, setIsArrowScheduleRotated] = useState(false);

  const [chatServiceOpen, setChatServiceOpen] = useState(false);
  const [isArrowChatServiceRotated, setIsArrowChatServiceRotated] = useState(false);

  const [FinanceServiceOpen, setFinanceServiceOpen] = useState(false);
  const [isArrowFinanceServiceRotated, setIsArrowFinanceServiceRotated] = useState(false);

  useEffect(() => {
    if (props.sidebarOpened) {
      setBurgerSidebarOpen(true);
    } else {
      setTimeout(() => {
        setBurgerSidebarOpen(false);
      }, 0);
    }
  }, [props.sidebarOpened]);

  const toggleCampanhas = () => {
    setCampanhasOpen(!campanhasOpen);
    setIsArrowRotated(!isArrowRotated);
  };

  const toggleSchedule = () => {
    setScheduleOpen(!scheduleOpen);
    setIsArrowScheduleRotated(!isArrowScheduleRotated);
  };

  const toggleChatService = () => {
    setChatServiceOpen(!chatServiceOpen);
    setIsArrowChatServiceRotated(!isArrowChatServiceRotated);
  };

  const toggleFinanceService = () => {
    setFinanceServiceOpen(!FinanceServiceOpen);
    setIsArrowFinanceServiceRotated(!isArrowFinanceServiceRotated);
  };


  return (
    <>
      <nav className={cn(s.root, { [s.sidebarOpen]: burgerSidebarOpen })}>
        <header className={s.logo}>
          <img src={Logo} alt="Logo"></img>
        </header>
        <ul className={s.nav}>
          <LinksGroup
            onActiveSidebarItemChange={(activeItem) =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header="Painel"
            isHeader
            iconName={<i className={"eva eva-home-outline"} />}
            link="/admin/dashboard"
            index="dashboard"
          />

          <LinksGroup
            onActiveSidebarItemChange={(activeItem) =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header="Estatística"
            isHeader
            iconName={<i className={"eva eva-activity-outline"} />}
            link="/admin/analytics"
            index="Analytics"
          />

          <Li className="d-flex align-items-center justify-content-start w-100">
            <Link
              className="d-flex align-items-center text-decoration-none w-100"
              style={{}}
              href="javascript:void(0);"
              onClick={toggleChatService}
            >
              <Icon className={"eva eva-paper-plane-outline"} /> Atendimento
              {isArrowChatServiceRotated ? (
                <ArrowIconImage src={ExpandIcon} alt="Expand" />
              ) : (
                <ArrowIconImage src={CollapseIcon} alt="Collapse" />
              )}
            </Link>
          </Li>

          {chatServiceOpen && (
            <>
              <LinksGroup
                onActiveSidebarItemChange={(activeItem) =>
                  props.dispatch(changeActiveSidebarItem(activeItem))
                }
                activeItem={props.activeItem}
                header="Chat"
                isHeader
                link="/admin/chat"
                index="Chat"
              />

              <LinksGroup
                onActiveSidebarItemChange={(activeItem) =>
                  props.dispatch(changeActiveSidebarItem(activeItem))
                }
                activeItem={props.activeItem}
                header="Configuração"
                isHeader
                link="/admin/chat-config"
                index=""
              />
            </>
          )}

          <Li className="d-flex align-items-center justify-content-start w-100">
            <Link
              className="d-flex align-items-center text-decoration-none w-100"
              style={{}}
              href="javascript:void(0);"
              onClick={toggleFinanceService}
            >
              <Icon className={"eva eva-credit-card-outline"} />Financeiro
              {isArrowFinanceServiceRotated ? (
                <ArrowIconImage src={ExpandIcon} alt="Expand" />
              ) : (
                <ArrowIconImage src={CollapseIcon} alt="Collapse" />
              )}
            </Link>
          </Li>

          {FinanceServiceOpen && (
            <>
              <LinksGroup
                onActiveSidebarItemChange={(activeItem) =>
                  props.dispatch(changeActiveSidebarItem(activeItem))
                }
                activeItem={props.activeItem}
                header="Contas"
                isHeader
                link="/admin/bills"
                index="Bills"
              />

              <LinksGroup
                onActiveSidebarItemChange={(activeItem) =>
                  props.dispatch(changeActiveSidebarItem(activeItem))
                }
                activeItem={props.activeItem}
                header="Relatorios"
                isHeader
                link="/admin/bills/report"
                index="Bills/Report"
              />
            </>
          )}
          
          <LinksGroup
            onActiveSidebarItemChange={(activeItem) =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header="Instancia"
            isHeader
            iconName={<i className={"eva eva-grid-outline"} />}
            link="/admin/instance"
            index="Instancia"
          />

          <Li className="d-flex align-items-center justify-content-start">
            <Link
              className="d-flex align-items-center text-decoration-none"
              href="javascript:void(0);"
              onClick={toggleCampanhas}
            >
              <Icon className={"eva eva-book-outline"} /> Campanhas
              {isArrowRotated ? (
                <ArrowIconImageOne src={ExpandIcon} alt="Expand" />
              ) : (
                <ArrowIconImageOne src={CollapseIcon} alt="Collapse" />
              )}
            </Link>
          </Li>

          {campanhasOpen && (
            <>
              <LinksGroup
                onActiveSidebarItemChange={(activeItem) =>
                  props.dispatch(changeActiveSidebarItem(activeItem))
                }
                activeItem={props.activeItem}
                header="Listagem"
                isHeader
                link="/admin/campaign-list"
                index="Lista de Campanhas"
              />
              <LinksGroup
                onActiveSidebarItemChange={(activeItem) =>
                  props.dispatch(changeActiveSidebarItem(activeItem))
                }
                activeItem={props.activeItem}
                header="Cadastrar"
                isHeader
                link="/admin/messages"
                index="Message"
              />

              <LinksGroup
                onActiveSidebarItemChange={(activeItem) =>
                  props.dispatch(changeActiveSidebarItem(activeItem))
                }
                activeItem={props.activeItem}
                header="BlackList"
                isHeader
                link="/admin/blackList-numbers"
                index="BlackList"
              />

              <LinksGroup
                onActiveSidebarItemChange={(activeItem) =>
                  props.dispatch(changeActiveSidebarItem(activeItem))
                }
                activeItem={props.activeItem}
                header="Modelos Campanha"
                isHeader
                link="/admin/campaign-template"
                index="CampaignTemplate"
              />
            </>
          )}

          <LinksGroup
            onActiveSidebarItemChange={(activeItem) =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header="Chatbot"
            isHeader
            iconName={<i className={"eva eva-message-square-outline"} />}
            link="/admin/chatbot"
            index="Chatbot"
            isBeta={1}
          />

          {user.level === "ADMIN" ? (
            <>
              <LinksGroup
                onActiveSidebarItemChange={(activeItem) =>
                  props.dispatch(changeActiveSidebarItem(activeItem))
                }
                activeItem={props.activeItem}
                header="Usuários sistema"
                isHeader
                iconName={<i className={"fa fa-user"} />}
                link="/admin/user"
                index="User"
              />
            </>
          ) : (
            <></>
          )}

          <Li className="d-flex align-items-center justify-content-start w-100">
            <Link
              className="d-flex align-items-center text-decoration-none w-100"
              style={{}}
              href="javascript:void(0);"
              onClick={toggleSchedule}
            >
              <Icon className={"eva eva-calendar-outline"} /> Contatos
              {isArrowScheduleRotated ? (
                <ArrowIconImage src={ExpandIcon} alt="Expand" />
              ) : (
                <ArrowIconImage src={CollapseIcon} alt="Collapse" />
              )}
            </Link>
          </Li>

          {scheduleOpen && (
            <>
              <LinksGroup
                onActiveSidebarItemChange={(activeItem) =>
                  props.dispatch(changeActiveSidebarItem(activeItem))
                }
                activeItem={props.activeItem}
                header="Cadastro Cliente"
                isHeader
                link="/admin/client-schedule"
                index="Schedule"
              />

              <LinksGroup
                onActiveSidebarItemChange={(activeItem) =>
                  props.dispatch(changeActiveSidebarItem(activeItem))
                }
                activeItem={props.activeItem}
                header="Cadastro Simplificado"
                isHeader
                link="/admin/simplified-register"
                index="SimplifiedRegister"
              />
            </>
          )}

          <LinksGroup
            onActiveSidebarItemChange={(activeItem) =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header="Calendário"
            isHeader
            iconName={<i className={"eva eva-checkmark-square-outline"} />}
            link="/admin/calendar"
            index="Calendar"
          />

          <LinksGroup
            onActiveSidebarItemChange={(activeItem) =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header="Perfil"
            isHeader
            iconName={<i className={"eva eva-smiling-face-outline"} />}
            link="/admin/profile"
            index="Profile"
          />

          <LinksGroup
            onActiveSidebarItemChange={(activeItem) =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header="Departamentos"
            isHeader
            iconName={<i className={"eva eva-globe-outline"} />}
            link="/admin/department"
            index="Department"
          />
          <LinksGroup
            onActiveSidebarItemChange={(activeItem) =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header="Pagamento"
            isHeader
            iconName={<i className={"eva eva-shopping-cart-outline"} />}
            link="/admin/finance"
            index="Payment"
          />

          <LinksGroup
            onActiveSidebarItemChange={(activeItem) =>
              props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={props.activeItem}
            header="Alterar Senha"
            isHeader
            iconName={<i className={"eva eva-book-outline"} />}
            link="/admin/update-password"
            index="UpdatePassword"
          />

          <ServiceButton
            href="https://api.whatsapp.com/send/?phone=554499143623&text&type=phone_number&app_absent=0"
            target="_blank"
          >
            <button className="border-0 d-flex align-items-center service-button">
              <i className={"fa fa-solid fa-phone mr-2"} />
              <p className="m-0">Suporte</p>
            </button>
          </ServiceButton>


          {
            /* <h5 className={s.navTitle}>TEMPLATE</h5>
                <LinksGroup
                  onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
                  activeItem={props.activeItem}
                  header="Typography"
                  isHeader
                  iconName={<i className={'eva eva-text-outline'}/>}
                  link="/admin/typography"
                  index="typography"
          />
          <LinksGroup
            onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={props.activeItem}
            header="Tables"
            isHeader
            iconName={<i className={'eva eva-grid-outline'}/>}
            link="/admin/tables"
            index="tables"
          />
          <LinksGroup
            onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={props.activeItem}
            header="Notifications"
            isHeader
            iconName={<i className={'eva eva-bell-outline'}/>}
            link="/admin/notifications"
            index="notifications"
          /> */
            // <LinksGroup
            //   onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
            //   activeItem={props.activeItem}
            //   header="UI Elements"
            //   isHeader
            //   iconName={<i className={'eva eva-cube-outline'}/>}
            //   link="/admin/uielements"
            //   index="uielements"
            //   childrenLinks={[
            //     {
            //       header: 'Charts', link: '/admin/ui-elements/charts',
            //     },
            //     {
            //       header: 'Icons', link: '/admin/ui-elements/icons',
            //     },
            //     {
            //       header: 'Google Maps', link: '/admin/ui-elements/maps',
            //     },
            //   ]}
            // />
          }
        </ul>
      </nav>
      <ModalAddressComponent />
    </>
  );
};

Sidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
