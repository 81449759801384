import styled, { css } from "styled-components";
import Menu from "./assets/menu.png"
export const ButtonToggle = styled.div`
  position: absolute;
  top: 20px;
  width: 2rem;
  height: 2rem;
  border: none;
  cursor: pointer;
  z-index: 1000;
  margin: ${props => (props.showSidebar ? '0' : '0 20px')};
    ${props =>
      props.showSidebar &&
      `
        display: none;
      `}

      @media (max-width: 767px) {
        display: none;
     }

     &.classColapsed{
        left: 200px;
        top: 25px;
     }

     &.classMenu{
        left: 6px;
     }
`;

export const Image = styled.img`
     width: 80%;
     height: 80%;
`;
