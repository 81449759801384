import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min.js";

import {
    Col,
    Row,
    Table,
    Card,
    CardBody,
    FormGroup,
    Button,
    InputGroup,
    InputGroupAddon,
    Alert,
    UncontrolledDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";

import {
    getAllMessages,
    getCampaignInfoService,
    postResendCampaign,
    postCancelCampaign
} from "../../services/Instance/index.js";

import Swal from "sweetalert2";

import ModalAddMoreContacts from "./ModalAddMoreContacts.js";

const CampaignInfo = () => {
    const history = useHistory();
    const { id: campaignId } = useParams();
    const [campaign, setCampaign] = useState({});
    const [selectedCampaignId, setSelectedCampaignId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [numberList, setNumberList] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [selectionMode, setSelectionMode] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [numbersPhone, setNumbersPhone] = useState("");
    const [showBtnMoreRegisters, setShowBtnMoreRegisters] = useState(false);
    const [list, setList] = useState([]);
    const [enableButtons, setEnableButtons] = useState(false);

    const [message, setMessage] = useState({
        numbers: [],
        instance: null,
        groups: null
    });

    const [filter, setFilter] = useState({
        phone: null,
        previusLimit: 0,
        limit: 15,
        campaignId: campaignId
    });

    const handleResendCampaigns = async (campaignId) => {
        Swal.fire({
            icon: "question",
            title: "Confirmação",
            text: "Você tem certeza que deseja reenviar a campanha?",
            showCancelButton: true,
            cancelButtonText: "Não, sair",
            confirmButtonText: "Sim, continuar"
        }).then((confirm) => {
            if (!confirm.isConfirmed) return;

            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde.",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            postResendCampaign(campaignId).then((ev) => {
                getInfo();
                Swal.fire({
                    icon: "success",
                    title: "Campanha enviada com sucesso.",
                    confirmButtonText: "Fechar",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                });
            });
        });
    };

    const handleCancelCampaigns = async (campaignId) => {
        Swal.fire({
            icon: "question",
            title: "Confirmação",
            text: "Você tem certeza que deseja cancelar a campanha?",
            showCancelButton: true,
            cancelButtonText: "Não, sair",
            confirmButtonText: "Sim, continuar"
        }).then((confirm) => {
            if (!confirm.isConfirmed) return;

            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde.",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            postCancelCampaign(campaignId)
                .then((response) => {
                    getInfo();
                    Swal.fire({
                        icon: "success",
                        title: "Campanha cancelada com sucesso.",
                        confirmButtonText: "Fechar",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    });
                })
                .catch((err) => {
                    const code = !err.response ? "00000" : err.response.data.code;
                    const message = !err.response
                        ? "Ocorreu um erro inesperado"
                        : err.response.data.message;
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: `[${code}] ${message}`,
                    });
                });
        });
    };

    const handleBack = () => {
        const previousPage = localStorage.getItem('previousPage');
        const trimmedPage = previousPage?.replace(/.*\/admin\/(dashboard|campaign-list)/, '/admin/$1');
        if (trimmedPage) {
            history.push(trimmedPage);
        } else {
            history.push('/admin/dashboard');
        }
    };

    const getInfo = async () => {
        await getCampaignInfoService(campaignId).then(({ data }) => {
            setCampaign(data?.resultSet ?? {});
            getMessages(false);
            Swal.close();
        });
    };

    const getMessages = async (isMore = false) => {
        Swal.fire({
            title: "Carregando, Por favor aguarde...",
            text: "Por favor aguarde",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        let filterSearch = {
            ...filter
        };
        if (!isMore) {
            setFilter({
                ...filter,
                previusLimit: 0,
            });

            filterSearch = {
                ...filter,
                previusLimit: 0,
            };
        }

        await getAllMessages(filterSearch).then(({ data }) => {
            setEnableButtons(filter.status === "0" ?? false);
            if (!data?.status) return;
            Swal.close();

            if (isMore == true) {
                setList([...list, ...data.resultSet.result]);
                setShowBtnMoreRegisters(data?.resultSet?.result?.length > 0);
                setFilter({
                    ...filter,
                    previusLimit: data.resultSet.limit,
                });
            } else {
                setList(data?.resultSet?.result ?? []);
                setFilter({
                    ...filter,
                    previusLimit: 0,
                });
                setShowBtnMoreRegisters(data?.resultSet?.result?.length >= 15);
            }
        });
    };

    const openModal = (campaignId) => {
        setSelectedCampaignId(campaignId);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setMessage({
            numbers: []
        })
        setNumbersPhone('');
        setSelectedGroup('');
        setSelectionMode('');
        setIsModalOpen(false);
        setNumberList([]);
        setShowAlert(false);
    };

    useEffect(() => {
        getInfo();
    }, [])

    if (!campaign?.id) {
        return <>
            <Alert color="info" className="text-info">Carregando...</Alert>
        </>
    }

    return (
        <div className="h-auto">
            <div>
                <Button
                    onClick={handleBack}
                    color="primary"
                >
                    Voltar
                </Button>
            </div>
            <Row>
                <Col className="h-100 d-flex flex-lg-row flex-column">

                    <Card className="col-lg-6 col-12 border-0 mb-lg-0 mb-3 mt-3 shadow mr-4 h-auto p-0">
                        <CardBody className="h-auto">
                            <div className="overflow-hidden d-flex flex-lg-row flex-column">
                                <Col className="col-xl-6 col-12">
                                    <h4 className="mb-4">Campanha</h4>
                                    <div className="col-12 p-0">
                                        <div className="mb-2">Titulo</div>
                                        <div>
                                            <p className="font-weight-bold">
                                                {campaign.title}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex flex-xl-row flex-column mt-4 p-0">
                                        <div className="col-12 p-0">
                                            <div className="mb-2">Data de cadastro</div>
                                            <div>
                                                <p className="font-weight-bold">
                                                    {campaign.createdAt}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-xl-0 mt-4 col-12 p-0 mx-xl-3 mx-0">
                                            <div className="mb-2">Instancia</div>
                                            <div>
                                                <p className="font-weight-bold">
                                                    {campaign.instance}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4 mb-2 p-0">
                                        <div className="mb-2">Mensagem</div>
                                        <div className="">
                                            <p className="font-weight-bold"
                                                style={{ whiteSpace: 'pre-line' }}
                                            >
                                                {campaign.message}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </CardBody>
                    </Card>

                    <Card className="border-0 col-lg-5 col-12 border-0 mt-3 shadow h-auto p-0" style={{ zIndex: "0" }}>
                        <CardBody className="h-auto">
                            <div className="d-flex flex-lg-row flex-column"  >
                                <Col class="col-lg-6">
                                    <div className="d-flex flex-lg-row flex-column align-items-lg-center justify-content-between mb-4">
                                        <h4>Número</h4>
                                        <UncontrolledDropdown className="mt-lg-0 mt-3" >
                                            <DropdownToggle caret color="secondary">
                                                Opções
                                            </DropdownToggle>
                                            <DropdownMenu style={{ zIndex: "1000" }}>
                                                <DropdownItem
                                                    style={{ color: "black" }}
                                                    onClick={() => {
                                                        openModal(campaign.id);
                                                    }}
                                                >
                                                    Adicionar contatos
                                                </DropdownItem>
                                                <DropdownItem
                                                    style={{ color: "black" }}
                                                    onClick={() => {
                                                        handleCancelCampaigns(campaign.id);
                                                    }}
                                                >
                                                    Cancelar campanha
                                                </DropdownItem>
                                                <DropdownItem
                                                    style={{ color: "black" }}
                                                    onClick={() => {
                                                        handleResendCampaigns(campaign.id);
                                                    }}
                                                >
                                                    Reenviar campanha
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                    <div className="col-12 d-flex flex-column mt-4 p-0" >
                                        <FormGroup className="w-100 d-flex">
                                            <InputGroup>
                                                <input
                                                    id="phone"
                                                    type="text"
                                                    placeholder="Informar número desejado"
                                                    className="form-control form-control-md"
                                                    onChange={(ev) => {
                                                        setFilter({
                                                            ...filter,
                                                            numberSend: ev.target.value
                                                        })
                                                    }}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <Button
                                                        className="text-light"
                                                        style={{ backgroundColor: '#009582' }}
                                                        onClick={getMessages}
                                                    >
                                                        Buscar
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>

                                        </FormGroup>
                                        <div
                                            className="widget-table-overflow text-center"
                                            style={{ maxHeight: "450px", overflowY: "auto" }}
                                        >
                                            {list.length > 0 ? (
                                                <>
                                                    <Table
                                                        className={`table-striped table-borderless table-hover`}
                                                        responsive
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th className="w-50">NUMERO</th>
                                                                <th className="w-50">STATUS</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {list.map((message, key) => (
                                                                <tr key={key}>
                                                                    <td>
                                                                        <strong className="number">
                                                                            {message.phone}
                                                                        </strong>
                                                                    </td>
                                                                    <td>
                                                                        {Number(message.send) === 1 ? (
                                                                            <span className="badge badge-success">
                                                                                ENVIADO
                                                                            </span>
                                                                        ) : Number(message.send) === 0 ? (
                                                                            <span className="badge badge-warning">
                                                                                AGUARDANDO
                                                                            </span>
                                                                        ) : Number(message.send) === -2 ? (
                                                                            <span className="badge badge-secondary text-dark">
                                                                                CANCELADO
                                                                            </span>
                                                                        ) : Number(message.send) === 2 ? (
                                                                            <span className="badge badge-dark text-light">
                                                                                BLACKLIST
                                                                            </span>
                                                                        ) : (
                                                                            <span className="badge badge-danger">
                                                                                ERRO
                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                        {showBtnMoreRegisters && (
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={7}>
                                                                        <Button
                                                                            className="w-100"
                                                                            onClick={() => {
                                                                                getMessages(true);
                                                                            }}
                                                                        >
                                                                            Carregar mais...
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </Table>
                                                </>
                                            ) : (
                                                <div className="alert alert-secondary">
                                                    <h4 className="text-dark text-center">
                                                        Não possuem registros no momento.
                                                    </h4>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </CardBody>


                    </Card>
                </Col>

                <ModalAddMoreContacts
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                    campaignId={selectedCampaignId}
                    getInfo={getInfo}
                />
            </Row>
        </div>
    );
};

export default CampaignInfo;