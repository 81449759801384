import styled from "styled-components";

export const ModalBodyStyled = styled.section`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px;
    overflow-y: auto;
    max-height: 70vh;
`;

export const CardFooterStyled = styled.section`
    padding: 0 15px 15px;
    display: flex;
    gap: 10px;
`;