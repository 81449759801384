import { useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import { cepMask } from "../../utils/mask";
import { brasilApi } from "../../services/Utils/BrasilApiService";
import { createAddress } from "../../services/Register/User";
import Swal from "sweetalert2";

const ModalAddressComponent = () => {
  const user = JSON.parse(localStorage.getItem("data_user_izichat_app"));
  const [toggle, setToggle] = useState(!user?.hasAddress);
  const [register, setRegister] = useState({
    zipCode: null,
    address: null,
    numberAddress: null,
    district: null,
    city: null,
    complement: null,
  });

  const handleGetZipCode = (cep) => {
    setRegister({
      ...register,
      zipCode: cepMask(cep),
    });

    if (cep.replace(/[^0-9]/g, "").length >= 8) {
      Swal.fire({
        title: "Carregando...",
        text: "Por favor aguarde",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      brasilApi(cep).then(({ data }) => {
        Swal.close();
        setRegister({
          zipCode: cepMask(data.cep),
          address: data.street,
          district: data.neighborhood,
          city: data.city,
          state: data.state,
        });
      })
      .catch((err) => {
        setRegister({
          zipCode: '',
          address: '',
          district: '',
          city: '',
          state: '',
        });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Não foi possível identificar o CEP informado`
        });
      });
    }
  };

  const handleSave = (ev) => {
    ev.preventDefault();

    Swal.fire({
      title: "Salvando...",
      text: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    createAddress(register)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro atualizado com sucesso, obrigado!",
        });

        localStorage.setItem(
          "data_user_izichat_app",
          JSON.stringify({
            ...user,
            hasAddress: true,
          })
        );
        setToggle(false);
      })
      .catch((err) => {
        const code = err.response.data.code;
        const message = err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
          // footer: '<a href="">Why do I have this issue?</a>'
        });
      });
  };

  if (user.level !== "ADMIN") return <></>;

  return (
    <Modal isOpen={toggle} size="lg" className="shadow">
      <Form method="submit" onSubmit={handleSave}>
        <ModalBody>
          <Card className="shadow border-0">
            <CardHeader className="bg-white border-0">
              <span className="float-right text-danger">
                Campos com * são obrigatórios
              </span>
              <h4>Completar cadastro</h4>
            </CardHeader>
            <CardBody>
              <Alert color="dark">
                <p className="text-dark">
                  - Falta pouco para completar seu cadastro, preencha seu
                  endereço abaixo:
                </p>
              </Alert>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label>
                      CEP: <span className="text-danger">*</span>
                    </Label>
                    <Input
                      placeholder="00000-000"
                      value={register.zipCode}
                      onChange={(ev) => {
                        handleGetZipCode(ev.target.value);
                      }}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="7">
                  <FormGroup>
                    <Label>
                      Endereço: <span className="text-danger">*</span>
                    </Label>
                    <Input
                      value={register.address}
                      onChange={(ev) => {
                        setRegister({
                          ...register,
                          address: ev.target.value,
                        });
                      }}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label>
                      Número: <span className="text-danger">*</span>
                    </Label>
                    <Input
                      value={register.numberAddress}
                      onChange={(ev) => {
                        setRegister({
                          ...register,
                          numberAddress: ev.target.value,
                        });
                      }}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>
                      Bairro: <span className="text-danger">*</span>
                    </Label>
                    <Input
                      value={register.district}
                      onChange={(ev) => {
                        setRegister({
                          ...register,
                          district: ev.target.value,
                        });
                      }}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>
                      Cidade: <span className="text-danger">*</span>
                    </Label>
                    <Input value={register.city} readOnly required />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>
                      Estado: <span className="text-danger">*</span>
                    </Label>
                    <Input value={register.state} readOnly required />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Complemento</Label>
                    <Input
                      value={register.complement}
                      onChange={(ev) => {
                        setRegister({
                          ...register,
                          complement: ev.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Button color="success">Salvar</Button>
            </CardFooter>
          </Card>
        </ModalBody>
      </Form>
    </Modal>
  );
};

export default ModalAddressComponent;
