import styled from 'styled-components'

export const ModalBodyStyled = styled.section`
    height: 70vh;
    display: flex;
    padding: 20px;
    justify-content: space-around;
    overflow-y: scroll;
    flex-wrap: wrap;

        @media screen and (max-width: 991px) {
            align-items: center;
            justify-content: center;
            
        }
`;

export const ClassForm = styled.section`
    padding: 20px 0;
    width: 50%;

    @media screen and (max-width: 991px) {
        width: 80%;

    }

    @media screen and (max-width: 640px) {
        width: 95%;

    }
`;

export const PreviewContainer = styled.section`
    width: 40%;
    height: 30rem;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;    
    margin-bottom: 20px;
   
    @media screen and (max-width: 991px) {
        width: 75%;

    }

    @media screen and (max-width: 640px) {
        width: 95%;

    }
    `;

export const PhoneHeader = styled.section`
    width: 100%;
    height: 3.4rem;
    background-color: white;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
`;

export const Left = styled.div`
    border-radius: 100%;
    width: 10px;
    height: 10px;
    background-color: gray;
`;

export const Center = styled.div`
    border-radius: 2rem;
    width: 55px;
    height: 10px;
    background-color: gray;
    position: relative;
    left: 20px;
`;

export const Icons = styled.div`
    width: 3rem;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
`;

export const ImgBateria = styled.img`
    height: 20px;
    width: 25px;
`;

export const ImgContect = styled.img`
    height: 15px;
    width: 15px;
`;


export const PreviewHeader = styled.section`
    width: 100%;
    height: 3.6rem;
    display: flex;
    align-items: center;
    padding: 0.5rem 20px;
    background-color: #4D53E0;
    gap: 20px;
    color: white;
`;

export const PreviewFooter = styled.section`
    border-top: 1px solid lightGray;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 3.5rem;
    background-color: white;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    font-size: 0.9rem;
`;

export const PreviewWrap = styled.section`
    width: 100%;
    height: 27rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
`;

export const ChatbotAvatar = styled.div`
    background-color: white;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ImgAvatar = styled.img`
    width: 30px;
    height: 30px;

`;

export const PreviewBody = styled.div`
    width: 75%;
    max-width: 300px;
    height: auto;
    max-height: 200px;
    background-color: #4D53E0;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 20px;
    word-wrap: break-word;
    overflow-y: auto;
    gap: 15px;
`;

export const Paragraph = styled.p`
    padding: 10px 15px 20px;
    color: white;
    font-size: 0.9rem;
`;