import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

import {
  ModalBodyStyled,
  GroupStyled,
  Client,
  Observation,
  TellList,
  ObservationsTextList,
  Li,
  Ul,
} from "../style/ClientScheduleStyled.js";
import RegisterGroup from "./RegisterGroup";
import { FaPlus, FaMinus } from "react-icons/fa";
import Swal from "sweetalert2";
import {
  registerClient,
  getAllGroups,
} from "../../../services/Client-Schedule/index.js";
import { phoneMask } from "../../../utils/mask.js";

const RegisterClient = ({ isOpen, toggleModal, onUpdateClients }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [showModalGroup, setShowModalGroup] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [numeroTelefone, setNumeroTelefone] = useState("");
  const [tipoTelefone, setTipoTelefone] = useState("");
  const [name, setName] = useState("");
  const [documentValue, setDocumentValue] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [observations, setObservations] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [observationsList, setObservationsList] = useState([]);
  const [observationText, setObservationText] = useState("");
  const [groups, setGroups] = useState([]);

  const resetStates = () => {
    setName("");
    setDocumentValue("");
    setBirthDate("");
    setObservations("");
    setNumbers([]);
    setNumeroTelefone("");
    setTipoTelefone("");
    setSelectedOption("");
    setSelectedGroup("");
    setObservationsList([]);
    setGroups([]);
  };

  const resetModal = () => {
    toggleModal();
    resetStates();
  };

  const addObservation = () => {
    if (observationText) {
      setObservationsList([...observationsList, { text: observationText }]);
      setObservationText("");
    }
  };

  const getGroup = async () => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await getAllGroups().then((res) => {
        Swal.close();
        return res;
      });
      Array.isArray(response.data.resultSet);
      setGroupList([...response.data.resultSet]);
    } catch (error) {
      Swal.close();
      console.error("Erro ao buscar a lista de grupos", error);
    }
  };

  const handleSave = async () => {
    try {
      if (!name || numbers.length <= 0) {
        Swal.fire({
          icon: "error",
          title: "Campos em branco",
          text: "Nome e Telefone são obrigatórios",
        });
        return;
      }

      Swal.fire({
        title: "Carregando, Por favor aguarde...",
        text: "Por favor aguarde.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const data = {
        name,
        document: selectedOption
          ? {
            type: selectedOption,
            value: documentValue,
          }
          : null,
        birthDate,
        groups: [
          ...(selectedGroup
            ? [{ id: parseInt(selectedGroup.id), name: selectedGroup.name }]
            : []),
          ...groups,
        ],
        observations: observationsList,
        numbers,
      };

      await registerClient(data);
      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Cliente cadastrado com sucesso!",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      resetStates();
      onUpdateClients();
      resetModal();
    } catch (error) {
      console.log(error);
      const code = !error.response ? "00000" : error.response.data.code;
      const message = !error.response
        ? "Ocorreu um erro inesperado"
        : error.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
      });
    }
  };

  const toggleModalGroup = () => {
    setShowModalGroup(!showModalGroup);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const addTelefone = () => {
    if (numeroTelefone && tipoTelefone) {
      setNumbers([
        ...numbers,
        { numbers: numeroTelefone, type: tipoTelefone },
      ]);
      setNumeroTelefone("");
      setTipoTelefone("");
    }
  };

  const addGroup = () => {
    if (selectedGroup.id && selectedGroup.name) {
      if (groups.some((group) => group.id === selectedGroup.id)) {
        Swal.fire({
          icon: "warning",
          title: "Grupo Duplicado",
          text: "Você já selecionou este grupo. Por favor, escolha outro.",
          confirmButtonText: "OK",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      } else {
        setGroups([...groups, selectedGroup]);
        setSelectedGroup({ id: "", name: "" });
      }
    }
  };

  const removeGroup = (index) => {
    const updatedGroups = [...groups];
    updatedGroups.splice(index, 1);
    setGroups(updatedGroups);
  };

  useEffect(() => {
    getGroup();
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={resetModal} className="modal-xl">
      <ModalHeader toggle={resetModal}>Cadastro de Novo Cliente</ModalHeader>
      <ModalBodyStyled>
        <Client>
          <GroupStyled>
            <FormGroup>
              <label>Nome do Cliente</label>
              <Input
                type="text"
                id="name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <label>Documento</label>
              <Input
                type="select"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                <option value="RG">RG</option>
                <option value="CPF">CPF</option>
                <option value="CNPJ">CNPJ</option>
              </Input>
              {selectedOption && (
                <Input
                  type="text"
                  className="mt-2"
                  placeholder={`Digite o ${selectedOption}`}
                  value={documentValue}
                  onChange={(e) => setDocumentValue(e.target.value)}
                />
              )}
            </FormGroup>
            <FormGroup>
              <label>Data de Nascimento</label>
              <Input
                type="date"
                placeholder="DD/MM/AAAA"
                className="form-control"
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <label>Grupos</label>
              <InputGroup>
                <select
                  className="form-control"
                  name="clientGroup"
                  required
                  value={selectedGroup.id}
                  onChange={(e) => {
                    const selectedGroupId = e.target.value;
                    const selectedGroup = groupList.find(
                      (group) => group.id === selectedGroupId
                    );
                    setSelectedGroup({
                      id: parseInt(selectedGroupId),
                      name: selectedGroup.name,
                    });
                  }}
                >
                  <option value="">Selecione...</option>
                  {groupList.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </select>
                <InputGroupAddon addonType="append">
                  <Button color="info" onClick={() => addGroup(selectedGroup)}>
                    <FaPlus />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            {groups.length > 0 && (
              <Ul>
                <label>Grupos Adicionados:</label>
                {groups.map((group, index) => (
                  <div key={index} className="mb-2">
                    <InputGroup>
                      <Input type="text" value={group.name} disabled />
                      <InputGroupAddon addonType="append">
                        <Button
                          color="danger"
                          onClick={() => removeGroup(index)}
                        >
                          <FaMinus />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                ))}
              </Ul>
            )}

            <Button onClick={toggleModalGroup}>Adicionar Grupo</Button>
          </GroupStyled>
          <GroupStyled>
            <FormGroup>
              <label>Adicionar Telefone</label>
              <InputGroup>
                <Input
                  id="numeroTelefone"
                  type="tel"
                  placeholder="Telefone"
                  value={numeroTelefone}
                  onChange={(e) => setNumeroTelefone(phoneMask(e.target.value))}
                />
                <select
                  id="tipoTelefone"
                  className="form-control"
                  name="tipoTelefone"
                  value={tipoTelefone}
                  onChange={(e) => setTipoTelefone(e.target.value)}
                >
                  <option value="">Selecione...</option>
                  <option value="whatsapp">WhatsApp</option>
                  <option value="sms">SMS</option>
                  <option value="fixed">Fixo</option>
                </select>
                <InputGroupAddon addonType="append">
                  <Button color="info" onClick={addTelefone}>
                    <FaPlus />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            {numbers.length > 0 && (
              <TellList>
                <label>Telefones</label>
                {numbers.map((number, index) => (
                  <div key={index} className="mb-2">
                    <InputGroup>
                      <Input type="text" value={number.numbers} disabled />
                      <Input type="text" value={number.type} disabled />
                    </InputGroup>
                  </div>
                ))}
              </TellList>
            )}
          </GroupStyled>
        </Client>
        <Observation className="p-2">
          <FormGroup>
            <label>Observações</label>
            <Input
              type="textarea"
              placeholder="Digite uma mensagem"
              value={observationText}
              onChange={(e) => setObservationText(e.target.value)}
            />
          </FormGroup>
          <Button color="info" onClick={addObservation}>
            Adicionar Observação
          </Button>
          {observationsList.length > 0 && (
            <ObservationsTextList>
              <label>Lista de Observações:</label>
              {observationsList.map((obs, index) => (
                <div key={index}>
                  <label>Observação {index + 1}:</label>
                  <Ul>
                    <Li>{obs.text}</Li>
                  </Ul>
                </div>
              ))}
            </ObservationsTextList>
          )}
        </Observation>
      </ModalBodyStyled>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            toggleModal();
            resetStates();
          }}
        >
          Fechar
        </Button>
        <Button
          color="primary"
          onClick={() => {
            handleSave();
          }}
        >
          Salvar
        </Button>
      </ModalFooter>
      <RegisterGroup
        isOpen={showModalGroup}
        toggleModal={toggleModalGroup}
        execute={() => {
          getGroup();
        }}
      />
    </Modal>
  );
};

export default RegisterClient;
