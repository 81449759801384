import React, { useEffect, useRef, useState } from "react";

import "@webscopeio/react-textarea-autocomplete/style.css";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import emoji from "@jukben/emoji-search";
import "@webscopeio/react-textarea-autocomplete/style.css";

import IconBateria from "./assets/bateria.png"
import IconConect from "./assets/conexao.png"
import User from "./assets/user.png"
import Send from "./assets/send.png"

import {
    deleteTemplate,
    getTemplate,
    sendTemplate
} from "../../services/Campaign-Template/index.js";

import {
    Alert,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    InputGroup,
    Label,
    Row,
    Table,
    Tooltip
} from "reactstrap";

import {
    PreviewContainer,
    PhoneHeader,
    PreviewHeader,
    PreviewFooter,
    PreviewWrap,
    Left,
    Center,
    Icons,
    ImgBateria,
    ImgContect,
    UserAvatar,
    ImgAvatar,
    PreviewBody,
    Paragraph,
    CardPhone
} from "./components/CardPhoneStyle.js";

import Swal from "sweetalert2";

const CampaignTemplate = () => {
    const ref = useRef(600);
    const [list, setList] = useState([]);
    const [showBtnMoreTemplates, setShowBtnMoreTemplates] = useState(false);
    const [dropdownStates, setDropdownStates] = useState(list.map(() => false));
    const [visibleTemplates, setVisibleTemplates] = useState(15);
    const [tooltipOpen, setTooltipOpen] = useState([]);

    const [template, setTemplate] = useState({
        title: "",
        message: null,
    });

    const toggleTooltip = (index) => {
        setTooltipOpen((prevTooltipOpen) => {
          const newTooltipOpen = [...prevTooltipOpen];
          newTooltipOpen[index] = !newTooltipOpen[index];
          return newTooltipOpen;
        });
      };

    const Item = ({ entity: { name, char } }) => <div>{`${name}: ${char}`}</div>;

    const toggleDropdown = (key) => {
        const newDropdownStates = [...dropdownStates];
        newDropdownStates[key] = !newDropdownStates[key];
        setDropdownStates(newDropdownStates);
    };

    const handleSubmit = async () => {
        if (
            !template.title || !template.message
        ) {
            Swal.fire({
                icon: "warning",
                title: "Aviso",
                text: "Por favor, preencha todos os campos, são obrigatórios!",
            });
            return;
        }

        Swal.fire({
            title: "Carregando, Por favor aguarde...",
            text: "Por favor aguarde",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        await sendTemplate(template)
            .then(() => {
                Swal.close();

                Swal.fire({
                    icon: "success",
                    title: "Sucesso",
                    text: "Modelo cadastrados com sucesso",
                });

                setTemplate({
                    title: [],
                    message: "",
                });

                handleListTemplates();
                setVisibleTemplates(15);
            })
            .catch(() => {
                Swal.fire({
                    icon: "error",
                    title: "Erro",
                    text: "Não foi possível finalizar o registro",
                });
            });
    };

    const handleChange = async (ev) => {
        if (ev.target.name === "title") {
            setTemplate({
                ...template,
                title: ev.target.value,
            });
        }

        if (ev.target.name === "message") {
            setTemplate({
                ...template,
                message: ev.target.value,
            });
            updateMessagePreview(ev);
        }
    };

    const updateMessagePreview = (event) => {
        const preview = document.getElementById("message-preview");
        if (preview) {
            const messageText = event.target.value.replace(/\n/g, '<br>');
            preview.querySelector("p").innerHTML = messageText || "";
        }
    };

    const handleListTemplates = async () => {
        Swal.fire({
            title: "Carregando, Por favor aguarde...",
            text: "Por favor aguarde",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        await getTemplate()
            .then((response) => {
                Swal.close();
                const templates = response.data.resultSet;
                setShowBtnMoreTemplates(templates.length > visibleTemplates);
                setList(templates.slice(0, visibleTemplates));
            })
            .catch((error) => {
                console.error("Error fetching templates:", error);
            });
    };

    const loadMoreTemplates = async () => {
        try {
            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            await new Promise((resolve) => {
                setVisibleTemplates((prevVisibleTemplates) => {
                    const newVisibleTemplates = prevVisibleTemplates + 5;
                    resolve(newVisibleTemplates);
                    return newVisibleTemplates;
                });
            });

        } catch (error) {
            console.error("Erro ao carregar mais templates:", error);
        } finally {
            Swal.close();
        }
    };

    const setStatus = async (templateId) => {
        Swal.fire({
            icon: "question",
            title: "Confirmação",
            text: "Você tem certeza que deseja desativar o chat?",
            showCancelButton: true,
            cancelButtonText: "Não, sair",
            confirmButtonText: "Sim, continuar"
        })
            .then((confirm) => {
                if (!confirm.isConfirmed) return;

                Swal.fire({
                    title: "Carregando, Por favor aguarde...",
                    text: "Por favor aguarde.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                deleteTemplate(templateId, 0)
                    .then(() => {
                        Swal.fire({
                            icon: "success",
                            title: "Operação realizada com sucesso.",
                            confirmButtonText: "Fechar",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                        });

                        handleListTemplates(templateId);
                    })
                    .catch((err) => {
                        const code = !err.response ? "00000" : err.response.data.code;
                        const message = !err.response
                            ? "Ocorreu um erro inesperado"
                            : err.response.data.message;
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: `[${code}] ${message}`,
                            showConfirmButton: true,
                        });
                    });
            });
    };

    useEffect(() => {
        handleListTemplates();
    }, [visibleTemplates]);

    return (
        <Row className="align-items-start">
            <Col className="flex-column col-lg-7 col-12">
                <Col className="pr-grid-col d-flex flex-column flex-lg-row justify-content-start align-items-start w-100">
                    <Card className="border-0 w-100">
                        <CardHeader className="border-0 bg-white">
                            <div className="headline-2">CRIAR MODELO DE CAMPANHA</div>
                            <Alert className="m-0 mt-2 text-success">
                                Crie um modelo de mensagem para reutilizar no cadastro de campanhas.
                            </Alert>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Label>Titulo do modelo</Label>
                                <InputGroup>
                                    <input
                                        name="title"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={template.title}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label>Mensagem padrão</Label>
                                <InputGroup>
                                    <ReactTextareaAutocomplete
                                        className="form-control w-100"
                                        name="message"
                                        style={{ height: 120, zIndex: 999 }}
                                        containerStyle={{
                                            width: ref.current.offsetWidth,
                                            zIndex: 999,
                                        }}
                                        onChange={handleChange}
                                        value={template.message}
                                        onInput={updateMessagePreview}
                                        loadingComponent={() => <span>Loading</span>}
                                        trigger={{
                                            ":": {
                                                dataProvider: (token) => {
                                                    return emoji(token)
                                                        .slice(0, 20)
                                                        .map(({ name, char }) => ({ name, char }));
                                                },
                                                component: Item,
                                                output: (item, trigger) => item.char,
                                            },
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <Button
                                className="btn btn-success"
                                onClick={handleSubmit}
                            >
                                Salvar
                            </Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="pr-grid-col d-flex flex-column flex-lg-row justify-content-start align-items-start w-100 mt-5">
                    <Card className="border-0 w-100">
                        <CardHeader className="border-0 bg-white">
                            <Row>
                                <Col md="9" xl="9">
                                    <div>
                                        <div className="headline-2">Modelos cadastrados</div>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <div className="widget-table-overflow text-center">
                                {list.length > 0 ? (
                                    <>
                                        <Table
                                            className={`table-striped table-borderless table-hover`}
                                            responsive
                                        >
                                            <thead>
                                                <tr>
                                                    <th className="w-14">DATA CADASTRO</th>
                                                    <th className="w-14">TITULO</th>
                                                    <th className="w-14">MENSAGEM</th>
                                                    <th className="w-14">STATUS</th>
                                                    <th className="w-14">OPÇÕES</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list.map((template, key) => (
                                                    <tr key={key}>
                                                        <td>
                                                            <p>
                                                                <strong>{template.createdAt}</strong>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <strong className="text">
                                                                    {template.title.length > 10 ? `${template.title.substring(0, 10)}...` : template.title}
                                                                </strong>
                                                            </p>
                                                        </td>
                                                        <td className="">
                                                            <Tooltip
                                                                className="align-self-start text-start w-auto"
                                                                placement="top"
                                                                isOpen={tooltipOpen[key]}
                                                                target={`tooltip-${key}`}
                                                                toggle={() => toggleTooltip(key)}
                                                                trigger="hover"
                                                                delay={{ show: 100, hide: 0 }}
                                                                style={{
                                                                    backgroundColor: "#FFF",
                                                                    border: "1px solid #C7D0D9",
                                                                    color: "#000",
                                                                    fontWeight: "500",
                                                                    borderRadius: "5px",
                                                                    width: "15rem",
                                                                    padding: "10px",
                                                                    whiteSpace: "pre-wrap",
                                                                    textAlign: "start",
                                                                    position: "absolute",
                                                                    bottom: "10px"
                                                                }}
                                                            >
                                                                {template.message}
                                                            </Tooltip>
                                                            <div id={`tooltip-${key}`}>
                                                                {template.message.length > 20 ? `${template.message.slice(0, 20)}...` : template.message}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {Number(template.active) === 1 && (
                                                                <span className="badge badge-success">
                                                                    ATIVO
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <Dropdown isOpen={dropdownStates[key]} toggle={() => toggleDropdown(key)}>
                                                                <DropdownToggle caret style={{ color: 'black' }}>OPÇÕES</DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem
                                                                        onClick={() => {
                                                                            setStatus(template.id);
                                                                        }}
                                                                    >
                                                                        Cancelar
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            {showBtnMoreTemplates && (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={7}>
                                                            <Button
                                                                className="w-100"
                                                                onClick={loadMoreTemplates}
                                                            >
                                                                Carregar mais...
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </Table>
                                    </>
                                ) : (
                                    <div className="alert alert-secondary">
                                        <h4 className="text-dark text-center">
                                            Não possuem registros no momento.
                                        </h4>
                                    </div>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Col >

            <Col className="mt-lg-0 mt-3 pr-grid-col d-flex flex-column flex-lg-row justify-content-center align-items-center col-lg-5 col-12" xs={5}>
                <CardPhone>
                    <PreviewContainer>
                        <PhoneHeader>
                            <Left />
                            <Center />
                            <Icons>
                                <ImgBateria src={IconBateria} alt="Bateria" />
                                <ImgContect src={IconConect} alt="Conexão" />
                            </Icons>
                        </PhoneHeader>
                        <PreviewWrap>
                            <PreviewHeader>
                                <UserAvatar>
                                    <ImgAvatar src={User} alt="Chatbot"></ImgAvatar>
                                </UserAvatar>
                                <p>(99) 99999-9999</p>
                            </PreviewHeader>
                            <PreviewBody
                                id="message-preview"
                                className="message-preview"
                            >
                                <Paragraph></Paragraph>
                            </PreviewBody>
                            <PreviewFooter>
                                <p>Escreva sua mensagem</p>
                                <img src={Send} alt="Send" />
                            </PreviewFooter>
                        </PreviewWrap>
                    </PreviewContainer>
                </CardPhone>
            </Col>
        </Row>
    );
};

export default CampaignTemplate;