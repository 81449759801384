import styled from "styled-components"


export const ModalBodyStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    align-items: center;
    justify-content: center;
    height: 65vh;
    overflow-y: auto;
`;
export const ModalBodyGroupStyled = styled.div`
    padding: 20px;
    height: 65vh;
    overflow-y: auto;
`;

export const Client = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

export const Observation = styled.div`
    width: 100%
`;


export const GroupStyled = styled.form`
    width: 50%;
    padding: 10px;
    
    @media screen and (max-width: 991px) {
        width: 90%;
    }
`;

export const TellList = styled.div`
    background-color: #e9ecef90;
    max-height: 240px;
    overflow-y: auto;
    padding: 10px;
`;


export const ObservationsTextList = styled.div`
    margin-top: 10px;
    padding: 20px 10px;
    background-color: #e9ecef90;
`;

export const Ul = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-height: 240px;
    overflow-y: auto;
`;

export const Li = styled.ul`
    border: 1px solid gray;
    padding: 10px;
    border-radius: 0.5rem;
`;

export const ContainerEditClient = styled.div`
    width: 70%;
    @media screen and (max-width: 991px) {
        width: 100%;
    }
`;

export const ListTell = styled.div`
    margin-top: 15px;
    padding: 10px;
    background: #f5f5f5;
    max-height: 240px;
    overflow-y: auto;
`;

export const ListGroup = styled.ul`
    margin-top: 15px;
    padding: 10px;
    background: #f5f5f5;
    max-height: 240px;
    overflow-y: auto;
`;

export const ClientData = styled.div`
    background-color: #4F4F4F50;
    color: white;
    padding: 5px;
    width: 190px;
    text-align: center;
    border-radius: 0.5rem;
    font-weight: 600;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
`;

export const ClientInfos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    height: 65vh;
    overflow-y: auto;
`;

export const CellPhonesList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;


export const ListPhones = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 600;
    color: #FFFFFF;
`;

export const Phones = styled.li`
    border: 1px solid;
    padding: 10px;
    background-color: #4F4F4F90;
    border-radius: 0.5rem;
    width: 250px;
    text-align: center;
`;

export const GroupList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const ListGroups = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const Groups = styled.li`
    padding: 10px;
    background-color: #4F4F4F90;
    border-radius: 0.5rem;
    color: #FFFFFF;
    font-weight: 600;
    width: 250px;
    text-align: center;
`;
