import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  Label,
  CustomInput,
  Col,
  Row,
  Table,
  Card,
  CardBody,
  CardHeader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { ModalBodyGroupStyled } from "../style/ClientScheduleStyled.js";
import Swal from "sweetalert2";
import EditGroup from "./EditGroup";
import {
  getAllGroups,
  registerGroup,
  groupStatus
} from "../../../services/Client-Schedule/index.js";

const RegisterGroup = ({ isOpen, toggleModal, execute = async () => {} }) => {
  const [name, setName] = useState("");
  const [sendMessage, setSendMessage] = useState("ALL");
  const [list, setList] = useState([]);
  const [showBtnMoreGroups, setShowBtnMoreGroups] = useState(false);
  const [shouldUpdateList, setShouldUpdateList] = useState(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [existingGroupIds, setExistingGroupIds] = useState(new Set());
  const [limit, setLimit] = useState(4);
  const [visibleGroups, setVisibleGroups] = useState(4);
  const [enableButtons, setEnableButtons] = useState(false);

  const toggleEditModal = () => {
    setEditModalOpen(!editModalOpen);
  };

  const resetModal = () => {
    setName("");
    setSendMessage("ALL");
    setList([]);
    setShowBtnMoreGroups(false);
    setShouldUpdateList(true);
    setEditModalOpen(false);
    setSelectedGroupId(null);
    setExistingGroupIds(new Set());
    setVisibleGroups(4);
    setEnableButtons(false);

    toggleModal();
  };

  const getGroups = async (isMore = false) => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading(),
    });

    try {
      const filterSearch = isMore ? {} : { previousLimit: 0 };
      const { data } = await getAllGroups({
        ...filterSearch,
        limit: visibleGroups,
      });

      Swal.close();
      setEnableButtons(true);

      if (data.resultSet && data.resultSet.length > 0) {
        if (isMore) {
          const newGroups = data.resultSet.filter(
            (group) => !existingGroupIds.has(group.id)
          );
          setList((prevList) => [...prevList, ...newGroups]);
          setExistingGroupIds(
            (prevIds) =>
              new Set([...prevIds, ...newGroups.map((group) => group.id)])
          );
          setShowBtnMoreGroups(newGroups.length >= visibleGroups);
        } else {
          const initialGroups = data.resultSet.slice(0, visibleGroups);
          setList(initialGroups);
          setExistingGroupIds(new Set(initialGroups.map((group) => group.id)));
          setShowBtnMoreGroups(data.resultSet.length > visibleGroups);
        }
      } else {
        if (isMore) {
          setShowBtnMoreGroups(false);
        } else {
          setList([]);
          setExistingGroupIds(new Set());
          setShowBtnMoreGroups(false);
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Ocorreu um erro ao buscar os grupos. Tente novamente mais tarde.",
      });
    }
  };

  const handleActiveInactive = async (groupId, active) => {
    const action = active === 1 ? "Inativar" : "Ativar";
    const confirmed = await Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: `Você tem certeza que deseja ${action} o Grupo?`,
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar",
    });

    if (!confirmed.isConfirmed) return;

    Swal.fire({
      title: "Atualizando",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      await groupStatus(groupId, active);
      await getGroups();
      Swal.close();
    } catch (err) {
      const code = !err.response ? "00000" : err.response.data.code;
      const message = !err.response
        ? "Ocorreu um erro inesperado"
        : err.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
        showConfirmButton: true,
      });
    }
  };

  const messageTranslationMap = {
    ALL: "TODOS",
  };

  const updateList = async () => {
    try {
      const filterSearch = { limit: visibleGroups, previousLimit: 0 };
      const { data } = await getAllGroups(filterSearch);
      setList(data.resultSet || []);
      setShowBtnMoreGroups(data.resultSet.length >= visibleGroups);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Ocorreu um erro ao buscar os grupos. Tente novamente mais tarde.",
      });
    }
  };

  const handleSave = async () => {
    if (!name) {
      Swal.fire({
        icon: "error",
        title: "Campos em branco",
        text: "O Nome é obrigatório",
      });
      return;
    }

    Swal.fire({
      title: "Salvando",
      text: "Por favor, aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const data = {
      name,
      sendMessage,
    };

    await registerGroup(data)
      .then(async () => {
        setShouldUpdateList(true);
        Swal.close();
        setName("");
        setSendMessage("ALL");

        if (list.length < 4) {
          await updateList();
          await execute();
        }
      })
      .catch((error) => {
        const code = !error.response ? "00000" : error.response.data.code;
        const message = !error.response
          ? "Ocorreu um erro inesperado"
          : error.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    updateList();
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && shouldUpdateList) {
      getGroups();
      setShouldUpdateList(false);
    }
  }, [isOpen, shouldUpdateList, limit, visibleGroups]);

  return (
    <Modal isOpen={isOpen} toggle={resetModal} className="modal-lg">
      <ModalHeader toggle={resetModal}>Cadastro de Novo Grupo</ModalHeader>
      <ModalBodyGroupStyled>
        <FormGroup>
          <Label for="name">Nome do Grupo</Label>
          <Input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="sendMessage">Tipo de Mensagem</Label>
          <CustomInput
            type="select"
            id="sendMessage"
            value={sendMessage}
            onChange={(e) => setSendMessage(e.target.value)}
          >
            <option value="ALL">Todos</option>
            <option value="WHATSAPP">WhatsApp</option>
            <option value="SMS">SMS</option>
          </CustomInput>
        </FormGroup>
        <Card className="border-0">
          <CardHeader className="border-0 bg-white">
            <Row>
              <Col md="9" xl="9" className="pt-1 p-0">
                <div className="">
                  <div className="headline-2">Lista de Grupos</div>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="widget-table-overflow text-center">
              {list.length > 0 ? (
                <>
                  <Table
                    className={`table-striped table-borderless table-hover`}
                    responsive
                  >
                    <thead>
                      <tr>
                        <th className="w-10">Data de Cadastro</th>
                        <th className="w-15">Nome</th>
                        <th className="w-15">Mensagem</th>
                        <th className="w-15">Status</th>
                        <th className="w-10">Opções</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((group, key) => (
                        <tr key={key}>
                          <td>
                            <p>
                              <strong>{group.createdAt}</strong>
                            </p>
                          </td>
                          <td>
                            <p>
                              <strong className="text">{group.name}</strong>
                            </p>
                          </td>
                          <td>
                            <p>
                              <strong>
                                {messageTranslationMap[group.sendMessage] ||
                                  group.sendMessage}
                              </strong>
                            </p>
                          </td>
                          <td>
                            {Number(group.active) === 1 ? (
                              <span className="badge badge-success">ATIVO</span>
                            ) : (
                              <span className="badge badge-danger">
                                INATIVO
                              </span>
                            )}
                          </td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle caret style={{ color: "black" }}>
                                OPÇÕES
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => {
                                    setSelectedGroupId(group.id);
                                    toggleEditModal();
                                  }}
                                >
                                  Editar
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    handleActiveInactive(
                                      group.id,
                                      Number(group.active) === 1 ? 0 : 1
                                    )
                                  }
                                >
                                  {Number(group.active) === 0
                                    ? "Ativar"
                                    : "Inativar"}
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    {showBtnMoreGroups && (
                      <tbody>
                        <tr>
                          <td colSpan={5}>
                            <Button
                              className="w-100"
                              onClick={() => {
                                setVisibleGroups(
                                  (prevVisibleGroups) => prevVisibleGroups + 4
                                );
                                getGroups(true);
                              }}
                            >
                              Carregar mais...
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </>
              ) : (
                <div className="alert alert-secondary">
                  <h4 className="text-dark text-center">
                    Não possuem registros no momento.
                  </h4>
                </div>
              )}
            </div>
          </CardBody>
          <EditGroup
            editModalOpen={editModalOpen}
            toggleEditModal={toggleEditModal}
            groupId={selectedGroupId}
            initialName={
              list.find((group) => group.id === selectedGroupId)?.name || ""
            }
            initialSendMessage={
              list.find((group) => group.id === selectedGroupId)?.sendMessage ||
              "ALL"
            }
            getGroups={getGroups}
          />
        </Card>
      </ModalBodyGroupStyled>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>
          Salvar
        </Button>
        <Button color="secondary" onClick={resetModal}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RegisterGroup;
