import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  Badge,
  FormGroup,
  InputGroup,
  Input,
  Button,
  InputGroupAddon,
} from "reactstrap";
import QRCode from "qrcode.react";
import {
  getAllInstance,
  logoutInstance,
  setIntervalInstance,
  createTimeStartAndTimeEnd,
} from "../../services/Instance";
import Swal from "sweetalert2";

import { useHistory } from "react-router-dom";
import { position } from "polished";

const Instance = () => {
  const history = useHistory();
  
  const [instances, setInstances] = useState(null);
  const [timeSelect, setTimeSelect] = useState({
    minTimeExecute: null,
    maxTimeExecute: null,
  });

  const user = JSON.parse(localStorage.getItem("data_user_izichat_app"));

  const updateInstance = async () => {
    Swal.fire({
      title: "Carregando....",
      text: "Por favor aguarde!",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await getAll();
    Swal.close();
  };

  const getAll = async () => {
    await getAllInstance().then((res) => {
      if (!res.data.status) return;
      setInstances(res?.data?.resultSet ?? []);
    });
  };

  const logout = async (instance) => {
    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Tem certeza que deseja desconectar este número?",
      showCancelButton: true,
      cancelButtonText: "NÃO, SAIR",
      confirmButtonText: "SIM, DESCONECTAR",
    }).then(async (confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: "Saindo....",
        text: "Por favor aguarde!",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      await logoutInstance(instance)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: "Número desconectado",
          });
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: "Não foi possível desconectar o número",
          });
        });
    });
  };

  const handleSaveTime = async (instanceId) => {
    if (timeSelect.minTimeExecute === timeSelect.maxTimeExecute) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Os horários de início e término não podem ser iguais.",
      });
      return;
    }

    Swal.fire({
      title: "Salvando",
      text: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await createTimeStartAndTimeEnd(
      timeSelect.minTimeExecute,
      timeSelect.maxTimeExecute,
      instanceId
    )
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Horários salvos com sucesso!",
        });
      })
      .catch((err) => {
        const code = err.response.data.code;
        const message = err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    getAll();
    const interval = setInterval(() => getAll(), 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (user?.isUpdatePassword > 0) {
      history.push("/admin/update-password");
    }
  }, []);

  useEffect(() => {
    let timeoutId;
    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        history.push("/admin/dashboard");
      }, 120000);
    };

    const resetTimer = () => {
      window.addEventListener("mousemove", resetTimeout);
      window.addEventListener("keypress", resetTimeout);
      resetTimeout();
    };

    resetTimer();

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("mousemove", resetTimeout);
      window.removeEventListener("keypress", resetTimeout);
    };
  }, [history]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <Button color="primary" className="mb-4" onClick={updateInstance}>Recarregar instancias</Button>
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          {instances ? (
            instances.map((instance, key) => (
              <>
                <Card className="border-0 mb-4" key={key}>
                  <CardHeader className="border-0 bg-white">
                    <div className="headline-2">
                      WHATSAPP ID:{" "}
                      <Badge color="success">{instance.name}</Badge>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Row className="align-items-center justify-content-center">
                      <Col xs="12" lg="6" className="">
                        <div className="align-self-center">
                          <h3 className="mb-4">Situação</h3>
                          {instance.isRead ? (
                            <div>
                              <h1 className="text-success mb-1">Conectado</h1>
                              <div>
                                <h5 className="mt-4">Dados do dispositivo</h5>
                                <p>
                                  Nome: <strong>{instance.user.name}</strong>
                                </p>
                                <p>
                                  Número: <strong>{instance.user.phone}</strong>
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <h1 className="text-danger mb-1">Desconectado</h1>
                              <p>
                                Você não se conectou à API. Para conectar-se,
                                basta apontar a câmera de seu celeular para o
                                QRCODE ao lado, a partir do aplicativo do
                                WhatsApp
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="mt-5 form-group">
                          <h5>Intervalo de envio mensagens</h5>
                          <select
                            className="form-control form-control-md"
                            value={instance.minuteSendMessage}
                            onChange={(ev) => {
                              Swal.fire({
                                title: "Alterando",
                                text: "Por favor aguarde",
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                didOpen: () => {
                                  Swal.showLoading();
                                },
                              });
                              setIntervalInstance(
                                instance.id,
                                ev.target.value
                              ).then(() => {
                                Swal.fire({
                                  icon: "success",
                                  title: "Sucesso!",
                                  text: "Alteração realizada com sucesso!",
                                });
                              });
                            }}
                          >
                            <option value={1}>ENVIO A CADA 1 MINUTO</option>
                            <option value={2}>ENVIO A CADA 2 MINUTO(s)</option>
                            <option value={3}>ENVIO A CADA 3 MINUTO(s)</option>
                            <option value={4}>ENVIO A CADA 4 MINUTO(s)</option>
                            <option value={5}>ENVIO A CADA 5 MINUTO(s)</option>
                            <option value={10}>ENVIO A CADA 10 MINUTO(s)</option>
                            <option value={15}>ENVIO A CADA 15 MINUTO(s)</option>
                          </select>
                        </div>
                        <div className="mt-5 form-group">
                          <h5>Horário execução rotina de envio</h5>
                          <FormGroup>
                            <InputGroup>
                              <Input
                                type="time"
                                name="minTimeExecute"
                                value={instance.minTimeExecute}
                                onChange={(ev) => {
                                  const copyInstance = instances;
                                  instances[key].minTimeExecute =
                                    ev.target.value;
                                  setInstances(copyInstance);

                                  setTimeSelect({
                                    ...timeSelect,
                                    minTimeExecute: ev.target.value,
                                  });
                                }}
                              />
                              <Input
                                type="time"
                                name="maxTimeExecute"
                                value={instance.maxTimeExecute}
                                onChange={(ev) => {
                                  const copyInstance = instances;
                                  instances[key].maxTimeExecute =
                                    ev.target.value;
                                  setInstances(copyInstance);

                                  setTimeSelect({
                                    ...timeSelect,
                                    maxTimeExecute: ev.target.value,
                                  });
                                }}
                              />
                              <InputGroupAddon addonType="append">
                                <Button
                                  style={{ backgroundColor: '#009582', color: 'white' }}
                                  onClick={() => {
                                    handleSaveTime(instance.id);
                                  }}
                                >
                                  Salvar
                                </Button>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </div>
                      </Col>
                      <Col xs="12" lg="6" className="text-center">
                        {instance.resultSet !== "" &&
                          instance.isRead === false ? (
                          <>
                            <p className="text-info mb-3">Apontar câmera</p>
                            <QRCode
                              value={instance.resultSet}
                              size={300}
                              level="M"
                              imageSettings={{
                                src: "https://foodsafetybrazil.org/wp-content/uploads/2021/01/icon.png",
                                excavate: true,
                                width: "50",
                                height: "50",
                              }}
                            />
                          </>
                        ) : instance.isRead ? (
                          <>
                            <div className="mb-4">
                              <h5>
                                Qtd Envios Pendentes{" "}
                                <span className="badge" style={{ backgroundColor: '#009582', color: 'white' }}>
                                  {instance.qtdMessageWait}
                                </span>
                              </h5>
                              <p>
                                Última chamada{" "}
                                <strong className="badge badge-info">
                                  {instance.lastSendMessage}
                                </strong>
                              </p>
                            </div>
                            <div>
                              <a
                                href="#/admin/messages"
                                className="btn btn-success btn-md"
                              >
                                ENVIAR MENSAGENS
                              </a>
                            </div>
                            <div className="mt-2">
                              <button
                                className="btn btn-warning btn-md"
                                onClick={() => {
                                  logout(instance.name);
                                }}
                              >
                                DESCONECTAR NÚMERO
                              </button>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </>
            ))
          ) : (
            <div className="alert alert-info">
              <h4 className="text-info m-0">
                Carregando... por favor aguarde!
              </h4>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Instance;
