import styled from "styled-components";

export const CardPhone = styled.section`
    border-radius: 2rem;
    margin-left: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 1600px){
        width: 25rem;
        height: 40rem;
    }

    @media screen and (max-width: 1600px){
        width: 26rem;
        height: 40rem;
    }

    
    @media screen and (max-width: 991px) {
        width: 28rem;
        height: 45rem;
        margin-top: 30px;
        margin-left: 0;
    }

    @media screen and (max-width: 527px) {
        width: 95vw;
    } 
`;

export const PreviewContainer = styled.section`
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;    
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    position: relative;

    @media screen and (max-width: 640px) {
        width: 95%;
    }
`;

export const PhoneHeader = styled.section`
    width: 100%;
    height: 3.4rem;
    background-color: white;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
`;

export const Left = styled.div`
    border-radius: 100%;
    width: 10px;
    height: 10px;
    background-color: gray;
`;

export const Center = styled.div`
    border-radius: 2rem;
    width: 55px;
    height: 10px;
    background-color: gray;
    position: relative;
    left: 20px;
`;

export const Icons = styled.div`
    width: 3rem;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
`;

export const ImgBateria = styled.img`
    height: 20px;
    width: 25px;
`;

export const ImgContect = styled.img`
    height: 15px;
    width: 15px;
`;

export const PreviewHeader = styled.section`
    width: 100%;
    height: 3.6rem;
    display: flex;
    align-items: center;
    padding: 0.5rem 20px;
    background-color: #4D53E0;
    gap: 20px;
    color: white;
`;

export const PreviewFooter = styled.section`
    border-top: 1px solid lightGray;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 8rem;
    background-color: white;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    font-size: 0.9rem;
    position: absolute;
    bottom: 0;

     img{
        width: 2rem;
     }
`;

export const PreviewWrap = styled.section`
    width: 100%;
    height: 27rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
`;

export const UserAvatar = styled.div`
    background-color: white;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ImgAvatar = styled.img`
    width: 30px;
    height: 30px;

`;

export const PreviewBody = styled.div`
    width: 75%;
    max-width: 300px;
    height: auto;
    max-height: 200px;
    background-color: #4D53E0;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 1.5rem;
    word-wrap: break-word;
    overflow-y: auto;
    gap: 15px;
`;

export const Paragraph = styled.p`
    padding: 10px 15px 20px;
    color: white;
    font-size: 0.9rem;
`;
