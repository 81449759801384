import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { getMessageByDayAndStatus } from "../../../services/Instance";
import { Alert } from "reactstrap";

const GraphDayAndStatus = ({ type = "bar", filter = {}, isSubmit}) => {
    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        setSeries([])
        getMessageByDayAndStatus(filter)
            .then(({ data }) => {
                setSeries([
                    { 
                        name: 'AGUARDANDO', 
                        data: data.resultSet.map((register) => { return register.qtdWait })
                    },
                    { 
                        name: 'ENVIADO', 
                        data: data.resultSet.map((register) => { return register.qtdSend }) 
                    },
                    {
                        name: 'ERRO', 
                        data: data.resultSet.map((register) => { return register.qtdErr }) 
                    },
                    { 
                        name: 'CANCELADO', 
                        data: data.resultSet.map((register) => { return register.qtdCanceled }) 
                    },
                ]);
                setCategories(data.resultSet.map((register) => { return register.createdAt }));
            });
    }, [isSubmit]);

    const state = {

        series: series,
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: categories,
            },
            yaxis: {
                title: {
                    text: 'Mensagens'
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " Mensagens"
                    }
                }
            }
        },
    };

    if (series.length <= 0) {
        return <>
            <Alert color="secondary" className="text-secondary">Carregando</Alert>
        </>
    }

    return (
        <div id="chart">
            <ReactApexChart options={state.options} series={state.series}
                type={type} height={350} />
        </div>
    );
}

export default GraphDayAndStatus;