import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { getMessageByInstanceAndStatus } from "../../../services/Instance";
import { Alert } from "reactstrap";

const GraphInstanceandStatus = ({ type = "bar", filter = {}, isSubmit }) => {
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    setSeries([])
    getMessageByInstanceAndStatus(filter)
      .then(({ data }) => {
        setSeries([
          {
            name: 'AGUARDANDO',
            data: data.resultSet.map((register) => { return register.qtdWait })
          },
          {
            name: 'ENVIADO',
            data: data.resultSet.map((register) => { return register.qtdSend })
          },
          {
            name: 'ERRO',
            data: data.resultSet.map((register) => { return register.qtdErr })
          },
          {
            name: 'CANCELADO',
            data: data.resultSet.map((register) => { return register.qtdCanceled })
          },
        ]);
        setCategories(data.resultSet.map((register) => { return register.instance }));
      });
  }, [isSubmit]);

  const state = {
    series: series,
    options: {
      chart: {
        type: 'bar',
        height: 430
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        }
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: '12px',
          colors: ['#fff']
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      xaxis: {
        categories: categories,
      },
    },
  };

  if (series.length <= 0) {
    return <>
      <Alert color="secondary" className="text-secondary">Carregando</Alert>
    </>
  }

  return (
    <div id="chart">
      <ReactApexChart
        options={state.options} series={state.series}
        type={type}
      />
    </div>
  );
}

export default GraphInstanceandStatus;