import { useState, useEffect } from "react";
import {
  Col,
  Row,
  Table,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  CardFooter,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import Widget from "../../components/Widget/Widget.js";

import {
  listAllInstances,
  getCampaignList,
  postResendCampaign,
  postCancelCampaign,
} from "../../services/Instance/index.js";

import Swal from "sweetalert2";

import ModalAddMoreContacts from "./ModalAddMoreContacts.js";
import { Div } from "./DivStyled.js";

const CampaignList = () => {
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState({
    dateType: "createdAt",
    dateStart: null,
    dateEnd: null,
    typeCampaign: "",
    title: [],
    instance: [],
    previusLimit: 0,
    limit: 15,
    status: null,
  });
  const [campaign, setCampaign] = useState({
    dateType: "createdAt",
    instance: [],
    title: [],
    typeCampaign: "",
    status: null,
  });
  const [showBtnMoreCampaigns, setShowBtnMoreCampaigns] = useState(false);
  const [enableButtons, setEnableButtons] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [numberList, setNumberList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectionMode, setSelectionMode] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [numbersPhone, setNumbersPhone] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [message, setMessage] = useState({
    numbers: [],
    instance: null,
    groups: null
  });
  const [dropdownStates, setDropdownStates] = useState(list.map(() => false));

  const toggleTooltip = (index) => {
    setTooltipOpen((prevTooltipOpen) => {
      const newTooltipOpen = [...prevTooltipOpen];
      newTooltipOpen[index] = !newTooltipOpen[index];
      return newTooltipOpen;
    });

  };

  const toggleDropdown = (key) => {
    const newDropdownStates = [...dropdownStates];
    newDropdownStates[key] = !newDropdownStates[key];
    setDropdownStates(newDropdownStates);
  };

  const openModal = (campaignId) => {
    setSelectedCampaignId(campaignId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setMessage({
      numbers: []
    })
    setNumbersPhone('');
    setSelectedGroup('');
    setSelectionMode('');
    setIsModalOpen(false);
    setNumberList([]);
    setShowAlert(false);
  };

  const handleListInstance = () => {
    listAllInstances().then((res) => {
      setCampaign({
        ...campaign,
        instance: res?.data?.resultSet ?? [],
      });
    });
  };

  const handleChangeFilter = (ev) => {
    if (ev.target.id === "dateType") {
      setFilter({
        ...filter,
        dateType: ev.target.value,
      });
    }
    if (ev.target.id === "dateStart") {
      setFilter({
        ...filter,
        dateStart: ev.target.value,
      });
    }
    if (ev.target.id === "dateEnd") {
      setFilter({
        ...filter,
        dateEnd: ev.target.value,
      });
    }
    if (ev.target.id === "typeCampaign") {
      setFilter({
        ...filter,
        typeCampaign: ev.target.value,
      });
    }
    if (ev.target.id === "title") {
      setFilter({
        ...filter,
        title: ev.target.value,
      });
    }

    if (ev.target.id === "instance") {
      setFilter({
        ...filter,
        instance: ev.target.value,
      });
    }
  };

  const handleResendCampaigns = async (campaignId) => {
    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Você tem certeza que deseja reenviar a campanha?",
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar"
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: "Carregando, Por favor aguarde...",
        text: "Por favor aguarde.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      postResendCampaign(campaignId).then((ev) => {
        Swal.fire({
          icon: "success",
          title: "Campanha enviada com sucesso.",
          confirmButtonText: "Fechar",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      });
    });
  };

  const handleCancelCampaigns = async (campaignId) => {
    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Você tem certeza que deseja cancelar a campanha?",
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar"
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: "Carregando, Por favor aguarde...",
        text: "Por favor aguarde.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      postCancelCampaign(campaignId)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Campanha cancelada com sucesso.",
            confirmButtonText: "Fechar",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        })
        .catch((err) => {
          const code = !err.response ? "00000" : err.response.data.code;
          const message = !err.response
            ? "Ocorreu um erro inesperado"
            : err.response.data.message;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  const getCampaigns = async (isMore = false) => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let filterSearch = {
      ...filter,
    };
    if (!isMore) {
      setFilter({
        ...filter,
        previusLimit: 0,
      });

      filterSearch = {
        ...filter,
        previusLimit: 0,
      };
    }

    await getCampaignList(filterSearch).then(({ data }) => {
      setEnableButtons(filter.status === "0" ?? false);
      Swal.close();

      if (isMore) {
        setList([...list, ...data.resultSet.result]);
        setShowBtnMoreCampaigns(data?.resultSet?.result?.length > 0);
      } else {
        setList(data?.resultSet?.result ?? []);
        setShowBtnMoreCampaigns(data?.resultSet.result.length >= 15);
      }

      setFilter({
        ...filter,
        previusLimit: data.resultSet.limit,
      });
    });
  };

  useEffect(() => {
    getCampaigns(true);
    handleListInstance();
  }, []);

  return (
    <div>
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <Card className="border-0 mb-5 mt-3 shadow">
            <CardHeader className="bg-white border-0">
              <h4 className="headline-2">Filtro</h4>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="8">
                  <FormGroup>
                    <label>Data de Cadastro</label>
                    <div className="input-group">
                      <input
                        id="dateStart"
                        type="date"
                        className="form-control form-control-md"
                        onChange={handleChangeFilter}
                      />
                      <input
                        id="dateEnd"
                        type="date"
                        className="form-control form-control-md"
                        onChange={handleChangeFilter}
                      />
                      <select
                        id="dateType"
                        className="form-control form-control-md"
                        onChange={handleChangeFilter}
                      >
                        <option value="createdAt" selected>
                          Data de cadastro
                        </option>
                      </select>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>Tipo da campanha</label>
                    <select
                      id="typeCampaign"
                      className="form-control form-control-md"
                      onChange={handleChangeFilter}
                    >
                      <option value="" selected>
                        Selecione...
                      </option>
                      <option value="WHATSAPP">WHATSAPP</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col md="8">
                  <FormGroup>
                    <label>Titulo</label>
                    <div className="text">
                      <input
                        id="title"
                        type="text"
                        className="form-control form-control-md"
                        onChange={handleChangeFilter}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>Instancia</label>
                    <select
                      id="instance"
                      className="form-control form-control-md"
                      onChange={handleChangeFilter}
                    >
                      <option value="" selected>
                        Selecione...
                      </option>
                      {campaign?.instance?.length > 0 ? (
                        campaign?.instance.map((instance, key) => (
                          <option key={key} value={instance.id}>
                            {instance.name}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="border-0 bg-white">
              <button
                className="btn btn-success btn-md"
                onClick={() => {
                  getCampaigns(false);
                }}
              >
                Buscar
              </button>
            </CardFooter>
          </Card>
          <Row className="gutter">
            <Col>
              <Widget>
                <Card className="border-0">
                  <CardHeader className="border-0 bg-white">
                    <Row>
                      <Col md="9" xl="9">
                        <div className="">
                          <div className="headline-2">Lista de Campanhas</div>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="widget-table-overflow text-center">
                      {list.length > 0 ? (
                        <>
                          <Table
                            className={`table-striped table-borderless table-hover`}
                            responsive
                          >
                            <thead>
                              <tr>
                                <th className="w-14">DATA CADASTRO</th>
                                <th className="w-14">INSTANCIA</th>
                                <th className="w-14">TITULO</th>
                                <th className="w-14">MENSAGEM</th>
                                <th className="w-14">TIPO DE CAMPANHA</th>
                                <th className="w-14">STATUS</th>
                                <th className="w-14">OPÇÕES</th>
                              </tr>
                            </thead>
                            <tbody>
                              {list.map((campaign, key) => (
                                <tr key={key}>
                                  <td>
                                    <p>
                                      <strong>{campaign.createdAt}</strong>
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      <strong className="badge badge-info">
                                        {campaign.nameInstance}
                                      </strong>
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      <strong className="text">
                                        {campaign.title}
                                      </strong>
                                    </p>
                                  </td>
                                  <td>
                                    <Tooltip
                                      className="align-self-start text-start w-auto"
                                      placement="top"
                                      isOpen={tooltipOpen[key]}
                                      target={`tooltip-${key}`}
                                      toggle={() => toggleTooltip(key)}
                                      trigger="hover"
                                      delay={{ show: 100, hide: 0 }}
                                      style={{
                                        backgroundColor: "#FFF",
                                        border: "1px solid #C7D0D9",
                                        color: "#000",
                                        fontWeight: "500",
                                        borderRadius: "5px",
                                        width: "15rem",
                                        padding: "10px",
                                        whiteSpace: "pre-wrap",
                                        textAlign: "start",
                                        position: "absolute",
                                        bottom: "10px"
                                      }}
                                    >
                                      {campaign.message}
                                    </Tooltip>
                                    <div id={`tooltip-${key}`}>
                                      {campaign.message.length > 20 ? `${campaign.message.slice(0, 20)}...` : campaign.message}
                                    </div>
                                  </td>
                                  <td>
                                    <strong className="text">
                                      {campaign.typeCampaign}
                                    </strong>
                                  </td>
                                  <td>
                                    {Number(campaign.active) === 1 ? (
                                      <span className="badge badge-success">
                                        ATIVO
                                      </span>
                                    ) : (
                                      <span className="badge badge-danger">
                                        INATIVO
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <Dropdown isOpen={dropdownStates[key]} toggle={() => toggleDropdown(key)}>
                                      <DropdownToggle caret style={{ color: 'black' }}>OPÇÕES</DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem>
                                          <Link to={`/admin/campaignInfo/${campaign.id}`} replace={true} style={{ color: 'black' }}
                                          >Informações
                                          </Link>
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => {
                                            setCampaign({ campaign });
                                            handleResendCampaigns(campaign.id);
                                          }}
                                        >
                                          Reenviar
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => {
                                            setCampaign({ campaign });
                                            handleCancelCampaigns(campaign.id);
                                          }}
                                        >
                                          Cancelar
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => {
                                            openModal(campaign.id);
                                          }}
                                        >
                                          Adicionar contatos
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              ))}
                            </tbody>

                            {showBtnMoreCampaigns && (
                              <tbody>
                                <tr>
                                  <td colSpan={7}>
                                    <Button
                                      className="w-100"
                                      onClick={() => {
                                        setFilter({ ...filter });
                                        getCampaigns(true);
                                      }}
                                    >
                                      Carregar mais...
                                    </Button>
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </Table>
                        </>
                      ) : (
                        <div className="alert alert-secondary">
                          <h4 className="text-dark text-center">
                            Não possuem registros no momento.
                          </h4>
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Widget>
            </Col>
          </Row>

          <ModalAddMoreContacts
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            campaignId={selectedCampaignId}
          />

          
        </Col >
      </Row >
    </div >
  );
};

export default CampaignList;
