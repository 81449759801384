import { useState, useEffect } from "react";

import { Col, Label, Row, Table } from "reactstrap";
import Widget from "../../components/Widget/Widget";
import api from "../../services/api";

import s from "../tables/Tables.module.scss";
import "../../components/formUser/FormUser";
import FormUser from "../../components/formUser/FormUser";
import { Button } from "reactstrap";
import { Icon } from "@material-ui/core";
import Swal from "sweetalert2";
import { maskDocument, phoneMask, maskDateTime } from "../../utils/mask";
import { useHistory } from "react-router-dom";

const User = () => {
  const [userList, setUserList] = useState([]);
  const history = useHistory();
  const [showForm, setShowForm] = useState(false);
  const [totAllowedUser, setTotAllowedUser] = useState(0);
  const [enableActions, setEnableActions] = useState(false);
  const [userSelected, setuserSelected] = useState({});
  const [configuration, setConfiguration] = useState({});

  const user = JSON.parse(localStorage.getItem("data_user_izichat_app"));

  const showOrHideForm = () => {
    setShowForm(!showForm);
  };

  const LoadUserlist = async () => {
    Swal.fire({
      title: "Carregando...",
      html: "Por favor, aguarde ... ",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await api
      .get("/api/v1/accessUser")
      .then(({ data: { resultSet } }) => {
        Swal.close();
        setUserList(resultSet.map((data) => ({ ...data, selected: false })));
        setTotAllowedUser(resultSet.length);
      })
      .catch((err) => {
        const code = err.response.data.code;
        const message = err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const onSave = (data) => {
    showOrHideForm();
    LoadUserlist();
    if (data) {
      setEnableActions(!enableActions);
    }
  };

  const getConfiguration = async () => {
    await api.get("/api/v1/configuration").then(({ data: { resultSet } }) => {
      setConfiguration(resultSet);
    });
  };

  useEffect(() => {
    LoadUserlist();
    getConfiguration();
  }, []);

  const resetSelect = () => {
    const reseted = userList.map((data) => ({ ...data, selected: false }));

    setUserList(reseted);
  };

  const handleCheckbox = (e) => {
    const { value } = e.target;

    resetSelect();
    setuserSelected({});
    const users = userList.map((data) => {
      data.selected = data.id === value ? e.target.checked : false;

      if (data.selected) {
        setuserSelected(data);
      }

      return data;
    });

    // caso for selecionado um user com o formulário aberto o form será fechado automaticamente
    if (showForm) {
      showOrHideForm();
    }

    setUserList(users);
    setEnableActions(e.target.checked);
  };

  const onDeleteUser = async () => {
    const user = userList.filter((f) => f.selected)[0];

    Swal.fire({
      title: "Processando...",
      html: "Por favor, aguarde ... ",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await api
      .delete(`/api/v1/accessUser/${user.id}`)
      .then(({ data: { status } }) => {
        if (status) {
          LoadUserlist();
          setEnableActions(false);
          resetSelect();
        }
      });

    Swal.close();
  };

  const createUser = () => {
    const newTot = userList.length + 1;

    // Verifica se existe usuários disponivel para cadastrar
    if (newTot > configuration.userByPlan) {
      Swal.fire({
        icon: "info",
        title: "Notificação",
        text: "Seu pacote de usuários finalizou, entre em contato com seu representante comercial IZIDEV, atualize seu plano",
      });

      return true;
    }

    showOrHideForm();
  };

  const ActionsButtons = () => <></>;

  useEffect(() => {
    if (user?.isUpdatePassword > 0) {
      history.push("/admin/update-password");
    }
  }, []);

  return (
    <>
      <Row className="mb-2 mt-2">
        <Col xs={3} sm={3} xl={3}>
          <div
            className="widget-p-sm pb-0"
            style={{
              color: "primary",
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
            }}
          >
            <div>
              <div className="d-flex justify-content-between">
                <Icon
                  className="fa fa-users"
                  aria-hidden="true"
                  style={
                    {
                      //color: "#F7F8FB"
                    }
                  }
                />
                <p className="headline-3" style={{ color: "rgb(67, 188, 19)" }}>
                  {configuration.userByPlan}
                </p>
              </div>
              <div className="d-flex flex-column">
                <p
                  style={{
                    fontSize: "14px",
                    // color: "#F7F8FB"
                  }}
                >
                  Cadastros disponíveis
                </p>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={3} sm={3} xl={3}>
          <div
            className="widget-p-sm pb-0"
            color="primary"
            style={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}
          >
            <div color="primary">
              <div className="d-flex justify-content-between">
                <Icon
                  className="fa fa-address-card"
                  aria-hidden="true"
                  style={
                    {
                      // color: "#F7F8FB"
                    }
                  }
                />
                <p className="headline-3" style={{ color: "#FFC405" }}>
                  {totAllowedUser}
                </p>
              </div>
              <div className="d-flex flex-column">
                <p
                  style={{
                    fontSize: "14px",
                    // color: "#F7F8FB"
                  }}
                >
                  Usuários cadastrados
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {showForm ? (
        <FormUser handleSave={onSave} dataUser={userSelected} />
      ) : null}

      <Row>
        <Col>
          <Widget>
            <div className={`d-flex justify-content-between" ${s.tableTitle}`}>
              <div className="headline-2">Lista de Usuário</div>
              <div className="d-inline-flex">
                {enableActions ? <ActionsButtons /> : null}
                <Button
                  className="d-flex rounded-pill btn-sm mr-3"
                  color="success"
                  onClick={createUser}
                >
                  <i className="fa fa-plus" aria-hidden="true" />
                </Button>
              </div>
            </div>
            <div className="widget-table-overflow">
              <Table
                className={`table-striped table-borderless table-hover ${s.statesTable}`}
                responsive
              >
                <thead>
                  <tr>
                    <th />
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>CPF/CNPJ</th>
                    <th>Telefone</th>
                    <th>Data de criação</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {userList.map((item, index) => (
                    <tr key={index}>
                      <td className="text-center">
                        <div className="checkbox checkbox-primary">
                          <input
                            id={`check-${index}`}
                            className="styled form-check-input "
                            type="checkbox"
                            style={{ marginLeft: "5px" }}
                            name={item.id}
                            value={item.id}
                            onChange={handleCheckbox}
                            checked={item.selected}
                          />
                          <Label for={`check-${index}`} />
                        </div>
                      </td>
                      <td className="d-flex align-items-center">
                        <Icon className="fa fa-user" aria-hidden="true" />
                        <span className="ml-3">{item.name}</span>
                      </td>
                      <td>{item.email}</td>
                      <td>{maskDocument(item.document)}</td>
                      <td>{phoneMask(item.phone)}</td>
                      <td>{maskDateTime(item.createdAt)}</td>
                      <td>
                        <div className="d-inline-flex">
                          <Button
                            className="d-flex rounded-pill btn-sm mr-2"
                            color="primary-hover"
                            onClick={() => {
                              setuserSelected(item);
                              showOrHideForm();
                            }}
                          >
                            <i className="fa fa-pencil" aria-hidden="true" />
                          </Button>
                          <Button
                            className="d-flex rounded-pill btn-sm mr-2"
                            color="secondary-red"
                            onClick={() => {
                              setuserSelected(item);
                              onDeleteUser();
                            }}
                          >
                            <i className="fa fa-trash" aria-hidden="true" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Widget>
        </Col>
      </Row>
    </>
  );
};

export default User;
