import api from "../api";

export const registerBillService = async (data) => {
  return await api.post(`/api/v1/bills`, data);
};

export const getListBillService = async (filter) => {
  return await api.get('/api/v1/bills', {
    params: filter
  });
};

export const deleteBillService = async (billId, newStatus) => {
  return await api.put(`/api/v1/bills/status/${billId}`, { newStatus });
};

export const updateBillService = async (billId, data) => {
  return await api.put(`/api/v1/bills/${billId}`, data);
};

export const registerCategoryService = async (data) => {
  return await api.post(`/api/v1/category`, data);
};

export const getCategoryService = async (filter) => {
  return await api.get(`/api/v1/category`, {
    params: filter
  });
};

export const updateCategoryService = async (categoryId, data) => {
  return await api.put(`/api/v1/category/${categoryId}`, data);
};

export const getResumeStatusService = async (filter) => {
  return await api.get('/api/v1/category/resume/status', {
    params: filter,
  });
};