import api from "../api";

export const getBlackListPhones = async (filter) => {
    return await api.get('/api/v1/blacklist/phones', {
      params: filter
    });
  };

export const postBlackListPhones = async (phones) => {
    return await api.post(`/api/v1/blacklist/phones`, phones);
};

export const deleteBlackListPhone = async (phoneId, newStatus) => {
    return await api.put(`/api/v1/blacklist/phones/status/${phoneId}`, { newStatus });
};
