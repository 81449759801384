import React, { useEffect, useRef, useState } from 'react';

import IconBateria from "./assets/bateria.png"
import IconConect from "./assets/conexao.png"
import Chatbot from "./assets/chatbot.png"
import Send from "./assets/send.png"
import {
    Center,
    ChatbotAvatar,
    Icons,
    ImgAvatar,
    ImgBateria,
    ImgContect,
    Left,
    PhoneHeader,
    PreviewContainer,
    PreviewFooter,
    PreviewHeader,
    PreviewWrap,
    Paragaph,
    PreviewBody,
    PreviewBodyConatiner,
    ParagraphContainer,
    ParagraphContainerTrigger
} from './style/TriggerPreviewStyled';

export default function TriggerPreview({ originQuestionMessage, destinyQuestionMessage, isTriggerTitleFilled }) {
    const titleContainerRef = useRef(null);

    useEffect(() => {
        const previewContainer = document.getElementById("preview-container");
        if (previewContainer && (isTriggerTitleFilled || destinyQuestionMessage)) {
            previewContainer.scrollTo({
                top: previewContainer.scrollHeight,
                behavior: 'smooth'
            });
        }
    }, [isTriggerTitleFilled, destinyQuestionMessage]);


    return (
        <PreviewContainer>
            <PhoneHeader className='w-100 d-flex justify-content-between bg-white align-items-center'>
                <Left className='rounded' />
                <Center />
                <Icons className='d-flex align-items-center justify-content-between'>
                    <ImgBateria src={IconBateria} alt="Bateria" />
                    <ImgContect src={IconConect} alt="Conexão" />
                </Icons>
            </PhoneHeader>
            <PreviewWrap className='w-100 d-flex flex-column align-items-center'>
                <PreviewHeader className='w-100 d-flex align-items-center text-white'>
                    <ChatbotAvatar className='bg-white rounded-pill d-flex align-items-center justify-content-center'>
                        <ImgAvatar src={Chatbot} alt="Chatbot"></ImgAvatar>
                    </ChatbotAvatar>
                    <p>Bate-Papo</p>
                </PreviewHeader>

                <PreviewBodyConatiner className='w-100 d-flex flex-column' id="preview-container">
                    {originQuestionMessage && (
                        <PreviewBody className='d-flex flex-column h-auto align-items-end justify-content-end'>
                            <p className='position-relative origin-question mb-2'>Questão de Origem</p>
                            <ParagraphContainer>
                                <Paragaph>{originQuestionMessage}</Paragaph>
                            </ParagraphContainer>
                        </PreviewBody>
                    )}
                    <PreviewBody className='d-flex flex-column h-auto align-items-start justify-content-start'>
                        <p className='trigger position-relative mb-2'>Gatilho</p>
                        <ParagraphContainerTrigger>
                            <Paragaph id='message-trigger' ref={titleContainerRef}></Paragaph>
                        </ParagraphContainerTrigger>
                    </PreviewBody>
                    {destinyQuestionMessage && (
                        <PreviewBody className='d-flex flex-column h-auto align-items-end justify-content-end'>
                            <p className='destiny-question position-relative mb-2'>Questão de Destino</p>
                            <ParagraphContainer>
                                <Paragaph id='message-destiny'>{destinyQuestionMessage}</Paragaph>
                            </ParagraphContainer>
                        </PreviewBody>
                    )}
                </PreviewBodyConatiner>

                <PreviewFooter className='bg-white w-100 d-flex align-items-center justify-content-around'>
                    <p>Escreva sua mensagem</p>
                    <img src={Send} alt="Send" />
                </PreviewFooter>
            </PreviewWrap>
        </PreviewContainer>
    )
}