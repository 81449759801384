/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from "react";
import Swal from "sweetalert2";
import { resetPasswordService } from "../../services/Register/User";

function ResetPasswordService() {
  const [email, setEmail] = useState("");

  const handlePasswordReset = async () => {
    const { value: inputEmail } = await Swal.fire({
      title: "Enviar email para",
      input: "email",
      inputPlaceholder: "Digite o email",
      inputAttributes: { required: "required" },
      showCancelButton: true,
      confirmButtonText: "Enviar",
      preConfirm: async (inputEmail) => {
        if (!inputEmail) {
          Swal.showValidationMessage("O campo de e-mail é obrigatório");
        } else if (!isValidEmail(inputEmail)) {
          Swal.showValidationMessage("O email inserido não é válido");
        } else {
          Swal.fire({
            title: "Verifincando Email",
            text: "Por favor, aguarde.",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          await resetPasswordService(inputEmail)
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Sucesso",
                text: "Email enviado com sucesso!",
              });
            })
            .catch((err) => {
              const code = !err.response ? "00000" : err.response.data.code;
              const message = !err.response
                ? "Ocorreu um erro inesperado"
                : err.response.data.message;
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `[${code}] ${message}`,
              });
            });
        }
      },
    });

    setEmail(inputEmail);
  };
  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  return (
    <a
      className="rounded-pill mt-0 mb-3"
      style={{ fontSize: '0.9rem' }}
      onClick={handlePasswordReset}
    >
      Esqueci a senha
    </a>
  );
}

export default ResetPasswordService;
