import api from "../api";

export const postConfig = async (data) => {
  return await api.post(`/api/v1/chat/config`, data);
};

export const updateConfig = async (data) => {
  return await api.put(`/api/v1/chat/config`, data);
};

export const getConfig = async () => {
  return await api.get(`/api/v1/chat/config`);
};