import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import emoji from "@jukben/emoji-search";
import { useEffect, useRef, useState } from "react";
import {
  createChatbotQuestionService,
  getChatbotQuestionService,
  updateChatbotQuestionService,
} from "../../../services/Chatbot/index.js";
import Swal from "sweetalert2";

import {
  ClassForm,
  ModalBodyStyled,
} from "./style/PreviewStyle.js";

import Preview from "./Preview.js";
import { ToggleLeft } from "phosphor-react";

const RegisterChatbotQuestionComponent = ({
  id = null,
  chatbotId = null,
  isOpen = true,
  toggle = () => { },
  execute = () => { },
}) => {
  const [register, setRegister] = useState({
    id: null,
    chatbotId: null,
    type: "",
    question: "",
  });
  const ref = useRef(600);
  const Item = ({ entity: { name, char } }) => <div>{`${name}: ${char}`}</div>;
  const updateMessagePreview = (event) => {
    const previewParagraph = document.getElementById("message-preview");
    if (previewParagraph) {
      const messageText = event.target.value.replace(/\n/g, "<br>");
      previewParagraph.innerHTML = `<p>${messageText}</p>` || "";
    }
  };

  const toggleModal = () => {
      setRegister({
        chatbotId: null,
        type: "",
        question: "",
      });
    toggle();
  };

  const handleSubmit = () => {
    Swal.fire({
      title: !register?.id ? "Realizando cadastro" : "Atualizando cadastro",
      html: "Por favor, aguarde ... ",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (register.id > 0) {
      handleUpdate();
      return;
    }

    createChatbotQuestionService(register)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro realizado com sucesso",
        });
        setRegister({
          chatbotId: null,
          type: "",
          question: "",
        });
        toggle();
        execute();
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleUpdate = () => {
    updateChatbotQuestionService(register)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro alterado com sucesso",
        });
        setRegister({
          chatbotId: null,
          type: "",
          question: "",
        });
        toggle();
        execute();
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGetChatbotQuestion = (id) => {
    if (!id || id < 0) return;

    Swal.fire({
      title: "Carregando",
      html: "Por favor, aguarde ... ",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getChatbotQuestionService(id)
      .then(({ data }) => {
        Swal.close();
        setRegister(data.resultSet);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };


  useEffect(() => {
    if (isOpen) {
      handleGetChatbotQuestion(id);
    }
    setRegister({
      ...register,
      chatbotId: chatbotId,
      id: id,
    });

    return () => {
      setRegister({
        id: null,
        chatbotId: null,
        type: "",
        question: "",
      });
    };
  }, [isOpen]);

  if (chatbotId <= 0) return <></>;

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} className="modal-lg">
        <ModalHeader toggle={toggleModal}>Registro Ações do Chatbot</ModalHeader>
        <ModalBodyStyled>
          <ClassForm>
            <FormGroup>
              <Label>Tipo</Label>
              <select
                className="form-control"
                value={register.type}
                onChange={(ev) => {
                  setRegister({
                    ...register,
                    type: ev.target.value,
                  });
                }}
              >
                <option value="" disabled>
                  Selecione..
                </option>
                <option value="START">INICIO</option>
                <option value="CONTINUE">EXECUÇÃO</option>
                <option value="FINISH">FINALIZAÇÃO</option>
              </select>
            </FormGroup>
            <FormGroup>
              <Label>Mensagem de ação</Label>
              <ReactTextareaAutocomplete
                className="form-control w-100"
                name="message"
                style={{ height: 120, zIndex: 999 }}
                containerStyle={{
                  width: ref.current.offsetWidth,
                  zIndex: 999,
                }}
                onChange={(ev) => {
                  setRegister({
                    ...register,
                    question: ev.target.value,
                  });
                  updateMessagePreview(ev);
                }}
                value={register.question}
                onInput={updateMessagePreview}
                loadingComponent={() => <span>Loading</span>}
                trigger={{
                  ":": {
                    dataProvider: (token) => {
                      return emoji(token)
                        .slice(0, 20)
                        .map(({ name, char }) => ({ name, char }));
                    },
                    component: Item,
                    output: (item, trigger) => item.char,
                  },
                }}
              />
            </FormGroup>
          </ClassForm>
          <Preview />
        </ModalBodyStyled>
        <ModalFooter>
          <Button
            color="success"
            onClick={() => {
              handleSubmit();
            }}
          >
            SALVAR
          </Button>
          <Button
            color="danger"
            onClick={() => {
              toggleModal();
            }}
          >
            SAIR
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RegisterChatbotQuestionComponent;
