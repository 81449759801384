import React, { useState, useEffect, useRef } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalFooter,
    Input,
    FormGroup,
    Label,
    InputGroup,
    InputGroupAddon,
    Row,
    Card,
    CardFooter,
    CardBody,
    CardHeader,
    ModalBody,
    Col,
    Table,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Tooltip,
} from 'reactstrap';

import {
    setStatusTrigger,
    getTriggers,
    sendTriggers,
    listChatbotQuestionService,
    updateTrigger,
} from '../../../services/Chatbot';
import Swal from 'sweetalert2';
import s from '../Chatbot.module.scss';
import { Strong } from './style/ChatbotTableStyle';
import { FaPlus } from 'react-icons/fa';
import RegisterChatbotQuestionComponent from './RegisterChatbotQuestionComponent';
import TriggerPreview from './TriggerPreview';

const TriggersRegisterModal = ({ isOpen, toggleTriggersModal, chatbot, chatbotQuestionId }) => {

    const [triggers, setTriggers] = useState([]);
    const [formData, setFormData] = useState({
        type: '',
        action: 'WAIT_RESPONSE_CLIENT',
        title: '',
        questionOriginId: chatbotQuestionId,
        questionDestinyId: 1,
    });

    const [destinyQuestionText, setDestinyQuestionText] = useState('');
    const [modalChatbotQuestion, setModalChatbotQuestion] = useState(false);
    const [chatbotQuestionIdModal, setChatbotQuestionIdModal] = useState(null);
    const [chatbotQuestions, setChatbotQuestions] = useState([]);
    const [originQuestionMessage, setOriginQuestionMessage] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [selectedTrigger, setSelectedTrigger] = useState(null);
    const titleRef = useRef(null);
    const modalTitle = isEditing ? 'Editar Gatilho' : 'Cadastro de Gatilhos';
    const isTriggerTitleFilled = formData.title !== '';
    const destinyQuestionRef = useRef(null);
    const [tooltipOriginOpen, setTooltipOriginOpen] = useState([]);
    const [tooltipDestinyOpen, setTooltipDestinyOpen] = useState([]);

    const toggleTooltipOrigin = (index) => {
        setTooltipOriginOpen((prevTooltipOpen) => {
            const newTooltipOpen = [...prevTooltipOpen];
            newTooltipOpen[index] = !newTooltipOpen[index];
            return newTooltipOpen;
        });

    };

    const translationType = {
        START: "INICIAR",
        CONTINUE: "EXECUTAR",
        FINISH: "FINALIZAR",
    };

    const translationAction = {
        WAIT_RESPONSE_CLIENT: "AGUARDANDO RESPOSTA DO CLIENTE",
        WAIT_MESSAGE_INITIAL: "AGUARDANDO MENSAGEM INICIAL",
        TIMEOUT: "FINALIZAÇÃO",
    };

    const toggleTooltipDestiny = (index) => {
        setTooltipDestinyOpen((prevTooltipOpen) => {
            const newTooltipOpen = [...prevTooltipOpen];
            newTooltipOpen[index] = !newTooltipOpen[index];
            return newTooltipOpen;
        });

    };

    const setStatus = async (chatbotTriggerId, active) => {
        const action = Number(active) === 0 ? 'Inativar' : 'Ativar';

        Swal.fire({
            icon: "question",
            title: "Confirmação",
            text: `Você tem certeza que deseja ${action} o chat?`,
            showCancelButton: true,
            cancelButtonText: "Não, sair",
            confirmButtonText: "Sim, continuar"
        }).then((confirm) => {
            if (!confirm.isConfirmed) return;

            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde.",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            setStatusTrigger(chatbotTriggerId, active)
                .then((response) => {
                    Swal.fire({
                        icon: "success",
                        title: "Operação realizada com sucesso.",
                        confirmButtonText: "Fechar",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    });

                    handleList(chatbotQuestionId);
                })
                .catch((err) => {
                    const code = !err.response ? "00000" : err.response.data.code;
                    const message = !err.response
                        ? "Ocorreu um erro inesperado"
                        : err.response.data.message;
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: `[${code}] ${message}`,
                        showConfirmButton: true,
                    });
                });
        });
    };

    const updateMessagePreview = (event) => {
        const previewParagraph = document.getElementById("message-trigger");
        if (previewParagraph) {
            const messageText = event.target.value.replace(/\n/g, "<br>");
            previewParagraph.innerHTML = `<p>${messageText}</p>` || "";
        }
    };

    const handleDestinyQuestionChange = (selectedQuestionId) => {
        const selectedQuestion = chatbotQuestions.find(question => question.id === selectedQuestionId);
        setDestinyQuestionText(selectedQuestion?.question || '');
        const destinyQuestionElement = destinyQuestionRef.current;
        if (destinyQuestionElement) {
            destinyQuestionElement.innerHTML = selectedQuestion?.question || '';
        }
    };

    const resetModal = () => {
        setFormData({
            type: '',
            action: 'WAIT_RESPONSE_CLIENT',
            title: '',
            questionOriginId: chatbotQuestionId,
            questionDestinyId: 1,
        });
        setDestinyQuestionText('');
        setIsEditing(false);
        setSelectedTrigger(null);
    };

    const closeModal = () => {
        setFormData({
            type: '',
            action: 'WAIT_RESPONSE_CLIENT',
            title: '',
            questionOriginId: chatbotQuestionId,
            questionDestinyId: 1,
        });
        setDestinyQuestionText('');
        setIsEditing(false);
        setSelectedTrigger(null);
        toggleTriggersModal();
    }

    const handleList = (id) => {
        getTriggers(id)
            .then((response) => {
                setTriggers(response.data.resultSet);
            })
            .catch((error) => {
                console.error('Erro ao obter a lista de gatilhos:', error);
            });
    };

    const handleSave = async () => {
        if (!formData.title || !formData.type || !formData.action || !formData.questionDestinyId) {
            Swal.fire({
                icon: 'error',
                title: 'Campos obrigatórios em branco',
                text: 'Preencha todos os campos obrigatórios.',
                showConfirmButton: true,
            });
            return;
        }

        Swal.fire({
            title: "Carregando, Por favor aguarde...",
            text: "Por favor aguarde.",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        const newTriggersData = {
            chatbotId: parseInt(chatbot),
            type: formData.type,
            action: formData.action,
            title: formData.title,
            questionOriginId: parseInt(formData.questionOriginId, 10),
            questionDestinyId: parseInt(formData.questionDestinyId, 10),
        };

        await (isEditing ? updateTrigger(selectedTrigger.id, newTriggersData) : sendTriggers(newTriggersData))
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: 'Sucesso!',
                    text: isEditing ? 'Gatilho atualizado com sucesso!' : 'Chatbot cadastrado com sucesso!',
                    showConfirmButton: true,
                });
                handleList(chatbotQuestionId);
                setFormData({
                    type: '',
                    action: 'WAIT_RESPONSE_CLIENT',
                    title: '',
                    questionOriginId: chatbotQuestionId,
                    questionDestinyId: 1,
                });
                setIsEditing(false);
                setDestinyQuestionText('');
                resetModal();
            })
            .catch((err) => {
                const code = !err.response ? "00000" : err.response.data.code;
                const message = !err.response
                    ? "Ocorreu um erro inesperado"
                    : err.response.data.message;
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `[${code}] ${message}`,
                    showConfirmButton: true,
                });
            });
    };

    const handleCancelEdit = () => {
        setFormData({
            type: '',
            action: 'WAIT_RESPONSE_CLIENT',
            title: '',
            questionOriginId: chatbotQuestionId,
            questionDestinyId: 1,
        });
        setIsEditing(false);
        setDestinyQuestionText('');
    };

    const handleEditTrigger = (trigger) => {
        setSelectedTrigger(trigger);
        setFormData({
            type: trigger.type,
            action: trigger.action,
            title: trigger.title,
            questionOriginId: trigger.questionOriginId,
            questionDestinyId: trigger.questionDestinyId,
        });
        setIsEditing(true);
    };

    const handleListChatbotQuestion = (chatbotId) => {
        Swal.fire({
            title: "Carregando",
            html: "Por favor, aguarde ... ",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        listChatbotQuestionService(chatbotId)
            .then(({ data }) => {
                Swal.close();
                setChatbotQuestions(data?.resultSet ?? []);
                const originQuestion = data?.resultSet.find(question => question.id === formData.questionOriginId);
                setOriginQuestionMessage(originQuestion?.question || '');
            })
            .catch((err) => {
                const code = !err.response ? "00000" : err.response.data.code;
                const message = !err.response ? "Ocorreu um erro inesperado" : err.response.data.message;
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `[${code}] ${message}`,
                });
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleToggleModal = () => {
        setModalChatbotQuestion(!modalChatbotQuestion);
    };

    useEffect(() => {
        handleList(chatbotQuestionId);
        handleListChatbotQuestion(chatbot);
    }, [chatbotQuestionId]);

    useEffect(() => {
        if (isEditing && selectedTrigger) {
            setFormData({
                ...formData,
                type: selectedTrigger.type,
                action: selectedTrigger.action,
                title: selectedTrigger.title,
                questionOriginId: selectedTrigger.questionOriginId,
                questionDestinyId: selectedTrigger.questionDestinyId,
            });
            updateMessagePreview({ target: { value: selectedTrigger.title } });
            setDestinyQuestionText(selectedTrigger.destinyQuestion || '');
        }
    }, [isEditing, selectedTrigger]);

    useEffect(() => {
        if (selectedTrigger) {
            setTimeout(() => {
                const modal = document.querySelector('.modal-xl');
                if (modal) {
                    modal.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 100);
        }
    }, [selectedTrigger]);

    return (
        <Modal isOpen={isOpen} toggle={closeModal} className="modal-xl scroll-target">
            <ModalHeader toggle={closeModal}>Gatilhos</ModalHeader>
            <ModalBody className='d-flex flex-column' style={{ backgroundColor: '#F7F8FB' }}>
                <Col className='col-12 mr-4 d-flex flex-xl-row flex-column align-items-xl-start align-items-center justify-content-xl-start justify-content-center'>
                    <Card className='mb-xl-0 mb-4 col-xl-7 col-12 border-0 mr-xl-4 mr-0'>
                        <CardHeader className='bg-transparent border-0'>
                            <p className='headline-2'>{modalTitle}</p>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <FormGroup className='col-xl-6 col-12'>
                                    <Label for="type">Tipo de Execução do Chatbot</Label>
                                    <Input
                                        type="select"
                                        name="type"
                                        id="type"
                                        value={formData.type}
                                        onChange={handleChange}
                                    >
                                        <option value="" disabled>Selecione</option>
                                        <option value="START">INICIO</option>
                                        <option value="CONTINUE">EXECUÇÃO</option>
                                        <option value="FINISH">FINALIZAÇÃO</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup className='col-xl-6 col-12'>
                                    <Label for="action" required>Ação</Label>
                                    <Input
                                        type="select"
                                        name="action"
                                        id="action"
                                        value="WAIT_RESPONSE_CLIENT"
                                        onChange={handleChange}
                                        disabled
                                    >
                                        <option value="WAIT_RESPONSE_CLIENT" selected>ESPERANDO RESPOSTA DO CLIENTE</option>
                                    </Input>
                                </FormGroup>
                            </Row>
                            <FormGroup>
                                <Label for="title">Título do Gatilho</Label>
                                <Input
                                    type="text"
                                    name="title"
                                    id="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    innerRef={titleRef}
                                    onInput={updateMessagePreview}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="questionDestinyId">Questão de Destino</Label>
                                <InputGroup>
                                    <select
                                        className="form-control"
                                        name="questionDestinyId"
                                        value={formData.questionDestinyId}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleDestinyQuestionChange(e.target.value);
                                        }}
                                        disabled={chatbotQuestions.length === 0 || chatbotQuestions.length === 1}
                                        required
                                    >
                                        <option value="">Selecione...</option>
                                        {chatbotQuestions
                                            .filter(question => question.id !== formData.questionOriginId)
                                            .map((question, index) => (
                                                <option key={index} value={question.id}>
                                                    {question.question}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    <InputGroupAddon addonType="append">
                                        <Button
                                            color="info"
                                            onClick={() => {
                                                handleToggleModal();
                                                setChatbotQuestionIdModal(chatbotQuestionIdModal)
                                            }
                                            }
                                        >
                                            <FaPlus />
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                            {(chatbotQuestions.length === 0 || chatbotQuestions.length === 1) && (
                                <div className="alert alert-danger">
                                    <strong>Aviso: </strong> Cadastre uma nova ação para ter acesso ao destino.
                                    <button
                                        className={s.btn}
                                        onClick={() => {
                                            handleToggleModal();
                                            setChatbotQuestionIdModal(chatbotQuestionIdModal)
                                        }
                                        }
                                    >
                                        Clique aqui!
                                    </button>
                                </div>
                            )}
                        </CardBody>
                        <CardFooter className='d-flex flex-xl-row flex-column bg-transparent border-0 pt-0 pb-5'>
                            <Button color="primary" onClick={handleSave} style={{ maxWidth: '200px' }}>
                                Salvar
                            </Button>
                            {isEditing && (
                                <Button className='btn-secondary ml-xl-2 ml-0 mt-xl-0 mt-2' onClick={handleCancelEdit} style={{ maxWidth: '200px' }}>
                                    Cancelar
                                </Button>
                            )}
                        </CardFooter>
                    </Card>
                    <TriggerPreview
                        originQuestionMessage={originQuestionMessage}
                        destinyQuestionMessage={destinyQuestionText}
                        isTriggerTitleFilled={isTriggerTitleFilled}
                        updateMessagePreview={updateMessagePreview}
                        destinyQuestionRef={destinyQuestionRef}
                    />
                </Col>
                <Card className='border-0 mt-4'>
                    <CardHeader className="border-0 bg-white">
                        <Row>
                            <Col md="9" xl="9">
                                <div className={s.tableTitle}>
                                    <div className="headline-2">Listagem</div>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div className="widget-table-overflow text-center">
                            {triggers.length > 0 ? (
                                <Table
                                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                                    responsive
                                >
                                    <thead>
                                        <tr>
                                            <th className="w-14">TITULO DO GATILHO</th>
                                            <th className="w-14">TITULO DA QUESTÃO DE ORIGEM</th>
                                            <th className="w-14">TITULO DA QUESTÃO DE DESTINO</th>
                                            <th className="w-14">TIPO</th>
                                            <th className="w-14">AÇÃO DO DISPARO</th>
                                            <th className="w-14">Opções</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {triggers.map((trigger, key) => (
                                            <tr key={trigger.id}>
                                                <td><Strong>{trigger.title}</Strong></td>
                                                <td>
                                                    <Tooltip
                                                        className="align-self-start text-start w-auto"
                                                        placement="top"
                                                        isOpen={tooltipOriginOpen[key]}
                                                        target={`tooltip-origin-${key}`}
                                                        toggle={() => toggleTooltipOrigin(key)}
                                                        trigger="hover"
                                                        delay={{ show: 100, hide: 0 }}
                                                        style={{
                                                            backgroundColor: "#FFF",
                                                            border: "1px solid #C7D0D9",
                                                            color: "#000",
                                                            fontWeight: "500",
                                                            borderRadius: "5px",
                                                            width: "15rem",
                                                            padding: "10px",
                                                            whiteSpace: "pre-wrap",
                                                            textAlign: "start",
                                                            position: "absolute",
                                                            bottom: "10px"
                                                        }}
                                                    >
                                                        {trigger.originQuestion}
                                                    </Tooltip>
                                                    <div id={`tooltip-origin-${key}`}>
                                                        <Strong>{trigger.originQuestion.length > 20 ? `${trigger.originQuestion.slice(0, 20)}...` : trigger.originQuestion}</Strong>
                                                    </div>
                                                </td>

                                                <td>
                                                    <Tooltip
                                                        className="align-self-start text-start w-auto"
                                                        placement="top"
                                                        isOpen={tooltipDestinyOpen[key]}
                                                        target={`tooltip-destiny-${key}`}
                                                        toggle={() => toggleTooltipDestiny(key)}
                                                        trigger="hover"
                                                        delay={{ show: 100, hide: 0 }}
                                                        style={{
                                                            backgroundColor: "#FFF",
                                                            border: "1px solid #C7D0D9",
                                                            color: "#000",
                                                            fontWeight: "500",
                                                            borderRadius: "5px",
                                                            width: "15rem",
                                                            padding: "10px",
                                                            whiteSpace: "pre-wrap",
                                                            textAlign: "start",
                                                            position: "absolute",
                                                            bottom: "10px"
                                                        }}
                                                    >
                                                        {trigger.destinyQuestion}
                                                    </Tooltip>
                                                    <div id={`tooltip-destiny-${key}`}>
                                                        <Strong>{trigger.destinyQuestion.length > 20 ? `${trigger.destinyQuestion.slice(0, 20)}...` : trigger.destinyQuestion}</Strong>
                                                    </div>
                                                </td>
                                                <td><strong className="badge badge-info">{translationType[trigger.type]}</strong></td>
                                                <td><strong>{translationAction[trigger.action]}</strong></td>
                                                <td>
                                                    {Number(trigger.active) === 1 ? (
                                                        <span className="badge badge-success">
                                                            ATIVO
                                                        </span>
                                                    ) : (
                                                        <span className="badge badge-danger">
                                                            INATIVO
                                                        </span>
                                                    )}
                                                </td>
                                                <td>
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle caret color="secondary">
                                                            Opções
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem
                                                                style={{ color: 'black' }}
                                                                onClick={() => handleEditTrigger(trigger)}
                                                            >
                                                                Editar

                                                            </DropdownItem>
                                                            <DropdownItem
                                                                onClick={() =>
                                                                    setStatus(
                                                                        trigger.id,
                                                                        Number(trigger.active) === 1 ? 0 : 1
                                                                    )
                                                                }
                                                            >
                                                                {Number(trigger.active) === 0
                                                                    ? "Ativar"
                                                                    : "Inativar"}
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <div className="alert alert-secondary">
                                    <h4 className="text-dark text-center">
                                        Não existem gatilhos cadastrados no momento.
                                    </h4>
                                </div>
                            )}
                        </div>
                    </CardBody>
                </Card>

                <RegisterChatbotQuestionComponent
                    id={chatbotQuestionIdModal}
                    chatbotId={chatbot}
                    isOpen={modalChatbotQuestion}
                    toggle={handleToggleModal}
                    execute={() => {
                        handleListChatbotQuestion(chatbot);
                    }}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggleTriggersModal}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default TriggersRegisterModal;