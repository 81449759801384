import { useEffect, useState } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { getPhoneClientService } from "../../../services/Chat";
import { listAllInstanceService } from "../../../services/Instance";
import Swal from "sweetalert2";
import { phoneMask } from "utils/mask";

export const ChatNewMessageComponent = ({
  showModal,
  toggleModal = () => {},
  sendMessage = () => {},
  handleGetMessagesClient = () => {},
  handleListContact = () => {},
}) => {
  const [instances, setInstances] = useState([]);
  const [data, setData] = useState({
    phone: null,
    instanceId: null,
    message: null,
    instanceName: null,
  });

  const handleListInstance = () => {
    listAllInstanceService()
      .then(({ data }) => {
        if (!data?.resultSet || data?.resultSet.length <= 0) return;

        const instances = data.resultSet.map((register) => ({
          value: register.id,
          label: register.name,
        }));

        setInstances(instances);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
          showConfirmButton: true,
        });
      });
  };

  const handleSendMessage = () => {
    if (!data.phone || !data.instanceId || !data.message) {
      Swal.fire({
        icon: "info",
        title: "Campos em branco",
        text: "Por favor preencha todos os campos!",
        showConfirmButton: true,
      });
      return;
    }
    Swal.fire({
      title: "Enviando",
      text: "Por favor, aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    getPhoneClientService(data.phone)
      .then(({ data: result }) => {
        if (!result?.resultSet?.id) {
          console.log("AQ");
          throw new Error("Não foi possível número");
        }

        sendMessage({
          instance: data.instanceName,
          clientPhoneNumberId: result?.resultSet?.id,
          message: data.message,
        });

        handleListContact(
          {
            clientPhoneNumberId: result?.resultSet?.id,
          },
          false,
          (callback) => {
            setData({
              phone: null,
              instanceId: null,
              instanceName: null,
              message: null,
            });
            toggleModal(!showModal);
            Swal.close();

            if (callback.length <= 0) return;
            handleGetMessagesClient(callback[0]);
          }
        );
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
          showConfirmButton: true,
        });
      });
  };

  useEffect(() => {
    handleListInstance();
  }, []);

  return (
    <>
      <Modal
        isOpen={showModal}
        className="modal-md modal-dialog modal-dialog-centered"
      >
        <ModalHeader
          toggle={() => {
            toggleModal(!showModal);
          }}
          className="py-0 pt-2 border-0"
        >
          Envie sua mensagem
        </ModalHeader>
        <Card className="border-0 mt-4">
          <CardBody className="pt-0">
            <FormGroup>
              <Label>
                Nº WhatsApp <span className="text-danger">*</span>
              </Label>
              <Input
                type="tel"
                value={data.phone}
                placeholder="(00) 0 0000-0000"
                onChange={(e) => {
                  setData({
                    ...data,
                    phone: phoneMask(e.target.value),
                  });
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                Instância <span className="text-danger">*</span>
              </Label>
              <Select
                options={instances}
                isLoading={instances.length <= 0}
                value={{ value: data.instanceId, label: data.instanceName }}
                placeholder="Selecione..."
                onChange={(selectedOption) => {
                  setData({
                    ...data,
                    instanceId: selectedOption.value,
                    instanceName: selectedOption.label,
                  });
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                Mensagem <span className="text-danger">*</span>
              </Label>
              <Input
                type="textarea"
                id="messageTextarea"
                value={data.message}
                onChange={(e) => {
                  setData({
                    ...data,
                    message: e.target.value,
                  });
                }}
              />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button
              className="w-100"
              color="success"
              onClick={handleSendMessage}
            >
              Enviar
            </Button>
          </CardFooter>
        </Card>
      </Modal>
    </>
  );
};
