import styled, { css } from "styled-components";

export const Li = styled.li`
    margin-right: 16px;
    @media (max-width: 767px) {
        padding: 0 17px;
    }
`;

export const Link = styled.a`
    padding: 13px 12px;
    color: #16365f;
    font-size: 1rem;
    font-weight: 600;
`;

export const Icon = styled.i`
    margin-right: 0.2rem;
`;

export const ArrowIconImage = styled.img`
    margin-left: 10px;
    cursor: pointer; 
`;

export const ArrowIconImageOne = styled.img`
    margin-left: 75px;
    cursor: pointer; 
`;

export const ArrowIconImageMenu = styled.img`
    margin-left: 3rem;
    cursor: pointer; 
    transform: rotate(180deg);

    @media (max-width: 767px) {
       display: none;
    }
`;


export const ServiceButton = styled.a`
    button{
        background-color: white;
        padding: 13px 14px;
        font-size: 1rem;
        width: 93%;
        font-weight: 600;
        color: #16365f;
        border-radius: 0.25rem;

        @media (max-width: 767px) {
            width: 100%;
         }
    }

    button:focus,
    button:hover {
        background-color: white;
    }
`


